import {IUSERROLE, USERROLE} from '../constants';

export const decimalSeperator = ','; // 0,12
export const integerSeperator = '.'; // 100.000

export const convertToDotSeperated = (price: string | number) => {
  const validString = price.toString();
  if (!validString) return '0';

  const floatString = parseFloat(validString).toFixed(2).replace('.', decimalSeperator);
  return floatString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, integerSeperator);
};

export const convertNumberDotSeperated = (number: string | number = 0) => {
  const validNumberString = number ? number.toString() : '';
  if (!validNumberString) return '0';
  return validNumberString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, integerSeperator);
};

export const convertDotSeperatedToNumberString = (dotSeperated = '') => {
  const [integer = '', decimal = ''] = dotSeperated.split(decimalSeperator);
  const number = integer.split('.').join('');

  if (decimal) return `${number}.${decimal}`;
  return number;
};
export const convertDotSeperatedToFloat = (dotSeperated = '') =>
  parseFloat(convertDotSeperatedToNumberString(dotSeperated));

export const getInt = (value: any) => {
  return parseInt(value.toString().replaceAll('.', ''), 10);
};

/* eslint-disable prefer-regex-literals */

export const ValidatePhoneNo = (value: any) => {
  const patt = new RegExp('(5)[0-9][0-9][1-9]([0-9]){6}');
  return patt.test(value) && value.length < 11
    ? Promise.resolve()
    : Promise.reject(new Error('Lütfen geçerli bir telefon numaranızı giriniz!'));
};

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const priceIntoFloat = (price: any) => {
  return `${price}`.replaceAll('.', '').replace(',', '.');
};

export const priceFloat = (price: any) => {
  return parseFloat(`${price}`.replaceAll('.', '').replace(',', '.'));
};

export const getUserRole = (value: any) => {
  const valueMap = value.includes(', ') ? value.split(', ') : value.split(',');
  const roles =
    valueMap.length > 0 &&
    valueMap.reduce((newVal: any, val: IUSERROLE) => {
      newVal.push(USERROLE[val]);
      return newVal;
    }, []);
  return roles.join(', ');
};

const charMap: any = {
  Ç: 'C',
  Ö: 'O',
  Ş: 'S',
  İ: 'I',
  I: 'i',
  Ü: 'U',
  Ğ: 'G',
  ç: 'c',
  ö: 'o',
  ş: 's',
  ı: 'i',
  ü: 'u',
  ğ: 'g',
  i̇: 'i',
};

const turkishLetters = ['İ', 'Ş', 'Ğ', 'Ü', 'Ö', 'Ç', 'ı', 'i̇', 'ş', 'ğ', 'ü', 'ö', 'ç'];
const englishLetters = ['I', 'S', 'G', 'U', 'O', 'C', 'i', 'i', 's', 'g', 'u', 'o', 'c'];

export const turkishToEnglish = (word: any) => {
  const strArray = word.split('');
  for (let i = 0, len = strArray.length; i < len; i += 1) {
    strArray[i] = charMap[strArray[i]] || strArray[i];
  }
  const str = strArray.join('');

  const clearStr = str.replace(/[çöşüğı]/gi, '');
  return clearStr;
};

export const englishToTurkish = (word: string) => {
  let temp = word;
  for (let i = 0; i < 13; i += 1) temp = word.replaceAll(englishLetters[i], turkishLetters[i]);
  return temp;
};

export const matchTurkishString = (value: any, search: any) => {
  if (value === search || value.includes(search)) {
    return true;
  }
  if (value.toLowerCase().includes(search.toLowerCase())) {
    return true;
  }
  if (turkishToEnglish(value).toLowerCase().includes(turkishToEnglish(search).toLowerCase())) {
    return true;
  }
  return false;
};
