import React, {useEffect, useState} from 'react';
import {Tabs, Form, Row, Col, notification} from 'antd';
import {useDispatch} from 'react-redux';
import ContentContainer from '../../../../../components/ContentContainer';
import Text from '../../../../../components/Text';
import InputPrice from '../../../../../components/InputPrice';
import {convertToDotSeperated, priceFloat, priceIntoFloat} from '../../../../../utils/string';
import customerApi from '../../../../../Api/Customer';
import Button from '../../../../../components/Button';
import LineChart from '../../../../../components/Charts/LineChart';
import {fileKeyNamePair} from '../../../../../utils/file';
import {setInvoicePopupData} from '../../../../../store/customer/customerActions';
import {useAppSelector} from '../../../../../hooks/useRedux';

const {TabPane} = Tabs;

const ExcelYearPopup = ({accountId, type, onUpdate}: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const popupData: any = useAppSelector((state) => state.customer.invoicePopupData);

  const dataList: any =
    Object.keys(popupData) &&
    Object.keys(popupData).length > 0 &&
    Object.keys(popupData).sort((a, b) => parseInt(b, 10) - parseInt(a, 10));

  const getFinancingRate = (shortTerm: any, longTerm: any, ownFunds: any, activeYear: any) => {
    // Öz sermaye / (Kısa Vadeli Borçlar + Uzun Vadeli Borçlar)
    if (ownFunds && (shortTerm || longTerm)) {
      const cal = ownFunds / (parseFloat(shortTerm) + parseFloat(longTerm));
      dispatch(
        setInvoicePopupData({
          ...popupData,
          [activeYear]: {
            ...popupData[activeYear],
            ownFunds,
            shortTermLiabilities: shortTerm,
            longTermLiabilities: longTerm,
            financingRate: cal.toFixed(4),
          },
        }),
      );
    }
  };

  const getWorkingAsset = (workAsset: any, shortTerm: any, activeYear: any) => {
    // Dönen Varlıklar - Kısa Vadeli Borçlar
    const calNet = workAsset - shortTerm;
    const calcari = workAsset && shortTerm ? workAsset / shortTerm : 0;
    dispatch(
      setInvoicePopupData({
        ...popupData,
        [activeYear]: {
          ...popupData[activeYear],
          workingAssets: workAsset,
          shortTermLiabilities: shortTerm,
          netWorkingCapital: calNet.toFixed(4),
          workingCapitalRatio: calcari.toFixed(4),
        },
      }),
    );
  };

  const getShortTermFinans = (
    workAsset: any,
    shortTerm: any,
    longTerm: any,
    ownFunds: any,
    activeYear: any,
  ) => {
    const calNet = workAsset - shortTerm;
    const calcari = workAsset && shortTerm ? workAsset / shortTerm : 0;
    if (ownFunds && (shortTerm || longTerm)) {
      const cal = ownFunds / (parseFloat(shortTerm) + parseFloat(longTerm));
      dispatch(
        setInvoicePopupData({
          ...popupData,
          [activeYear]: {
            ...popupData[activeYear],
            ownFunds,
            workingAssets: workAsset,
            shortTermLiabilities: shortTerm,
            longTermLiabilities: longTerm,
            financingRate: cal.toFixed(4),
            netWorkingCapital: calNet.toFixed(4),
            workingCapitalRatio: calcari.toFixed(4),
          },
        }),
      );
    } else if (!ownFunds && ownFunds === 0) {
      dispatch(
        setInvoicePopupData({
          ...popupData,
          [activeYear]: {
            ...popupData[activeYear],
            workingAssets: workAsset,
            shortTermLiabilities: shortTerm,
            netWorkingCapital: calNet.toFixed(4),
            workingCapitalRatio: calcari.toFixed(4),
          },
        }),
      );
    }
  };

  const mapData = () => {
    const graphDataList: any =
      Object.keys(popupData) &&
      Object.keys(popupData).length > 0 &&
      Object.keys(popupData).sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
    const dataMap = graphDataList
      .sort((a: any, b: any) => parseInt(a, 10) - parseInt(b, 10))
      .reduce((newData: any, year: any) => {
        if (popupData[year]) {
          newData.push({...popupData[year], name: year});
        }
        return newData;
      }, []);
    return dataMap;
  };

  const handleChange = async (e: any, val: any) => {
    const values = form.getFieldsValue();
    const tabIndex = parseInt(activeTab, 10) - 1;
    const activeYear = dataList[tabIndex];
    // Öz sermaye / (Kısa Vadeli Borçlar + Uzun Vadeli Borçlar)
    const ownFunds: any =
      (values[`ownFunds-${activeYear}`] && priceIntoFloat(values[`ownFunds-${activeYear}`])) || 0;
    const shortTerm: any =
      (values[`shortTermLiabilities-${activeYear}`] &&
        priceIntoFloat(values[`shortTermLiabilities-${activeYear}`])) ||
      0;
    const longTerm: any =
      (values[`longTermLiabilities-${activeYear}`] &&
        priceIntoFloat(values[`longTermLiabilities-${activeYear}`])) ||
      0;
    const workAsset: any =
      (values[`workingAssets-${activeYear}`] &&
        priceIntoFloat(values[`workingAssets-${activeYear}`])) ||
      0;
    if (val === 'workingAssets') {
      await getWorkingAsset(workAsset, shortTerm, activeYear);
    }
    if (val === 'shortTermLiabilities') {
      await getShortTermFinans(workAsset, shortTerm, longTerm, ownFunds, activeYear);
    }
    if (val === 'longTermLiabilities' || val === 'ownFunds') {
      await getFinancingRate(shortTerm, longTerm, ownFunds, activeYear);
    }
  };

  useEffect(() => {
    let updatedFormData = {};
    const values = form.getFieldsValue();
    dataList &&
      dataList.length > 0 &&
      dataList.map((key: any) => {
        if (!values[`workingAssets-${key}`]) {
          const data = popupData[key];
          Object.keys(data) &&
            Object.keys(data).length > 0 &&
            Object.keys(data).map((val) => {
              updatedFormData = {
                ...updatedFormData,
                [`${val}-${key}`]: data[val] && convertToDotSeperated(data[val]),
              };
              return val;
            });
          form.setFieldsValue(updatedFormData);
        }
        return key;
      });
  }, [type, popupData]);

  const onUpdateYear = async (year: any) => {
    try {
      setIsLoading(true);
      const tabIndex = parseInt(activeTab, 10) - 1;
      const activeYear = dataList[tabIndex];
      const values = form.getFieldsValue();
      let key = '';
      let data: any = {
        id: popupData[activeYear].id,
        year: parseInt(year, 10),
        accountId,
        fileName: popupData[activeYear].fileName,
      };
      if (type === 'institue') {
        data = {
          ...data,
          netSales: priceFloat(values[`netSales-${activeYear}`]),
          periodProfitLoss: priceFloat(values[`periodProfitLoss-${activeYear}`]),
          workingAssets: priceFloat(values[`workingAssets-${activeYear}`]),
          longTermAssets: priceFloat(values[`longTermAssets-${activeYear}`]),
          shortTermLiabilities: priceFloat(values[`shortTermLiabilities-${activeYear}`]),
          longTermLiabilities: priceFloat(values[`longTermLiabilities-${activeYear}`]),
          ownFunds: priceFloat(values[`ownFunds-${activeYear}`]),
          netWorkingCapital: priceFloat(values[`netWorkingCapital-${activeYear}`]),
          workingCapitalRatio: priceFloat(values[`workingCapitalRatio-${activeYear}`]),
          financingRate: priceFloat(values[`financingRate-${activeYear}`]),
          totalAssests: priceFloat(values[`totalAssests-${activeYear}`]),
          totalLiabilities: priceFloat(values[`totalLiabilities-${activeYear}`]),
        };
        key = 'manifest';
      } else if (type === 'bilanco') {
        data = [
          {
            ...data,
            workingAssets: priceFloat(values[`workingAssets-${activeYear}`]),
            longTermAssets: priceFloat(values[`longTermAssets-${activeYear}`]),
            shortTermLiabilities: priceFloat(values[`shortTermLiabilities-${activeYear}`]),
            longTermLiabilities: priceFloat(values[`longTermLiabilities-${activeYear}`]),
            ownFunds: priceFloat(values[`ownFunds-${activeYear}`]),
            netWorkingCapital: priceFloat(values[`netWorkingCapital-${activeYear}`]),
            workingCapitalRatio: priceFloat(values[`workingCapitalRatio-${activeYear}`]),
            financingRate: priceFloat(values[`financingRate-${activeYear}`]),
            totalAssests: priceFloat(values[`totalAssests-${activeYear}`]),
            totalLiabilities: priceFloat(values[`totalLiabilities-${activeYear}`]),
          },
        ];
        key = 'balance';
      } else if (type === 'gelir') {
        data = [
          {
            ...data,
            netSales: priceFloat(values[`netSales-${activeYear}`]),
            periodProfitLoss: priceFloat(values[`periodProfitLoss-${activeYear}`]),
          },
        ];
        key = 'incoming';
      }
      const response: any = await customerApi.updateCustomerFileData(data, accountId, key);
      if (response) {
        onUpdate();
        notification.success({
          message: 'İşlem Başarıyla Tamamlandı.',
        });
        setIsLoading(false);
      }
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <ContentContainer horizontalPadding>
      <Form form={form} name="findeksInfo" layout="vertical" requiredMark={false}>
        <Tabs defaultActiveKey={activeTab} onChange={setActiveTab}>
          {dataList &&
            dataList.length > 0 &&
            dataList.map((key: any, index: any) => {
              const data = popupData[key];
              if (data) {
                delete data.accountId;
                return (
                  <TabPane tab={key} key={`${index + 1}`}>
                    {Object.keys(data) &&
                      Object.keys(data).length > 0 &&
                      Object.keys(data).map((val, i) => {
                        if (val !== 'id' && val !== 'fileName' && val !== 'year') {
                          return (
                            <Row key={i}>
                              <Col span={14}>
                                <Form.Item name={`${val}-${key}`} label={fileKeyNamePair[val]}>
                                  <InputPrice
                                    onChange={(e: any) => handleChange(e, val)}
                                    displayPrefix={false}
                                    addonAfter="TL"
                                    placeholder="Örn. 123.234,00"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          );
                        }
                        return '';
                      })}
                    {(type === 'bilanco' || type === 'institue') && (
                      <Text type="h3" style={{fontWeight: 'bold'}}>
                        Özet Rasyolar
                      </Text>
                    )}
                    {(type === 'bilanco' || type === 'institue') && (
                      <Text className="m-0" type="h4" style={{fontWeight: 'bold'}}>
                        Net İşleme Sermayesi:
                      </Text>
                    )}
                    {(data.netWorkingCapital || data.netWorkingCapital >= 0) && (
                      <Text className="m-0" type="h4">
                        {convertToDotSeperated(data.netWorkingCapital) || 0} TL
                      </Text>
                    )}
                    {(type === 'bilanco' || type === 'institue') && (
                      <Text className="m-0 mt" type="h4" style={{fontWeight: 'bold'}}>
                        Cari Oran:
                      </Text>
                    )}
                    {(data.workingCapitalRatio || data.workingCapitalRatio >= 0) && (
                      <Text className="m-0" type="h4" bold>
                        % {(data.workingCapitalRatio !== 'NaN' && data.workingCapitalRatio) || 0}
                      </Text>
                    )}
                    {(type === 'bilanco' || type === 'institue') && (
                      <Text className="m-0 mt" type="h4" style={{fontWeight: 'bold'}}>
                        Finansman Oranı:
                      </Text>
                    )}
                    {(data.financingRate || data.financingRate >= 0) && (
                      <Text className="m-0 mb" type="h4" bold>
                        % {(data.financingRate !== 'NaN' && data.financingRate) || 0}
                      </Text>
                    )}
                    <Row>
                      <Col span={16}>
                        <Form.Item className="m-0 mt">
                          <Button
                            onClick={() => onUpdateYear(key)}
                            type="primary"
                            htmlType="submit"
                            size="large"
                            loading={isLoading}
                            block
                          >
                            Kaydet ve İlerle
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>

                    {type === 'gelir' && (
                      <>
                        <Text className="m-t" type="h4" style={{fontWeight: 'bold'}}>
                          Dönem Karı veya Zararı:
                        </Text>
                        <LineChart
                          data={mapData()}
                          xKey="year"
                          yLinesLeft={[
                            {
                              key: 'periodProfitLoss',
                              displayKey: 'Dönem Karı veya Zararı',
                              fill: 'primary',
                              color: 'primary',
                            },
                          ]}
                          yUnitLeft=" ₺"
                        />
                      </>
                    )}
                    {type === 'gelir' && (
                      <>
                        <Text className="m-0" type="h4" style={{fontWeight: 'bold'}}>
                          Net Satışlar:
                        </Text>
                        <LineChart
                          data={mapData()}
                          xKey="year"
                          yLinesLeft={[
                            {
                              key: 'netSales',
                              displayKey: 'Net Satışlar',
                              fill: 'primary',
                              color: 'primary',
                            },
                          ]}
                          yUnitLeft=" ₺"
                        />
                      </>
                    )}
                    {(data.netWorkingCapital || data.netWorkingCapital >= 0) && (
                      <>
                        <Text className="mt" type="h4" style={{fontWeight: 'bold'}}>
                          Net İşleme Sermayesi:
                        </Text>
                        <LineChart
                          data={mapData()}
                          xKey="year"
                          yLinesLeft={[
                            {
                              key: 'netWorkingCapital',
                              displayKey: 'Net İşleme Sermayesi',
                              fill: 'primary',
                              color: 'primary',
                            },
                          ]}
                          yUnitLeft=" ₺"
                        />
                      </>
                    )}
                    {(data.workingCapitalRatio || data.workingCapitalRatio >= 0) && (
                      <>
                        <Text className="m-0" type="h4" style={{fontWeight: 'bold'}}>
                          Cari Oran:
                        </Text>
                        <LineChart
                          data={mapData()}
                          xKey="year"
                          yLinesLeft={[
                            {
                              key: 'workingCapitalRatio',
                              displayKey: 'Cari Oran',
                              fill: 'primary',
                              color: 'primary',
                            },
                          ]}
                        />
                      </>
                    )}
                    {(data.financingRate || data.financingRate >= 0) && (
                      <>
                        <Text className="m-0" type="h4" style={{fontWeight: 'bold'}}>
                          Finansman Oranı:
                        </Text>
                        <LineChart
                          data={mapData()}
                          xKey="year"
                          yLinesLeft={[
                            {
                              key: 'financingRate',
                              displayKey: 'Finansman Oranı',
                              fill: 'primary',
                              color: 'primary',
                            },
                          ]}
                        />
                      </>
                    )}
                  </TabPane>
                );
              }
              return null;
            })}
        </Tabs>
      </Form>
    </ContentContainer>
  );
};

export default ExcelYearPopup;
