import React, {FC} from 'react';
import {Select, Form, Input, Row, Col} from 'antd';
import {useAppSelector} from '../../../../hooks/useRedux';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import {ISalesFieldCreateComponent} from '../types';
import {CREATE_SALES_FIELD_INFO} from '../../../../constants';
import ContentContainer from '../../../../components/ContentContainer';

const {Option} = Select;

const CompanyIdentifyInfo: FC<ISalesFieldCreateComponent> = ({isLoading, onSubmit, onCancel}) => {
  const [form] = Form.useForm();
  const customerSale = useAppSelector((state: any) => state.customer.customerSale);
  return (
    <div>
      <Text type="h2" bold>
        {CREATE_SALES_FIELD_INFO.COMPANY_IDENTIFIER_INFO.title}
      </Text>
      <ContentContainer maxWidth={400}>
        <Form
          form={form}
          style={{marginTop: '2em'}}
          layout="vertical"
          requiredMark={false}
          initialValues={{...customerSale}}
          onFinish={onSubmit}
        >
          <Form.Item name="domesticEndorsement" label="Yurtiçi Cirosu">
            <Input type="number" />
          </Form.Item>

          <Form.Item name="abroadEndorsement" label="Yurtdışı Cirosu">
            <Input type="number" />
          </Form.Item>

          <Form.Item name="ownProductionFacility" label="Üretim Tesis Durumu">
            <Select autoClearSearchValue>
              <Option value>Kira</Option>
              <Option value={false}>Kendinin</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="customerGroupAndSectorDynamics"
            label="Satış Yaptığı Müşteri Grubu ve Sektör Dinamikleri"
          >
            <Input type="text" />
          </Form.Item>

          <Form.Item name="continuingProjects" label="Devam Eden Proje Varsa Hakkında Kısa Bilgi">
            <Input type="text" />
          </Form.Item>

          <Form.Item name="hasBigInvestment" label="Büyük Bir Yatırımı Var mı?">
            <Select autoClearSearchValue>
              <Option value>Evet</Option>
              <Option value={false}>Hayır</Option>
            </Select>
          </Form.Item>

          <Form.Item name="groupCompanyName" label="Bir Gruba Bağlı İse Grup İsmi">
            <Input type="text" />
          </Form.Item>

          <Form.Item name="brandName" label="Marka ismi var İse İsmi">
            <Input type="text" />
          </Form.Item>

          <Form.Item
            name="sectorInformationOfPartners"
            label="Ortaklarının Sektör Tecrübesi Hakkında Bilgi"
          >
            <Input type="text" />
          </Form.Item>

          <Form.Item name="productUsageCapacity" label="Ürün kullanım Kapasitesi (yaklaşık)">
            <Input type="text" />
          </Form.Item>

          <Form.Item
            name="bizimDisimizdaKimlerdenUrunTeminEdiyor"
            label="Bizim Dışımızda Kimlerden Ürün Temin Ediyor"
          >
            <Input type="text" />
          </Form.Item>

          <Form.Item name="makineParkuruHakkindaBilgi" label="Makine Parkuru Hakkında Bilgi">
            <Input type="text" />
          </Form.Item>

          <Form.Item
            name="talepEttigiUrunDisindaBirUrunSatilabilirMi"
            label="Talep Ettiği Ürün Dışında Bir Ürün Satılabilir mi?"
          >
            <Select autoClearSearchValue>
              <Option value>Evet</Option>
              <Option value={false}>Hayır</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="baskaSektorlerdeFaaliyetiVarsaIsmi"
            label="Başka Sektörlerde Faaliyeti Varsa İsmi"
          >
            <Input type="text" />
          </Form.Item>

          <Form.Item
            name="firmaBizeHangiKanallardanUlasti"
            label="Firma Bize Hangi Kanaldan Ulaştı"
          >
            <Input type="text" />
          </Form.Item>

          <Row gutter={20}>
            <Col span={13}>
              <Form.Item className="m-0">
                <Button
                  style={{paddingLeft: `${isLoading ? '10px' : '25px'}`}}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isLoading}
                  block
                >
                  Kaydet ve Devam Et
                </Button>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item className="m-0">
                <Button type="default" size="large" disabled={isLoading} onClick={onCancel} block>
                  Kaydet ve Çık
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ContentContainer>
    </div>
  );
};

export default CompanyIdentifyInfo;
