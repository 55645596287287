import {tciApi, tciApiV2} from '..';
import {IResetPasswordRequestBody, IUser, IUserPost, IUsersResponse} from './types';

export default {
  login(username: string, password: string): Promise<IUser> {
    return tciApi.post('/user/login', {username, password});
  },
  fetchUser(): Promise<IUser> {
    return tciApi.get('/user/login');
  },
  deleteUser(id: any): Promise<IUser> {
    return tciApiV2.delete(`/users/${id}`);
  },
  sendResetPasswordEmail(userEmail: string): Promise<unknown> {
    return tciApi.post('/user/forgotpassword', {userEmail});
  },
  updateUserRole(userId: string, roles: any): Promise<unknown> {
    return tciApiV2.patch(`/Users/${userId}/update/role`, roles, {
      headers: {'Content-Type': 'application/json'},
    });
  },
  resetPassword({token, password, passwordAgain}: IResetPasswordRequestBody): Promise<unknown> {
    return tciApi.post('/user/resetpassword', {token, password, passwordAgain});
  },
  fetchUsers({
    search = undefined,
    page = 1,
    size = 10,
  }: {
    search?: string;
    page?: number;
    size?: number;
  } = {}): Promise<IUsersResponse> {
    return tciApiV2.get('Users', {params: {search, page, size}});
  },
  fetchUserRoles(): Promise<unknown> {
    return tciApiV2.get('Users/roles');
  },
  createUser(data: IUserPost): Promise<unknown> {
    return tciApiV2.post('Users', data);
  },
};
