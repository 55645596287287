import React, {FC, useEffect, useState} from 'react';
import {Col, Drawer, Form, Input, Row, Spin} from 'antd';
import {useDispatch} from 'react-redux';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import Upload from '../../../../components/Upload';
import ProgressStep from '../../../../components/ProgressStep';
import {
  convertFileToAntdFile,
  // dummyData,
  fileKeyPair,
  FilePromiseYear,
  // getBase64,
} from '../../../../utils/file';
import {ICustomerCreateComponent} from '../types';
import {useAppSelector} from '../../../../hooks/useRedux';
import customerApi from '../../../../Api/Customer';
import ExcelYearPopup from './ExcelYearPopup';
import Icon from '../../../../components/Icon';
import {PreviewContainerStyles} from '../../../../components/Upload/styles';
import {priceIntoFloat} from '../../../../utils/string';
import {getNetWorkingCapital, getCariOn, getFinancingRate} from '../../../../utils/math';
import {setInvoicePopupData} from '../../../../store/customer/customerActions';

const DataPreviewContainer = ({name, onRemove, year}: any) => {
  return (
    <PreviewContainerStyles style={{paddingTop: 20, paddingBottom: 20, width: 165}}>
      <Icon
        className="d-block m-auto cursor-pointer"
        icon={['far', 'times-circle']}
        color="red"
        size="2x"
        onClick={onRemove}
      />
      <Text className="mt" align="center" style={{marginTop: 3}} bold block>
        {name}
      </Text>
      <Text align="center" bold block>
        {year}
      </Text>
    </PreviewContainerStyles>
  );
};

const FinancialInfo: FC<ICustomerCreateComponent> = ({onSubmit, onCancel}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [gelirData, setGelirData] = useState<any>({});
  const [bilancoData, setBilancoData] = useState<any>({});
  const [institueData, setInstitueData] = useState<any>({});
  const [type, setType] = useState('');
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [last3Years] = useState(
    [...new Array(3)].map(
      (_, idx) => new Date().getFullYear() - idx - (new Date().getMonth() >= 6 ? 1 : 2),
    ),
  );
  const [yearFilesInstitue, setyearFilesInstitue] = useState<any>(null);
  const [yearFilesBilanco, setyearFilesBilanco] = useState<any>(null);
  const [yearFilesGelir, setyearFilesGelir] = useState<any>(null);

  const customer = useAppSelector((state) => state.customer.customerCreateQueryData);

  const fetchInitialData = async () => {
    const initialData = await customerApi.fetchCustomerFinancialInfo(customer.accountId);
    const years = Object.keys(initialData.documentIdByYear);

    const documents = await Promise.all(
      years.map((year) => FilePromiseYear(initialData.documentIdByYear[year], year)),
    );
    const files = documents.map((file) => convertFileToAntdFile(file as File));

    years.map((year, idx) => {
      return form.setFieldsValue({[year]: files[idx] ? [files[idx]] : []});
    });
    setIsLoadingInitialData(false);
  };

  const handleSubmit = async () => {
    try {
      if (customer.accountId !== 0) {
        setIsLoadingSubmit(true);
        onSubmit();
      }
    } catch {
      setIsLoadingSubmit(false);
    }
  };

  const fetchFinancialGelir = async () => {
    try {
      setIsLoading(true);
      const response: any = await customerApi.fetchCustomerFinancialData(
        customer.accountId,
        'incoming',
      );
      if (response) {
        setIsLoading(false);
        const updateData = last3Years.reduce((newData: any, year) => {
          const findYear = response.find((val: any) => val.year === year);
          if (findYear) {
            return {...newData, [year]: findYear};
          }
          return newData;
        }, {});
        setGelirData({...updateData});
      }
    } catch {
      setIsLoading(false);
    }
  };

  const fetchFinancialBilanco = async () => {
    try {
      setIsLoading(true);
      const response: any = await customerApi.fetchCustomerFinancialData(
        customer.accountId,
        'balance',
      );
      if (response) {
        setIsLoading(false);
        const updateData = last3Years.reduce((newData: any, year) => {
          const findYear = response.find((val: any) => val.year === year);
          if (findYear) {
            const {workingAssets, shortTermLiabilities, longTermLiabilities, ownFunds} = findYear;
            const netWorkingCapital = getNetWorkingCapital(workingAssets, shortTermLiabilities);
            const workingCapitalRatio = getCariOn(workingAssets, shortTermLiabilities);
            const financingRate = getFinancingRate(
              ownFunds,
              shortTermLiabilities,
              longTermLiabilities,
            );
            const tempYearData = {
              ...findYear,
              netWorkingCapital,
              workingCapitalRatio,
              financingRate,
            };
            return {...newData, [year]: tempYearData};
          }
          return newData;
        }, {});
        setBilancoData({...updateData});
      }
    } catch {
      setIsLoading(false);
    }
  };

  const fetchFinancialInstitue = async () => {
    try {
      setIsLoading(true);
      const promises = last3Years.map((year) =>
        customerApi.fetchCustomerInstitueData(customer.accountId, year).then((data) => {
          return data;
        }),
      );

      Promise.all(promises).then((responses: any) => {
        let InstData = {};
        last3Years.map((year, index) => {
          if (responses[index].data === '') {
            return InstData;
          }
          InstData = {...InstData, [year]: responses[index]};
          return InstData;
        });
        setInstitueData(InstData);
      });
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  const onUploadFile = async (fileYear: any, year: any, key: string) => {
    if (key === 'bilanco') {
      setIsLoading1(true);
      setyearFilesBilanco({...yearFilesBilanco, [year]: fileYear});
    } else {
      setIsLoading(true);
      setyearFilesGelir({...yearFilesGelir, [year]: fileYear});
    }
    const formData = new FormData();
    formData.append('file', fileYear);
    const response: any = await customerApi.uploadCustomerFinancialTableInfo(
      formData,
      customer.accountId,
      year,
      fileYear.name,
    );
    if (response && key === 'bilanco') {
      setIsLoading1(false);
      fetchFinancialBilanco();
    } else {
      setIsLoading(false);
      fetchFinancialGelir();
    }
  };

  const handleDeleteYearFile = (year: any, key: any) => {
    if (key === 'institue') {
      setyearFilesInstitue({...yearFilesInstitue, [year]: null});
      delete institueData[year];
    }
    if (key === 'bilanco') {
      setyearFilesBilanco({...yearFilesBilanco, [year]: null});
      setBilancoData({...bilancoData, [year]: null});
    }
    if (key === 'gelir') {
      setyearFilesGelir({...yearFilesGelir, [year]: null});
      setGelirData({...gelirData, [year]: null});
    }
  };

  useEffect(() => {
    if (customer.accountId && customer.accountId !== 0) {
      fetchInitialData();
      fetchFinancialGelir();
      fetchFinancialBilanco();
      fetchFinancialInstitue();
    }
  }, [customer]);

  const handleRemove = (year: any, key: any) => {
    if (key === 'institue') {
      setInstitueData({...institueData, [year]: null});
    } else if (key === 'bilanco') {
      setBilancoData({...bilancoData, [year]: null});
    } else {
      setGelirData({...gelirData, [year]: null});
    }
  };

  const handleBilanco = () => {
    if (bilancoData && Object.keys(bilancoData) && Object.keys(bilancoData).length > 0) {
      dispatch(setInvoicePopupData(bilancoData));
      setType('bilanco');
      setIsDrawerVisible(!isDrawerVisible);
    }
  };

  const handleGelir = () => {
    if (gelirData && Object.keys(gelirData) && Object.keys(gelirData).length > 0) {
      dispatch(setInvoicePopupData(gelirData));
      setType('gelir');
      setIsDrawerVisible(!isDrawerVisible);
    }
  };

  const getPreviousData = (year: any, key: any) => {
    if (key === 'gelir' && gelirData && Object.keys(gelirData) && Object.keys(gelirData).length) {
      const data = Object.keys(gelirData);
      const found: any = data && data.find((val: any) => val.includes(year));
      if (found) return gelirData[found];
    } else if (
      key === 'bilanco' &&
      bilancoData &&
      Object.keys(bilancoData) &&
      Object.keys(bilancoData).length
    ) {
      const data = Object.keys(bilancoData);
      const found = data && data.find((val: any) => val.includes(year));
      if (found) return bilancoData[found];
    } else if (
      key === 'institue' &&
      institueData &&
      Object.keys(institueData) &&
      Object.keys(institueData).length
    ) {
      const data = Object.keys(institueData);
      const found = data && data.find((val: any) => val.includes(year));
      if (found) return institueData[found];
    }
    return false;
  };

  const handleInstitues = () => {
    if (institueData && Object.keys(institueData) && Object.keys(institueData).length > 0) {
      dispatch(setInvoicePopupData(institueData));
      setType('institue');
      setIsDrawerVisible(!isDrawerVisible);
    }
  };

  const onUploadInstitueFile = async (fileYear: any, year: any) => {
    try {
      setIsLoading3(true);
      setyearFilesInstitue({...yearFilesInstitue, [year]: fileYear});
      const formData = new FormData();
      formData.append('file', fileYear);
      const response: any = await customerApi.uploadCustomerInstitueFile(formData);
      if (response) {
        const institueRes =
          Object.keys(fileKeyPair).length > 0 &&
          Object.keys(fileKeyPair).reduce((newData: any, data: any) => {
            const findData = response.keyValue.find((key: any) => key.key === data);
            if (findData) {
              const value = findData.value.split(' ')[1];
              return {
                ...newData,
                [fileKeyPair[data]]: (value && value !== '' && priceIntoFloat(value)) || 0,
              };
            }
            return newData;
          }, {});
        if (institueRes) {
          const {workingAssets, shortTermLiabilities, longTermLiabilities, ownFunds} = institueRes;
          const netWorkingCapital = getNetWorkingCapital(workingAssets, shortTermLiabilities);
          const workingCapitalRatio = getCariOn(workingAssets, shortTermLiabilities);
          const financingRate = getFinancingRate(
            ownFunds,
            shortTermLiabilities,
            longTermLiabilities,
          );
          const tempYearData = {
            ...institueRes,
            netWorkingCapital,
            workingCapitalRatio,
            financingRate,
          };
          console.log(
            tempYearData,
            tempYearData.workingAssets,
            tempYearData.workingCapitalRatio,
            'tempYearData',
          );
          setInstitueData({...institueData, [year]: tempYearData});
          const res: any = await customerApi.uploadCustomerInstitueData(customer.accountId, {
            year,
            fileName: fileYear.name,
            accountId: customer.accountId,
            ...tempYearData,
          });
          console.log(res);
        }
        setIsLoading3(false);
      } else {
        setIsLoading3(false);
      }
    } catch {
      setIsLoading(false);
    }
  };

  const handleUpdateFiles = () => {
    if (type === 'institue') {
      fetchFinancialInstitue();
    } else if (type === 'bilanco') {
      fetchFinancialBilanco();
    } else if (type === 'gelir') {
      fetchFinancialGelir();
    }
  };

  return (
    <Spin spinning={isLoadingInitialData}>
      <ProgressStep className="mb-big" title="Mali Tablolar" value={6} steps={8} />
      <Form
        form={form}
        name="financialInfo"
        layout="vertical"
        requiredMark={false}
        initialValues={{company: customer.title}}
        onFinish={handleSubmit}
      >
        <Row>
          <Col span={10}>
            <Form.Item name="company" label="Firma Adı">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Text className="m-0" type="h3" bold>
          Kurumlar Vergisi Beyannamesi (pdf)
        </Text>
        <Text>Son 3 yıla ait Kurumlar Vergisi Beyannamesi bilgilerini yükleyin.</Text>

        <Row className="my-big" gutter={20}>
          {last3Years.map((year) => (
            <Col style={{marginBottom: 25}} key={year}>
              {getPreviousData(year, 'institue') ? (
                <DataPreviewContainer
                  year={year}
                  name={
                    getPreviousData(year, 'institue') && getPreviousData(year, 'institue').fileName
                  }
                  onRemove={() => handleRemove(year, 'institue')}
                />
              ) : (
                <Upload
                  accept=".doc,.docx,application/pdf"
                  fileList={
                    yearFilesInstitue && yearFilesInstitue !== {} && yearFilesInstitue[year]
                      ? [yearFilesInstitue[year]]
                      : []
                  }
                  label="PDF Yükle"
                  disabled={isLoading3}
                  desc={`${year} yılı`}
                  onChange={(val: any) => onUploadInstitueFile(val.file, year)}
                  onRemove={() => handleDeleteYearFile(year, 'institue')}
                />
              )}
            </Col>
          ))}
          <Col span={15}>
            <Form.Item className="m-0">
              <Button
                className="mali-btn"
                type="default"
                size="large"
                onClick={handleInstitues}
                disabled={isLoading3}
                loading={isLoading3}
                block
              >
                Tabloları Oku ve Analiz Et
              </Button>
            </Form.Item>
          </Col>
        </Row>

        <Text className="m-0 mt" type="h3" bold>
          Bilanço Ekle
        </Text>
        <Text className="m-0 mt" type="h4" style={{fontSize: 16}}>
          Son 3 yıla ait Bilanço Ekleyin. JPEG veya Excel yükleyebilirsiniz.
        </Text>
        <Text type="h4" style={{fontSize: 16}}>
          JPEG olarak yüklerseniz bu verileri analiz edemezsiniz.
        </Text>

        <Row className="my-big" gutter={20}>
          {last3Years.map((year) => (
            <Col style={{marginBottom: 25}} key={year}>
              {getPreviousData(year, 'bilanco') ? (
                <DataPreviewContainer
                  year={year}
                  name={
                    getPreviousData(year, 'bilanco') && getPreviousData(year, 'bilanco').fileName
                  }
                  onRemove={() => handleRemove(year, 'bilanco')}
                />
              ) : (
                <Upload
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/jpeg, image/png"
                  fileList={
                    yearFilesBilanco && yearFilesBilanco !== {} && yearFilesBilanco[year]
                      ? [yearFilesBilanco[year]]
                      : []
                  }
                  onChange={(val: any) => onUploadFile(val.file, year, 'bilanco')}
                  label="Excel /JPEG Yükle"
                  desc={`${year} yılı`}
                  onRemove={() => handleDeleteYearFile(year, 'bilanco')}
                />
              )}
            </Col>
          ))}
          <Col span={15}>
            <Form.Item className="m-0">
              <Button
                className="mali-btn"
                type="default"
                onClick={handleBilanco}
                size="large"
                disabled={isLoading1}
                loading={isLoading1}
                block
              >
                Bilanço Analiz Et
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Drawer
          title="Mali Veriler"
          width={600}
          visible={isDrawerVisible}
          onClose={() => {
            setIsDrawerVisible(false);
            dispatch(setInvoicePopupData({}));
          }}
        >
          <ExcelYearPopup type={type} accountId={customer.accountId} onUpdate={handleUpdateFiles} />
        </Drawer>

        <Text className="m-0 mt" type="h3" bold>
          Gelir Tablosu Ekle
        </Text>
        <Text className="m-0 mt" type="h4" style={{fontSize: 16}}>
          Son 3 yıla ait Gelir Tablosu Ekleyin. JPEG veya Excel yükleyebilirsiniz
        </Text>
        <Text type="h4" style={{fontSize: 16}}>
          JPEG olarak yüklerseniz bu verileri analiz edemezsiniz.
        </Text>
        <Row className="mb-big" gutter={20}>
          {last3Years.map((year) => (
            <Col style={{marginBottom: 25}} key={year}>
              {getPreviousData(year, 'gelir') ? (
                <DataPreviewContainer
                  year={year}
                  name={getPreviousData(year, 'gelir') && getPreviousData(year, 'gelir').fileName}
                  onRemove={() => handleRemove(year, 'gelir')}
                />
              ) : (
                <Upload
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/jpeg, image/png"
                  fileList={
                    yearFilesGelir && yearFilesGelir !== {} && yearFilesGelir[year]
                      ? [yearFilesGelir[year]]
                      : []
                  }
                  onChange={(val: any) => onUploadFile(val.file, year, 'gelir')}
                  label="Excel /JPEG Yükle"
                  desc={`${year} yılı`}
                  onRemove={() => handleDeleteYearFile(year, 'gelir')}
                />
              )}
            </Col>
          ))}
          <Col span={15}>
            <Form.Item className="m-0">
              <Button
                className="mali-btn"
                type="default"
                onClick={handleGelir}
                size="large"
                disabled={isLoading}
                loading={isLoading}
                block
              >
                Gelir Tablosu Analiz Et
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item className="m-0">
              <Button type="primary" htmlType="submit" size="large" loading={isLoadingSubmit} block>
                Kaydet ve İlerle
              </Button>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item className="m-0">
              <Button
                type="default"
                size="large"
                loading={isLoadingSubmit}
                onClick={onCancel}
                block
              >
                Kaydet ve Çık
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default FinancialInfo;
