import React, {useEffect, useState, VFC} from 'react';
import {Col, Row, Spin} from 'antd';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import ContentContainer from '../../../components/ContentContainer';
import Icon from '../../../components/Icon';
import Text from '../../../components/Text';
import SaleInfoStatus from './SaleInfoStatus';
import searchApi from '../../../Api/Search';
import paths from '../../../routes/paths';
import {useAppSelector} from '../../../hooks/useRedux';
import {
  fetchCities,
  fetchCurrencyTypes,
  fetchPaymentTypes,
} from '../../../store/globals/globalsActions';
import {CREATE_SALES_FIELD_INFO} from '../../../constants';
import {setCustomerQueryData} from '../../../store/customer/customerActions';

const saleInfoStatusItems: {key: string; text: string; icon: string}[] = [
  {
    key: CREATE_SALES_FIELD_INFO.COMPANY_INFO.key,
    text: CREATE_SALES_FIELD_INFO.COMPANY_INFO.title,
    icon: CREATE_SALES_FIELD_INFO.COMPANY_INFO.icon,
  },
  {
    key: CREATE_SALES_FIELD_INFO.SALES_UNIT_WORK_CONDITION.key,
    text: CREATE_SALES_FIELD_INFO.SALES_UNIT_WORK_CONDITION.title,
    icon: CREATE_SALES_FIELD_INFO.SALES_UNIT_WORK_CONDITION.icon,
  },
  {
    key: CREATE_SALES_FIELD_INFO.COMPANY_IDENTIFIER_INFO.key,
    text: CREATE_SALES_FIELD_INFO.COMPANY_IDENTIFIER_INFO.title,
    icon: CREATE_SALES_FIELD_INFO.COMPANY_IDENTIFIER_INFO.icon,
  },
  {
    key: CREATE_SALES_FIELD_INFO.SALES_UNIT_FEEDBACK.key,
    text: CREATE_SALES_FIELD_INFO.SALES_UNIT_FEEDBACK.title,
    icon: CREATE_SALES_FIELD_INFO.SALES_UNIT_FEEDBACK.icon,
  },
];

const SaleDetail: VFC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams() as {taxNumber: string};
  const [isLoadingtaxOffices, setIsLoadingTaxOffices] = useState(false);
  const {cities, isLoadingCities} = useAppSelector((state) => state.globals);
  const {customerSale}: any = useAppSelector((state) => state.customer);
  const fetchTaxOffices = async (provinceCode: number) => {
    try {
      setIsLoadingTaxOffices(true);
      await searchApi.fetchTaxOffices(provinceCode);
    } finally {
      setIsLoadingTaxOffices(false);
    }
  };

  useEffect(() => {
    if (customerSale.province) {
      const found: any = cities.find((city) => city.label === customerSale.province);
      if (found) fetchTaxOffices(found.value);
    } else {
      history.push(paths.sales);
    }
  }, [customerSale]);

  useEffect(() => {
    dispatch(fetchCities());
    dispatch(fetchPaymentTypes());
    dispatch(fetchCurrencyTypes());
  }, []);

  const handleReport = () => {
    history.push(paths.getSalesDetailPath(params.taxNumber));
  };

  const handleStepKey = (key: any) => {
    dispatch(
      setCustomerQueryData({
        ...customerSale,
        customerStepKey: key,
      }),
    );
    history.push(`${paths.salesCreate}?id=${customerSale.id}`);
  };

  return (
    <Spin spinning={isLoadingtaxOffices || isLoadingCities}>
      <ContentContainer>
        <Row className="mb-big" align="middle" gutter={20}>
          <Col>
            <Text type="h2">Saha Analizi</Text>
          </Col>

          <Col onClick={handleReport}>
            <Text type="h4" underlined>
              Detaylı Rapor Al <Icon icon="file-download" />
            </Text>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Text bold block>
              Unvanı:
            </Text>
            <Text>{customerSale?.accountName}</Text>
            <br />
            <br />
            <Text bold block>
              Vergi Kimlik No:
            </Text>
            <Text>{customerSale?.taxNumber}</Text>
            <br />
            <br />
            <Text bold block>
              Adres:
            </Text>
            <Text>{customerSale?.address}</Text>
            <br />
            <br />
          </Col>
          <Col span={12}>
            {saleInfoStatusItems.map(({key, text, icon}) => (
              <SaleInfoStatus
                className="mb hover-scale"
                key={key}
                icon={icon}
                text={text}
                onClick={() => handleStepKey(key)}
              />
            ))}
          </Col>
        </Row>
      </ContentContainer>
    </Spin>
  );
};

export default SaleDetail;
