import React, {FC} from 'react';
import {Col, Row} from 'antd';
import Icon, {CustomIcon} from '../../../../components/Icon';
import Text from '../../../../components/Text';
import CustomerInfoStatusContainer from './styles';

interface Props {
  className?: string;
  icon: string;
  text: string;
  status: boolean;
  onClick: () => void;
}

const CustomerInfoStatus: FC<Props> = ({className, icon, text, status, onClick}) => {
  return (
    <CustomerInfoStatusContainer className={`cursor-pointer ${className || ''}`} onClick={onClick}>
      <Row justify="space-between" align="middle">
        <Col>
          <CustomIcon icon={icon} className="mr-big" />
          <Text>{text}</Text>
        </Col>

        <Col>
          {status ? (
            <Icon icon="check-circle" color="green" size="lg" />
          ) : (
            <Text color="primary">Bilgileri Giriniz {'>'}</Text>
          )}
        </Col>
      </Row>
    </CustomerInfoStatusContainer>
  );
};

export default CustomerInfoStatus;
