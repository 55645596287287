/* eslint-disable func-names */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable import/no-cycle */
import html2canvas from 'html2canvas';
import {Buffer} from 'buffer';
import {IApiImage} from '../Api/types';
import searchApi from '../Api/Search';

/* eslint-disable import/prefer-default-export */
export const urlToFile = async (url = '', name = 'image', extension = '.pdf') => {
  const response = await fetch(url);
  // here image is url/location of image
  const blob = await response.blob();
  const file = new File([blob], `${name}${extension}`, {type: blob.type});

  return file;
};

export const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const getBase64 = (file: any): Promise<IApiImage> => {
  return new Promise((resolve, reject) => {
    const filename = file?.name || 'dosya';
    const reader = new FileReader();
    reader.onload = function () {
      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
      let encoded: any = reader.result!.toString().replace(/^data:(.*,)?/, '');
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }

      resolve({filename, base64FileContent: encoded});
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export function dataURLtoFile(streamData: string, filename: string) {
  let b64 = Buffer.from(streamData).toString('base64');
  b64 = `data:applicatioin/pdf;base64,${b64}`;

  const arr = b64.split(',');
  // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type: mime});
}

export function convertFileToAntdFile(file: File) {
  return {...file, originFileObj: file};
}

export const download = function (link: string, filename = 'tci') {
  const linkElement = document.createElement('a');
  linkElement.download = filename;
  linkElement.href = link;
  linkElement.click();
};

export const dowloadDivAsImage = (divId = '', fileTitle = 'tci-customer') => {
  const content = document.querySelector(`#${divId}`) as HTMLElement;

  html2canvas(content).then((canvas) => {
    const link = canvas.toDataURL();
    download(link, fileTitle);
  });
};

export function s2ab(s: string) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

export function createExcelFile(data: string) {
  const blob = new Blob([Buffer.from(data, 'base64')], {
    type: '',
  });

  return URL.createObjectURL(blob);
}

export const FilePromise = (fileDocumnet: any) => {
  return new Promise((resolve) => {
    searchApi.fetchDocument(fileDocumnet.tciDoumentId).then((_file) => {
      const name = fileDocumnet?.fileName;
      const renamedFile = new File([_file], name);
      resolve(renamedFile);
    });
  });
};

export const FilePromiseYear = (fileDocumnet: any, year: any) => {
  return new Promise((resolve) => {
    searchApi.fetchDocument(fileDocumnet).then((_file) => {
      const name = `${year}.pdf`;
      const renamedFile = new File([_file], name);
      resolve(renamedFile);
    });
  });
};

export const fileKeyPair: any = {
  DONEN_VARLIKLAR_TOPLAMI: 'workingAssets',
  DURAN_VARLIKLAR_TOPLAMI: 'longTermAssets',
  C_NETSATISLAR_HEADER: 'netSales',
  AKTIF_TOPLAMI: 'totalAssests',
  KISA_VADELI_YABANCI_KAYNAKLAR_TOPLAMI: 'shortTermLiabilities',
  UZUN_VADELI_YABANCI_KAYNAKLAR_TOPLAMI: 'longTermLiabilities',
  OZ_KAYNAKLAR_TOPLAMI: 'ownFunds',
  PASIF_TOPLAMI: 'totalLiabilities',
  DONEMNETKARIVEYAZARARI_HEADER: 'periodProfitLoss',
};

export const fileKeyNamePair: any = {
  workingAssets: 'Dönen Varlıklar',
  longTermAssets: 'Duran Varlıklar',
  totalAssests: 'Aktif Tolplam',
  shortTermLiabilities: 'Kısa Vadeli Borçlar',
  longTermLiabilities: 'Uzun Vadeli Borçlar',
  ownFunds: 'Öz Sermaye',
  totalLiabilities: 'Pasif Toplam',
  netWorkingCapital: 'Net İşleme Sermayesi',
  workingCapitalRatio: 'Cari Oran',
  financingRate: 'Finansman Oranı',
  netSales: 'Net Satışlar',
  periodProfitLoss: 'Dönem Karı veya Zararı',
};

export const dummyData = {
  header: {baslik: 'KESIN_SAHIS_BEYANNAMESI'},
  keyValue: [
    {
      key: 'DONEN_VARLIKLAR_TOPLAMI',
      value: '0,00 7.939.964,93',
    },
    {
      key: 'DONEN_A_HAZIRDEGERLER_HEADER',
      value: '0,00 771.857,28',
    },
    {
      key: 'DONEN_A_HAZIRDEGERLER_1_KASA',
      value: '0,00 19.374,00',
    },
    {
      key: 'DONEN_A_HAZIRDEGERLER_2_ALINANCEKLER',
      value: '',
    },
    {
      key: 'DONEN_A_HAZIRDEGERLER_3_BANKALAR',
      value: '0,00 2.152.483,28',
    },
    {
      key: 'DONEN_A_HAZIRDEGERLER_4_VERILENCEKLERVEODEMEEMIRLERI',
      value: '0,00 1.400.000,00',
    },
    {
      key: 'DONEN_A_HAZIRDEGERLER_5_DIGERHAZIRDEGERLER',
      value: '',
    },
    {
      key: 'DONEN_B_MENKULKIYMETLER_HEADER',
      value: '',
    },
    {
      key: 'DONEN_B_MENKULKIYMETLER_1_HISSESENETLERI',
      value: '',
    },
    {
      key: 'DONEN_B_MENKULKIYMETLER_2_OZELKESIMTAHVIL_SENETVEBONOLARI',
      value: '',
    },
    {
      key: 'DONEN_B_MENKULKIYMETLER_3_KAMUKESIMTAHVIL_SENETVEBONOLARI',
      value: '',
    },
    {
      key: 'DONEN_B_MENKULKIYMETLER_4_DIGERMENKULKIYMETLER',
      value: '',
    },
    {
      key: 'DONEN_B_MENKULKIYMETLER_5_MENKULKIYMETLERDEGERDUSUKLUGUKARSILIGI',
      value: '',
    },
    {
      key: 'DONEN_C_TICARIALACAKLAR_HEADER',
      value: '0,00 1.967.799,70',
    },
    {
      key: 'DONEN_C_TICARIALACAKLAR_1_ALICILAR',
      value: '0,00 1.967.799,70',
    },
    {
      key: 'DONEN_C_TICARIALACAKLAR_2_ALACAKSENETLERI',
      value: '',
    },
    {
      key: 'DONEN_C_TICARIALACAKLAR_3_ALACAKSENETLERIREESKONTU',
      value: '',
    },
    {
      key: 'DONEN_C_TICARIALACAKLAR_4_VERILENDEPOZITOVETEMINATLAR',
      value: '',
    },
    {
      key: 'DONEN_C_TICARIALACAKLAR_4_GRUPSIRKETLERINDENTICARIALACAKLAR',
      value: '',
    },
    {
      key: 'DONEN_C_TICARIALACAKLAR_5_KAZANILMAMISFINANSALKIRALAMAFAIZGELIRLERI',
      value: '',
    },
    {
      key: 'DONEN_C_TICARIALACAKLAR_6_DIGERTICARIALACAKLAR',
      value: '',
    },
    {
      key: 'DONEN_C_TICARIALACAKLAR_7_SUPHELITICARIALACAKLAR',
      value: '',
    },
    {
      key: 'DONEN_C_TICARIALACAKLAR_8_SUPHELITICARIALACAKLARKARSILIGI',
      value: '',
    },
    {
      key: 'DONEN_D_DIGERALACAKLAR_HEADER',
      value: '',
    },
    {
      key: 'DONEN_D_DIGERALACAKLAR_1_ORTAKLARDANALACAKLAR',
      value: '',
    },
    {
      key: 'DONEN_D_DIGERALACAKLAR_2_ISTIRAKLERDENALACAKLAR',
      value: '',
    },
    {
      key: 'DONEN_D_DIGERALACAKLAR_3_BAGLIORTAKLILARDANALACAKLAR',
      value: '',
    },
    {
      key: 'DONEN_D_DIGERALACAKLAR_4_PERSONELDENALACAKLAR',
      value: '',
    },
    {
      key: 'DONEN_D_DIGERALACAKLAR_5_DIGERCESITLIALACAKLAR',
      value: '',
    },
    {
      key: 'DONEN_D_DIGERALACAKLAR_6_DIGERALACAKSENETLERIREESKONTU',
      value: '',
    },
    {
      key: 'DONEN_D_DIGERALACAKLAR_7_SUPHELIDIGERALACAKLAR',
      value: '',
    },
    {
      key: 'DONEN_D_DIGERALACAKLAR_8_SUPHELIDIGERALACAKLARKARSILIGI',
      value: '',
    },
    {
      key: 'DONEN_D_DIGERALACAKLAR_9_ERTELENMISVERGIALACAGI',
      value: '',
    },
    {
      key: 'DONEN_E_STOKLAR_HEADER',
      value: '0,00 5.200.307,95',
    },
    {
      key: 'DONEN_E_STOKLAR_1_ILKMADDEVEMALZEME',
      value: '0,00 1.337.552,87',
    },
    {
      key: 'DONEN_E_STOKLAR_2_YARIMAMULLER',
      value: '0,00 91.111,59',
    },
    {
      key: 'DONEN_E_STOKLAR_3_MAMULLER',
      value: '0,00 473.537,00',
    },
    {
      key: 'DONEN_E_STOKLAR_4_TICARIMALLAR',
      value: '',
    },
    {
      key: 'DONEN_E_STOKLAR_5_DIGERSTOKLAR',
      value: '',
    },
    {
      key: 'DONEN_E_STOKLAR_6_STOKDEGERDUSUKLUGUKARSILIGI',
      value: '',
    },
    {
      key: 'DONEN_E_STOKLAR_7_VERILENSIPARISAVANSLARI',
      value: '0,00 3.298.106,49',
    },
    {
      key: 'DONEN_F_YILLARAYAYGININSAATVEONARIMMALIYETLERI_HEADER',
      value: '',
    },
    {
      key: 'DONEN_F_YILLARAYAYGININSAATVEONARIMMALIYETLERI_1_YILLARAYAYGININSAATVEONARIMMALIYETLERI',
      value: '',
    },
    {
      key: 'DONEN_F_YILLARAYAYGININSAATVEONARIMMALIYETLERI_2_YILLARAYAYGININSAATENFLASYONDUZELTMEHESABI',
      value: '',
    },
    {
      key: 'DONEN_F_YILLARAYAYGININSAATVEONARIMMALIYETLERI_3_TASERONLARAVERILENAVANSLAR',
      value: '',
    },
    {
      key: 'DONEN_G_GELECEKAYLARAAITGIDERLERVEGELIRTAHAKKUKLARI_HEADER',
      value: '',
    },
    {
      key: 'DONEN_G_GELECEKAYLARAAITGIDERLERVEGELIRTAHAKKUKLARI_1_GELECEKAYLARAAITGIDERLER',
      value: '',
    },
    {
      key: 'DONEN_G_GELECEKAYLARAAITGIDERLERVEGELIRTAHAKKUKLARI_2_GELIRTAHAKKUKLARI',
      value: '',
    },
    {
      key: 'DONEN_H_DIGERDONENVARLIKLAR_HEADER',
      value: '',
    },
    {
      key: 'DONEN_H_DIGERDONENVARLIKLAR_1_DEVREDENKDV',
      value: '',
    },
    {
      key: 'DONEN_H_DIGERDONENVARLIKLAR_2_INDIRILECEKKDV',
      value: '',
    },
    {
      key: 'DONEN_H_DIGERDONENVARLIKLAR_3_DIGERKDV',
      value: '',
    },
    {
      key: 'DONEN_H_DIGERDONENVARLIKLAR_4_PESINODENENVERGIVEFONLAR',
      value: '',
    },
    {
      key: 'DONEN_H_DIGERDONENVARLIKLAR_5_ISAVANSLARI',
      value: '',
    },
    {
      key: 'DONEN_H_DIGERDONENVARLIKLAR_6_PERSONELAVANSLARI',
      value: '',
    },
    {
      key: 'DONEN_H_DIGERDONENVARLIKLAR_7_SAYIMVETESELLUMNOKSANLARI',
      value: '',
    },
    {
      key: 'DONEN_H_DIGERDONENVARLIKLAR_8_DIGERCESITLIDONENVARLIKLAR',
      value: '',
    },
    {
      key: 'DONEN_H_DIGERDONENVARLIKLAR_8_DIGERCESITLIDONENVARLIKLARKARSILIGI',
      value: '',
    },
    {
      key: 'DURAN_VARLIKLAR_TOPLAMI',
      value: '0,00 248.608,80',
    },
    {
      key: 'DURAN_A_TICARIALACAKLAR_HEADER',
      value: '0,00 20.971,81',
    },
    {
      key: 'DURAN_A_TICARIALACAKLAR_1_ALICILAR',
      value: '',
    },
    {
      key: 'DURAN_A_TICARIALACAKLAR_2_ALACAKSENETLERI',
      value: '',
    },
    {
      key: 'DURAN_A_TICARIALACAKLAR_3_ALACAKSENETLERIREESKONTU',
      value: '',
    },
    {
      key: 'DURAN_A_TICARIALACAKLAR_4_KAZANILMAMISFINANSALKIRALAMAFAIZGELIRLERI',
      value: '',
    },
    {
      key: 'DURAN_A_TICARIALACAKLAR_5_VERILENDEPOZITOVETEMINATLAR',
      value: '0,00 20.971,81',
    },
    {
      key: 'DURAN_A_TICARIALACAKLAR_6_SUPHELITICARIALACAKLARKARSILIGI',
      value: '',
    },
    {
      key: 'DURAN_B_DIGERALACAKLAR_HEADER',
      value: '',
    },
    {
      key: 'DURAN_B_DIGERALACAKLAR_1_ORTAKLARDANALACAKLAR',
      value: '',
    },
    {
      key: 'DURAN_B_DIGERALACAKLAR_2_ISTIRAKLERDENALACAKLAR',
      value: '',
    },
    {
      key: 'DURAN_B_DIGERALACAKLAR_3_BAGLIORTAKLILARDANALACAKLAR',
      value: '',
    },
    {
      key: 'DURAN_B_DIGERALACAKLAR_4_PERSONELDENALACAKLAR',
      value: '',
    },
    {
      key: 'DURAN_B_DIGERALACAKLAR_5_DIGERCESITLIALACAKLAR',
      value: '',
    },
    {
      key: 'DURAN_B_DIGERALACAKLAR_6_DIGERALACAKSENETLERIREESKONTU',
      value: '',
    },
    {
      key: 'DURAN_B_DIGERALACAKLAR_7_SUPHELIDIGERALACAKLARKARSILIGI',
      value: '',
    },
    {
      key: 'DURAN_B_DIGERALACAKLAR_8_ERTELENMISVERGIALACAGI',
      value: '',
    },
    {
      key: 'DURAN_C_MALIDURANVARLIKLAR_HEADER',
      value: '',
    },
    {
      key: 'DURAN_C_MALIDURANVARLIKLAR_1_BAGLIMENKULKIYMETLER',
      value: '',
    },
    {
      key: 'DURAN_C_MALIDURANVARLIKLAR_2_BAGLIMENKULKIYMETLERDEGERDUSUKLUGUKARSILIGI',
      value: '',
    },
    {
      key: 'DURAN_C_MALIDURANVARLIKLAR_3_ISTIRAKLER',
      value: '',
    },
    {
      key: 'DURAN_C_MALIDURANVARLIKLAR_4_ISTIRAKLERSERMAYEPAYLARITAAHUTLERI',
      value: '',
    },
    {
      key: 'DURAN_C_MALIDURANVARLIKLAR_5_ISTIRAKLERSERMAYETAAHUTLERIDEGERDUSUKLUGUKARSILIGI',
      value: '',
    },
    {
      key: 'DURAN_C_MALIDURANVARLIKLAR_6_BAGLIORTAKLIKLAR',
      value: '',
    },
    {
      key: 'DURAN_C_MALIDURANVARLIKLAR_7_BAGLIORTAKLIKLARSERMAYETAAHHUTLERI',
      value: '',
    },
    {
      key: 'DURAN_C_MALIDURANVARLIKLAR_8_BAGLIORT_SERMAYEPAYLARIDEGERDUSUKLUGUKARSILIGI',
      value: '',
    },
    {
      key: 'DURAN_C_MALIDURANVARLIKLAR_9_DIGERMALIDURANVARLIKLAR',
      value: '',
    },
    {
      key: 'DURAN_C_MALIDURANVARLIKLAR_10_DIGERMALIDURANVARLIKLARDEGERDUSUKLUGUKARSILIGI',
      value: '',
    },
    {
      key: 'DURAN_D_MADDIDURANVARLIKLAR_HEADER',
      value: '0,00 132.978,57',
    },
    {
      key: 'DURAN_D_MADDIDURANVARLIKLAR_1_ARSAVEARAZILER',
      value: '',
    },
    {
      key: 'DURAN_D_MADDIDURANVARLIKLAR_2_YERALTIVEYERUSTUDUZENLERI',
      value: '',
    },
    {
      key: 'DURAN_D_MADDIDURANVARLIKLAR_3_BINALAR',
      value: '',
    },
    {
      key: 'DURAN_D_MADDIDURANVARLIKLAR_4_TESIS_MAKINAVECIHAZLAR',
      value: '0,00 80.000,00',
    },
    {
      key: 'DURAN_D_MADDIDURANVARLIKLAR_5_TASITLAR',
      value: '',
    },
    {
      key: 'DURAN_D_MADDIDURANVARLIKLAR_6_DEMIRBASLAR',
      value: '0,00 70.647,06',
    },
    {
      key: 'DURAN_D_MADDIDURANVARLIKLAR_7_DIGERMADDIDURANVARLIKLAR',
      value: '',
    },
    {
      key: 'DURAN_D_MADDIDURANVARLIKLAR_8_BIRIKMISAMORTISMANLAR',
      value: '0,00 17.668,49',
    },
    {
      key: 'DURAN_D_MADDIDURANVARLIKLAR_9_YAPILMAKTAOLANYATIRIMLAR',
      value: '',
    },
    {
      key: 'DURAN_D_MADDIDURANVARLIKLAR_10_VERILENAVANSLAR',
      value: '',
    },
    {
      key: 'DURAN_E_MADDIOLMAYANDURANVARLIKLAR_HEADER',
      value: '0,00 32.772,62',
    },
    {
      key: 'DURAN_E_MADDIOLMAYANDURANVARLIKLAR_1_HAKLAR',
      value: '',
    },
    {
      key: 'DURAN_E_MADDIOLMAYANDURANVARLIKLAR_2_SEREFIYE',
      value: '',
    },
    {
      key: 'DURAN_E_MADDIOLMAYANDURANVARLIKLAR_3_KURULUSVEORGUTLENMEGIDERLERI',
      value: '',
    },
    {
      key: 'DURAN_E_MADDIOLMAYANDURANVARLIKLAR_4_ARASTIRMAVEGELISTIRMEGIDERLERI',
      value: '',
    },
    {
      key: 'DURAN_E_MADDIOLMAYANDURANVARLIKLAR_5_OZELMALIYETLER',
      value: '0,00 40.965,78',
    },
    {
      key: 'DURAN_E_MADDIOLMAYANDURANVARLIKLAR_6_DIGERMADDIOLMAYANDURANVARLIKLAR',
      value: '',
    },
    {
      key: 'DURAN_E_MADDIOLMAYANDURANVARLIKLAR_7_BIRIKMISAMORTISMANLAR',
      value: '0,00 8.193,16',
    },
    {
      key: 'DURAN_E_MADDIOLMAYANDURANVARLIKLAR_8_VERILENAVANSLAR',
      value: '',
    },
    {
      key: 'DURAN_F_OZELTUKENMEYETABIVARLIKLAR_HEADER',
      value: '',
    },
    {
      key: 'DURAN_F_OZELTUKENMEYETABIVARLIKLAR_1_ARAMAGIDERLERI',
      value: '',
    },
    {
      key: 'DURAN_F_OZELTUKENMEYETABIVARLIKLAR_2_HAZIRLIKVEGELISTIRMEGIDERLERI',
      value: '',
    },
    {
      key: 'DURAN_F_OZELTUKENMEYETABIVARLIKLAR_3_DIGEROZELTUKENMEYETABIGIDERLER',
      value: '',
    },
    {
      key: 'DURAN_F_OZELTUKENMEYETABIVARLIKLAR_4_BIRIKMISTUKENMEPAYLARI',
      value: '',
    },
    {
      key: 'DURAN_F_OZELTUKENMEYETABIVARLIKLAR_5_VERILENAVANSLAR',
      value: '0,00 0,00',
    },
    {
      key: 'DURAN_G_GELECEKYILLARAAITGIDERLERVEGELIRTAHAKKUKLARI_HEADER',
      value: '0,00 61.885,80',
    },
    {
      key: 'DURAN_G_GELECEKYILLARAAITGIDERLERVEGELIRTAHAKKUKLARI_1_GELECEKYILLARAAITGIDERLER',
      value: '0,00 61.885,80',
    },
    {
      key: 'DURAN_G_GELECEKYILLARAAITGIDERLERVEGELIRTAHAKKUKLARI_2_GELIRTAHAKKUKLARI',
      value: '',
    },
    {
      key: 'DURAN_H_DIGERDURANVARLIKLAR_HEADER',
      value: '',
    },
    {
      key: 'DURAN_H_DIGERDURANVARLIKLAR_1_GELECEKYILLARDAINDIRILECEKKDV',
      value: '',
    },
    {
      key: 'DURAN_H_DIGERDURANVARLIKLAR_2_DIGERKDV',
      value: '',
    },
    {
      key: 'DURAN_H_DIGERDURANVARLIKLAR_3_GELECEKYILLARIHTIYACISTOKLAR',
      value: '',
    },
    {
      key: 'DURAN_H_DIGERDURANVARLIKLAR_4_ELDENCIKARILACAKSTOKLARVEMADDIDURANVARLIKLAR',
      value: '',
    },
    {
      key: 'DURAN_H_DIGERDURANVARLIKLAR_5_PESINODENENVERGILERVEFONLAR',
      value: '',
    },
    {
      key: 'DURAN_H_DIGERDURANVARLIKLAR_6_DIGERCESITLIDURANVARLIKLAR',
      value: '',
    },
    {
      key: 'DURAN_H_DIGERDURANVARLIKLAR_6_GECICIHESAP',
      value: '',
    },
    {
      key: 'DURAN_H_DIGERDURANVARLIKLAR_7_STOKDEGERDUSUKLUGUKARSILIGI',
      value: '',
    },
    {
      key: 'DURAN_H_DIGERDURANVARLIKLAR_8_BIRIKMISAMORTISMANLAR',
      value: '0,00 0,00',
    },
    {
      key: 'AKTIF_TOPLAMI',
      value: '',
    },
    {
      key: 'KISA_VADELI_YABANCI_KAYNAKLAR_TOPLAMI',
      value: '0,00 4.284.382,78',
    },
    {
      key: 'KISA_A_MALIBORCLAR_HEADER',
      value: '',
    },
    {
      key: 'KISA_A_MALIBORCLAR_1_BANKAKREDILERI',
      value: '',
    },
    {
      key: 'KISA_A_MALIBORCLAR_2_FINANSALKIRALAMAISLEMLERINDENBORCLAR',
      value: '',
    },
    {
      key: 'KISA_A_MALIBORCLAR_3_ERTELENMISFINANSALKIRALAMABORCLANMA',
      value: '',
    },
    {
      key: 'KISA_A_MALIBORCLAR_4_UZUNVADELIKREDILERINANAPARATAKSITLERIVE',
      value: '',
    },
    {
      key: 'KISA_A_MALIBORCLAR_5_TAHVILANAPARABORCTAKSITVEFAIZLERI',
      value: '',
    },
    {
      key: 'KISA_A_MALIBORCLAR_6_CIKARILMISBONOLARVESENETLER',
      value: '',
    },
    {
      key: 'KISA_A_MALIBORCLAR_7_CIKARILMISDIGERMENKULKIYMETLER',
      value: '',
    },
    {
      key: 'KISA_A_MALIBORCLAR_8_MENKULKIYMETLERIHRACFARKI',
      value: '',
    },
    {
      key: 'KISA_A_MALIBORCLAR_9_DIGERMALIBORCLAR',
      value: '',
    },
    {
      key: 'KISA_B_TICARIBORCLAR_HEADER',
      value: '0,00 1.879.435,24',
    },
    {
      key: 'KISA_B_TICARIBORCLAR_1_SATICILAR',
      value: '0,00 1.879.435,24',
    },
    {
      key: 'KISA_B_TICARIBORCLAR_2_BORCSENETLERI',
      value: '',
    },
    {
      key: 'KISA_B_TICARIBORCLAR_3_BORCSENETLERIREESKONTU',
      value: '',
    },
    {
      key: 'KISA_B_TICARIBORCLAR_4_ALINANDEPOZITOVETEMINATLAR',
      value: '',
    },
    {
      key: 'KISA_B_TICARIBORCLAR_5_GRUPSIRKETLERINETICARIBORCLAR',
      value: '',
    },
    {
      key: 'KISA_B_TICARIBORCLAR_5_DIGERTICARIBORCLAR',
      value: '',
    },
    {
      key: 'KISA_C_DIGERTICARIBORCLAR_HEADER',
      value: '0,00 97.822,83',
    },
    {
      key: 'KISA_C_DIGERTICARIBORCLAR_1_ORTAKLARABORCLAR',
      value: '',
    },
    {
      key: 'KISA_C_DIGERTICARIBORCLAR_2_ISTIRAKLEREBORCLAR',
      value: '',
    },
    {
      key: 'KISA_C_DIGERTICARIBORCLAR_3_BAGLIORTAKLIKLARABORCLAR',
      value: '',
    },
    {
      key: 'KISA_C_DIGERTICARIBORCLAR_4_PERSONELEBORCLAR',
      value: '0,00 97.822,83',
    },
    {
      key: 'KISA_C_DIGERTICARIBORCLAR_5_DIGERCESITLIBORCLAR',
      value: '',
    },
    {
      key: 'KISA_C_DIGERTICARIBORCLAR_6_DIGERBORCSENETLERIREESKONTU',
      value: '',
    },
    {
      key: 'KISA_C_DIGERTICARIBORCLAR_7_ERTELENMISVERGIYUKUMLULUGU',
      value: '',
    },
    {
      key: 'KISA_D_ALINANAVANSLAR_HEADER',
      value: '0,00 2.263.008,65',
    },
    {
      key: 'KISA_D_ALINANAVANSLAR_1_ALINANSIPARISAVANSLARI',
      value: '0,00 2.263.008,65',
    },
    {
      key: 'KISA_D_ALINANAVANSLAR_2_ALINANDIGERAVANSLAR',
      value: '',
    },
    {
      key: 'KISA_E_YILLARAYAYGININSAATVEONARIMHAKEDISLERI_HEADER',
      value: '',
    },
    {
      key: 'KISA_E_YILLARAYAYGININSAATVEONARIMHAKEDISLERI_1_YILLARAYAYGININSAATVEONARIMHAKEDISLERIBEDELLERI',
      value: '',
    },
    {
      key: 'KISA_E_YILLARAYAYGININSAATVEONARIMHAKEDISLERI_2_YILLARAYAYGININSAATENFLASYONDUZELTMEHESABI',
      value: '',
    },
    {
      key: 'KISA_F_ODENECEKVERGIVEDIGERYUKUMLULUKLER_HEADER',
      value: '0,00 44.116,06',
    },
    {
      key: 'KISA_F_ODENECEKVERGIVEDIGERYUKUMLULUKLER_1_ODENECEKVERGIVEFONLAR',
      value: '0,00 10.804,74',
    },
    {
      key: 'KISA_F_ODENECEKVERGIVEDIGERYUKUMLULUKLER_2_ODENECEKOSOSYALGUVENLIKKESINTILERI',
      value: '0,00 32.314,32',
    },
    {
      key: 'KISA_F_ODENECEKVERGIVEDIGERYUKUMLULUKLER_3_TAKSITLENDIRILMISVERGIVEDIGERYUKUMLULUKLER',
      value: '',
    },
    {
      key: 'KISA_F_ODENECEKVERGIVEDIGERYUKUMLULUKLER_4_ODENECEKDIGERYUKUMLULUKLER',
      value: '0,00 997,00',
    },
    {
      key: 'KISA_G_BORCVEGIDERKARSILIKLARI_HEADER',
      value: '',
    },
    {
      key: 'KISA_G_BORCVEGIDERKARSILIKLARI_1_DONEMKARIVERGIVEDIGERYASALYUKUMLULUK',
      value: '',
    },
    {
      key: 'KISA_G_BORCVEGIDERKARSILIKLARI_2_DONEMKARININPESINODENENVERGIVEDIGER',
      value: '',
    },
    {
      key: 'KISA_G_BORCVEGIDERKARSILIKLARI_3_KIDEMTAZMINATIKARSILIGI',
      value: '',
    },
    {
      key: 'KISA_G_BORCVEGIDERKARSILIKLARI_4_MALIYETGIDERLERIKARSILIGI',
      value: '',
    },
    {
      key: 'KISA_G_BORCVEGIDERKARSILIKLARI_5_DIGERBORCVEGIDERKARSILIKLARI',
      value: '',
    },
    {
      key: 'KISA_H_GELECEKAYLARAAITGELIRLERVEGIDERTAHAKKUKLARI_HEADER',
      value: '',
    },
    {
      key: 'KISA_H_GELECEKAYLARAAITGELIRLERVEGIDERTAHAKKUKLARI_1_GELECEKAYLARAAITGELIRLER',
      value: '',
    },
    {
      key: 'KISA_H_GELECEKAYLARAAITGELIRLERVEGIDERTAHAKKUKLARI_2_GIDERTAHAKKUKLARI',
      value: '',
    },
    {
      key: 'KISA_I_DIGERKISAVADELIYABANCIKAYNAKLAR_HEADER',
      value: '',
    },
    {
      key: 'KISA_I_DIGERKISAVADELIYABANCIKAYNAKLAR_1_HESAPLANANKDV',
      value: '0,00 0,00',
    },
    {
      key: 'KISA_I_DIGERKISAVADELIYABANCIKAYNAKLAR_2_DIGERKDV',
      value: '0,00 0,00',
    },
    {
      key: 'KISA_I_DIGERKISAVADELIYABANCIKAYNAKLAR_3_MERKEZSUBELERVECARIHESABI',
      value: '0,00 0,00',
    },
    {
      key: 'KISA_I_DIGERKISAVADELIYABANCIKAYNAKLAR_4_SAYIMVETESELLUMFAZLALARI',
      value: '0,00 0,00',
    },
    {
      key: 'KISA_I_DIGERKISAVADELIYABANCIKAYNAKLAR_5_DIGERCESITLIYABANCIKAYNAKLAR',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_VADELI_YABANCI_KAYNAKLAR_TOPLAMI',
      value: '',
    },
    {
      key: 'UZUN_A_MALIBORCLAR_HEADER',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_A_MALIBORCLAR_1_BANKAKREDILERI',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_A_MALIBORCLAR_2_FINANSALKIRALAMAISLEMLERINDENBORCLAR',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_A_MALIBORCLAR_3_ERTELENMISFINANSALKIRALAMABORCLANMA',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_A_MALIBORCLAR_4_CIKARILMISTAHVILLER',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_A_MALIBORCLAR_5_CIKARILMISDIGERMENKULKIYMETLER',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_A_MALIBORCLAR_6_MENKULKIYMETLERIHRACFARKI',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_A_MALIBORCLAR_7_DIGERMALIBORCLAR',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_B_TICARIBORCLAR_HEADER',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_B_TICARIBORCLAR_1_SATICILAR',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_B_TICARIBORCLAR_2_BORCSENETLERI',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_B_TICARIBORCLAR_3_BORCSENETLERIREESKONTU',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_B_TICARIBORCLAR_4_ALINANDEPOZITOVETEMINATLAR',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_B_TICARIBORCLAR_5_DIGERTICARIBORCLAR',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_C_DIGERTICARIBORCLAR_HEADER',
      value: '',
    },
    {
      key: 'UZUN_C_DIGERTICARIBORCLAR_1_ORTAKLARABORCLAR',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_C_DIGERTICARIBORCLAR_2_ISTIRAKLEREBORCLAR',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_C_DIGERTICARIBORCLAR_3_BAGLIORTAKLIKLARABORCLAR',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_C_DIGERTICARIBORCLAR_4_DIGERCESITLIBORCLAR',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_C_DIGERTICARIBORCLAR_5_DIGERBORCSENETLERIREESKONTU',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_C_DIGERTICARIBORCLAR_6_KAMUYAOLANERTELENMISVEYATAKSITLENDIRILMIS',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_C_DIGERTICARIBORCLAR_7_ERTELENMISVERGIYUKUMLULUGU',
      value: '',
    },
    {
      key: 'UZUN_D_ALINANAVANSLAR_HEADER',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_D_ALINANAVANSLAR_1_ALINANSIPARISAVANSLARI',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_D_ALINANAVANSLAR_2_ALINANDIGERAVANSLAR',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_E_BORCVEGIDERKARSILIKLARI_HEADER',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_E_BORCVEGIDERKARSILIKLARI_1_KIDEMTAZMINATIKARSILIKLARI',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_E_BORCVEGIDERKARSILIKLARI_2_DIGERBORCVEGIDERKARSILIKLARI',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_F_GELECEKYILLARAAITGELIRLERVEGIDERTAHAKKUKLARI_HEADER',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_F_GELECEKYILLARAAITGELIRLERVEGIDERTAHAKKUKLARI_1_GELECEKYILLARAAITGELIRLER',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_F_GELECEKYILLARAAITGELIRLERVEGIDERTAHAKKUKLARI_2_GIDERTAHAKKUKLARI',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_G_DIGERUZUNVADELIYABANCIKAYNAKLAR_HEADER',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_G_DIGERUZUNVADELIYABANCIKAYNAKLAR_1_GELECEKYILLARA_ERTELENENVEYATERINEDILENKDV',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_G_DIGERUZUNVADELIYABANCIKAYNAKLAR_2_TESISEKATILMAPAYLARI',
      value: '0,00 0,00',
    },
    {
      key: 'UZUN_G_DIGERUZUNVADELIYABANCIKAYNAKLAR_3_DIGERUZUNVADELIYABANCIKAYNAKLAR',
      value: '',
    },
    {
      key: 'OZ_KAYNAKLAR_TOPLAMI',
      value: '0,00 3.904.190,95',
    },
    {
      key: 'OZKAYNAK_A_ODENMISSERMAYE_HEADER',
      value: '0,00 100.000,00',
    },
    {
      key: 'OZKAYNAK_A_ODENMISSERMAYE_1_SERMAYE',
      value: '0,00 100.000,00',
    },
    {
      key: 'OZKAYNAK_A_ODENMISSERMAYE_2_ODENMEMISSERMAYE',
      value: '',
    },
    {
      key: 'OZKAYNAK_A_ODENMISSERMAYE_3_SERMAYEDUZELTMESIOLUMLUFARKLARI',
      value: '',
    },
    {
      key: 'OZKAYNAK_A_ODENMISSERMAYE_4_SERMAYEDUZELTMESIOLUMSUZFARKLARI',
      value: '',
    },
    {
      key: 'OZKAYNAK_B_SERMAYEYEDEKLERI_HEADER',
      value: '',
    },
    {
      key: 'OZKAYNAK_B_SERMAYEYEDEKLERI_1_HISSESENEDIIHRACPRIMLERI',
      value: '',
    },
    {
      key: 'OZKAYNAK_B_SERMAYEYEDEKLERI_2_HISSESENEDIIPTALKARARLARI',
      value: '',
    },
    {
      key: 'OZKAYNAK_B_SERMAYEYEDEKLERI_3_M_D_V_YENIDENDEGERLEMEARTISLARI',
      value: '',
    },
    {
      key: 'OZKAYNAK_B_SERMAYEYEDEKLERI_3_BORSADAOLUSANDEGERARTISI',
      value: '',
    },
    {
      key: 'OZKAYNAK_B_SERMAYEYEDEKLERI_4_ISTIRAKLERYENIDENDEGERLEMEARTISLARI',
      value: '',
    },
    {
      key: 'OZKAYNAK_B_SERMAYEYEDEKLERI_5_MALIYETARTISFONU',
      value: '',
    },
    {
      key: 'OZKAYNAK_B_SERMAYEYEDEKLERI_6_KAYDAALINANEMTIAOZELKARSILIKHESABI',
      value: '',
    },
    {
      key: 'OZKAYNAK_B_SERMAYEYEDEKLERI_7_DEMIRBASMAKINEVETECHIZATOZELKARSILIK',
      value: '',
    },
    {
      key: 'OZKAYNAK_B_SERMAYEYEDEKLERI_8_DIGERSERMAYEYEDEKLERI',
      value: '',
    },
    {
      key: 'OZKAYNAK_C_KARYEDEKLERI_HEADER',
      value: '',
    },
    {
      key: 'OZKAYNAK_C_KARYEDEKLERI_1_YASALYEDEKLER',
      value: '',
    },
    {
      key: 'OZKAYNAK_C_KARYEDEKLERI_2_STATUYEDEKLERI',
      value: '',
    },
    {
      key: 'OZKAYNAK_C_KARYEDEKLERI_3_OLAGANUSTUYEDEKLER',
      value: '',
    },
    {
      key: 'OZKAYNAK_C_KARYEDEKLERI_4_DIGERKARYEDEKLERI',
      value: '',
    },
    {
      key: 'OZKAYNAK_C_KARYEDEKLERI_5_OZELFONLAR',
      value: '',
    },
    {
      key: 'OZKAYNAK_C_KARYEDEKLERI_6_MALIYETARTISFONU',
      value: '',
    },
    {
      key: 'OZKAYNAK_D_GECMISYILKARLARI_HEADER',
      value: '',
    },
    {
      key: 'OZKAYNAK_D_GECMISYILKARLARI_1_GECMISYILKARLARI',
      value: '',
    },
    {
      key: 'OZKAYNAK_E_GECMISYILLARZARARLARI_HEADER',
      value: '',
    },
    {
      key: 'OZKAYNAK_D_GECMISYILKARLARI_1_GECMISYILLARZARARLARI',
      value: '',
    },
    {
      key: 'OZKAYNAK_F_DONEMNETKARIZARARI_HEADER',
      value: '0,00 3.804.190,95',
    },
    {
      key: 'OZKAYNAK_1_DONEMNETKARI',
      value: '0,00 3.804.190,95',
    },
    {
      key: 'OZKAYNAK_2_DONEMNETZARARI',
      value: '',
    },
    {
      key: 'PASIF_TOPLAMI',
      value: '0,00 8.188.573,73',
    },
    {
      key: 'A_BRUTSATISLAR_HEADER',
      value: '0,00 14.006.321,03',
    },
    {
      key: 'A_BRUTSATISLAR_1_YURTICISATISLAR',
      value: '0,00 2.634.907,57',
    },
    {
      key: 'A_BRUTSATISLAR_2_YURTDISISATISLAR',
      value: '0,00 11.340.246,12',
    },
    {
      key: 'A_BRUTSATISLAR_3_DIGERGELIRLER',
      value: '0,00 31.167,34',
    },
    {
      key: 'B_SATISINDIRIMLERI_HEADER',
      value: '',
    },
    {
      key: 'B_SATISINDIRIMLERI_1_SATISTANIADELER',
      value: '',
    },
    {
      key: 'B_SATISINDIRIMLERI_2_SATISISKONTOLARI',
      value: '',
    },
    {
      key: 'B_SATISINDIRIMLERI_3_DIGERINDIRIMLER',
      value: '',
    },
    {
      key: 'C_NETSATISLAR_HEADER',
      value: '0,00 14.006.321,03',
    },
    {
      key: 'D_SATISLARINMALIYETI_HEADER',
      value: '0,00 9.528.203,67',
    },
    {
      key: 'D_SATISLARINMALIYETI_1_SATILANMAMULLERMALIYETI',
      value: '0,00 9.528.203,67',
    },
    {
      key: 'D_SATISLARINMALIYETI_2_SATILANTICARIMALLARMALIYETI',
      value: '',
    },
    {
      key: 'D_SATISLARINMALIYETI_3_SATILANHIZMETMALIYETI',
      value: '',
    },
    {
      key: 'D_SATISLARINMALIYETI_4_DIGERSATISLARINMALIYETI',
      value: '',
    },
    {
      key: 'BRUTSATISKARIVEYAZARARI_HEADER',
      value: '0,00 4.478.117,36',
    },
    {
      key: 'E_FAALIYETGIDERLERI_HEADER',
      value: '0,00 682.515,74',
    },
    {
      key: 'E_FAALIYETGIDERLERI_1_ARASTIRMAVEGELISTIRMEGIDERLERI',
      value: '',
    },
    {
      key: 'E_FAALIYETGIDERLERI_2_PAZARLAMA_SATISVEDAGITIMGIDERLERI',
      value: '0,00 454.509,50',
    },
    {
      key: 'E_FAALIYETGIDERLERI_3_GENELYONETIMGIDERLERI',
      value: '0,00 228.006,24',
    },
    {
      key: 'FAALIYETKARIVEYAZARARI_HEADER',
      value: '0,00 3.795.601,62',
    },
    {
      key: 'F_DIGERFAALIYETLERDENOLAGANGELIRVEKARLAR_HEADER',
      value: '0,00 524.159,05',
    },
    {
      key: 'F_DIGERFAALIYETLERDENOLAGANGELIRVEKARLAR_1_ISTIRAKLERDENTEMETTUGELIRLERI',
      value: '',
    },
    {
      key: 'F_DIGERFAALIYETLERDENOLAGANGELIRVEKARLAR_2_BAGLIORTAKLIKLARDANTEMETTUGELIRLERI',
      value: '',
    },
    {
      key: 'F_DIGERFAALIYETLERDENOLAGANGELIRVEKARLAR_3_FAIZGELIRLERI',
      value: '',
    },
    {
      key: 'F_DIGERFAALIYETLERDENOLAGANGELIRVEKARLAR_4_KOMISYONGELIRLERI',
      value: '',
    },
    {
      key: 'F_DIGERFAALIYETLERDENOLAGANGELIRVEKARLAR_5_KONUSUKALMAYANKARSILIKLAR',
      value: '',
    },
    {
      key: 'F_DIGERFAALIYETLERDENOLAGANGELIRVEKARLAR_6_MENKULKIYMETSATISKARLARI',
      value: '',
    },
    {
      key: 'F_DIGERFAALIYETLERDENOLAGANGELIRVEKARLAR_7_KAMBIYOKARLARI',
      value: '0,00 524.159,05',
    },
    {
      key: 'F_DIGERFAALIYETLERDENOLAGANGELIRVEKARLAR_8_REESKONTFAIZGIDERLERI',
      value: '',
    },
    {
      key: 'F_DIGERFAALIYETLERDENOLAGANGELIRVEKARLAR_9_ENFLASYONDUZELTMESIKARLARI',
      value: '',
    },
    {
      key: 'F_DIGERFAALIYETLERDENOLAGANGELIRVEKARLAR_10_DIGEROLAGANGIDERVEZARARLAR',
      value: '',
    },
    {
      key: 'G_DIGERFAALIYETLERDENOLAGANGIDERVEZARARLAR_HEADER',
      value: '0,00 512.664,78',
    },
    {
      key: 'G_DIGERFAALIYETLERDENOLAGANGIDERVEZARARLAR_1_KOMISYONGIDERLERI',
      value: '',
    },
    {
      key: 'G_DIGERFAALIYETLERDENOLAGANGIDERVEZARARLAR_2_KARSILIKGIDERLERI',
      value: '',
    },
    {
      key: 'G_DIGERFAALIYETLERDENOLAGANGIDERVEZARARLAR_3_MENKULKIYMETSATISZARARLARI',
      value: '',
    },
    {
      key: 'G_DIGERFAALIYETLERDENOLAGANGIDERVEZARARLAR_4_KAMBIYOZARARLARI',
      value: '0,00 512.664,78',
    },
    {
      key: 'G_DIGERFAALIYETLERDENOLAGANGIDERVEZARARLAR_5_REESKONTFAIZGIDERLERI',
      value: '',
    },
    {
      key: 'G_DIGERFAALIYETLERDENOLAGANGIDERVEZARARLAR_6_DIGEROLAGANGIDERVEZARARLAR',
      value: '',
    },
    {
      key: 'H_FINANSMANGIDERLERI_HEADER',
      value: '0,00 2.902,99',
    },
    {
      key: 'H_FINANSMANGIDERLERI_1_KISAVADELIBORCLANMAGIDERLERI',
      value: '0,00 2.902,99',
    },
    {
      key: 'H_FINANSMANGIDERLERI_2_UZUNVADELIBORCLANMAGIDERLERI',
      value: '',
    },
    {
      key: 'OLAGANKARVEYAZARAR_HEADER',
      value: '0,00 3.804.192,90',
    },
    {
      key: 'I_OLAGANDISIGELIRVEKARLAR_HEADER',
      value: '',
    },
    {
      key: 'I_OLAGANDISIGELIRVEKARLAR_1_ONCEKIDONEMGELIRVEKARLARI',
      value: '',
    },
    {
      key: 'I_OLAGANDISIGELIRVEKARLAR_2_DIGEROLAGANDISIGELIRVEKARLAR',
      value: '',
    },
    {
      key: 'J_OLAGANDISIGIDERVEZARARLAR_HEADER',
      value: '0,00 1,95',
    },
    {
      key: 'J_OLAGANDISIGIDERVEZARARLAR_1_CALISMAYANKISIMGIDERVEZARARLARI',
      value: '',
    },
    {
      key: 'J_OLAGANDISIGIDERVEZARARLAR_2_ONCEKIDONEMGIDERVEZARARLARI',
      value: '',
    },
    {
      key: 'J_OLAGANDISIGIDERVEZARARLAR_3_DIGEROALAGANDISIGIDERVEZARARLAR',
      value: '0,00 1,95',
    },
    {
      key: 'DONEMKARIVEYAZARARI_HEADER',
      value: '0,00 3.804.190,95',
    },
    {
      key: 'K_DONEMKARIVERGIVEDIGERYASALYUKUMLULUK_HEADER',
      value: '',
    },
    {
      key: 'DONEM_NET_KARI_VEYA_ZARARI_HEADER',
      value: '0,00 3.804.190,95',
    },
    {
      key: 'DONEMTIPI',
      value: 'Yillik',
    },
    {
      key: 'YILI',
      value: '2020',
    },
    {
      key: 'SOYADI_UNVAN',
      value: 'DOGAN',
    },
    {
      key: 'ADI_UNVANINDEVAMI',
      value: 'MUSTAFA NEBI',
    },
    {
      key: 'TC_KIMLIK_NO',
      value: '17513180488',
    },
    {
      key: 'TICARET_SICIL_NUMARASI',
      value: '52811',
    },
    {
      key: 'TICARI_KAZANCLAR',
      value: '0,00 4.176.371,82',
    },
    {
      key: 'ZIRAI_KAZANCLAR',
      value: '0,00 0,00',
    },
    {
      key: 'SERBEST_MESLEK_KAZANCLARI',
      value: '0,00 0,00',
    },
    {
      key: 'UCRETLER',
      value: '0,00',
    },
    {
      key: 'GAYRIMENKUL_SERMAYE_IRATLARI',
      value: '0,00 6.375,00',
    },
    {
      key: 'DIGER_KAZANC_VE_IRATLAR',
      value: '0,00',
    },
    {
      key: 'TOPLAM',
      value: '0,00 4.182.746,82',
    },
    {
      key: 'MAHSUP_EDILECEK_GECMIS_YIL_ZARARLARI_TOPLAMI',
      value: '0,00',
    },
    {
      key: 'MAHSUP_SONRASI_KALAN_TUTAR',
      value: '416.597,78',
    },
    {
      key: 'VERGIYE_TABI_GELIR_(MATRAH)',
      value: '416.597,78',
    },
    {
      key: 'HESAPLANAN_GELIR_VERGISI',
      value: '0,00',
    },
    {
      key: 'HESAPLANAN_VERGI_INDIRIMI(GVKMUKERRER121)',
      value: '0,00',
    },
    {
      key: 'MAHSUP_EDILECEK_VERGILER_TOPLAMI',
      value: '59.859,72',
    },
    {
      key: 'ODENMESI_GEREKEN_GELIR_VERGISI',
      value: '71.819,50',
    },
    {
      key: 'VERGI_INDIRIMININ_MAHSUBUNDAN_SONRA_KALAN_TUTAR',
      value: '0,00',
    },
    {
      key: 'IADESI_GEREKEN_GELIR_VERGISI_VE/VEYA_GECICI_VERGI',
      value: '0,00',
    },
    {
      key: 'KALAN_VERGI_INDIRIMI_TUTARI',
      value: '0,00',
    },
    {
      key: 'ODENMESI_GEREKEN_DAMGA_VERGISI',
      value: '318,60',
    },
    {
      key: 'GELIR/GAYRISAFI_HASILAT',
      value: '',
    },
    {
      key: 'GIDER/INDIRILECEK_GIDER',
      value: '',
    },
    {
      key: 'FINANSMAN_GIDERLERI',
      value: '',
    },
    {
      key: 'AMORTISMAN_GIDERLERI',
      value: '',
    },
    {
      key: 'TICARI_KAR',
      value: '3.766.149,04',
    },
    {
      key: 'TICARI_ZARAR',
      value: '',
    },
    {
      key: 'SATISTAN_IADELER',
      value: '',
    },
    {
      key: 'SATIS_ISKONTOLARI',
      value: '',
    },
    {
      key: 'SATILAN_MAMULLERIN_MALIYETI',
      value: '',
    },
    {
      key: 'SATILAN_TICARI_MALLAR_MALIYETI',
      value: '',
    },
    {
      key: 'SATILAN_HIZMET_MALIYETI',
      value: '',
    },
    {
      key: 'PAZARLAMA_SATIS_VE_DAGITIM_GIDERLERI',
      value: '',
    },
    {
      key: 'GENEL_YONETIM_GIDERLERI',
      value: '484,30',
    },
    {
      key: 'KAMBIYO_ZARARLARI',
      value: '',
    },
    {
      key: 'DIGER_GIDER_VE_ZARARLAR',
      value: '',
    },
    {
      key: 'KISA_VADELI_BORCLANMA_GIDERLERI',
      value: '',
    },
    {
      key: 'UZUN_VADELI_BORCLANMA_GIDERLERI',
      value: '',
    },
    {
      key: 'DIGER_OLAGANDISI_GIDER_VE_ZARARLAR',
      value: '',
    },
    {
      key: 'TOPLAM1',
      value: '',
    },
    {
      key: 'YURTICI_SATISLAR',
      value: '',
    },
    {
      key: 'YURTDISI_SATISLAR',
      value: '',
    },
    {
      key: 'KAMBIYO_KARLARI',
      value: '',
    },
    {
      key: 'REESKONT_FAIZ_GELIRLERI',
      value: '',
    },
    {
      key: 'DIGER_OLAGANDISI_GELIR_VE_KARLAR',
      value: '',
    },
    {
      key: 'TOPLAM2',
      value: '',
    },
    {
      key: 'MALI_KAR_VEYA_ZARAR',
      value: '3.766.633,34',
    },
    {
      key: 'KESINTI_YOLUYLA_ODENEN_VERGILER',
      value: '0,00',
    },
  ],
};
