/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const CollateralList = styled.div`
  .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar {
    height: 4px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .ant-tabs-tab {
    font-size: 16px;
  }
  .collateral-item {
    align-items: center;
    display: flex;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    color: #000000d9;
    border: 1px solid #d9d9d9;
    background: #fff;
    justify-content: space-between;
    padding: 20px 20px 10px;
    border-radius: 7px;
    margin-bottom: 10px;
  }
  .main-title {
    color: ${({theme}) => theme.colors.primary};
    font-size: 48px;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    text-align: center;
    margin-bottom: 0px;
  }
  .circle-plus {
    width: 20px;
    height: 20px;
    margin-top: -10px;
  }
  .desc-btns {
    display: flex;
    height: 40px;
    margin-bottom: 1em;
  }
  .desc-btns button {
    height: 40px;
    padding: 10px 15px;
  }
  .upload-list-btn {
    background: ${({theme}) => theme.colors.primaryFaded};
    color: ${({theme}) => theme.colors.primary};
    border-color: none;
    border: 0px;
    outline: none;
    font-size: 14px;
    font-weight: bold;
    height: 30px;
    width: 90px;
    border-radius: 5px;
    padding: 5px;
    box-shadow: none !important;
    text-shadow: none !important;
    margin-top: -10px;
  }
  .upload-list-btn:focus,
  .upload-list-btn:hover {
    border-color: none;
    border: 0px;
    outline: none;
  }
`;
