import styled from 'styled-components';
import {Progress as ProgressAntd} from 'antd';

export const ProgressStepContainer = styled.div`
  border: 1px solid ${({theme}) => theme.colors.lightSmoke};
  border-radius: ${({theme}) => theme.main.borderRadius}px;
  padding: ${({theme}) => theme.main.padding}px;
`;

export const Progress = styled(ProgressAntd)`
  .ant-progress-steps-item {
    width: 38px !important;
    border-radius: 10px;
    margin-right: 3px;
  }
`;
