/* eslint-disable import/prefer-default-export */
import {put} from 'redux-saga/effects';
import searchApi from '../../Api/Search';
import {
  IBank,
  ICity,
  ICurrencyType,
  IGrowthType,
  IInsuranceCompany,
  ILimitRateType,
  IPaymentType,
} from '../../Api/Search/types';
import {
  fetchBanksFailure,
  fetchBanksSuccess,
  fetchCitiesFailure,
  fetchCitiesSuccess,
  fetchCurrencyTypesFailure,
  fetchCurrencyTypesSuccess,
  fetchGrowthTypesFailure,
  fetchGrowthTypesSuccess,
  fetchInsuranceCompaniesFailure,
  fetchInsuranceCompaniesSuccess,
  fetchLimitRateTypesFailure,
  fetchLimitRateTypesSuccess,
  fetchPaymentTypesFailure,
  fetchPaymentTypesSuccess,
  fetchTermOfSaleTypesFailure,
  fetchTermOfSaleTypesSuccess,
} from './globalsActions';

export function* fetchCitiesSaga() {
  try {
    const cities: ICity[] = yield searchApi.fetchCities();
    yield put(fetchCitiesSuccess(cities));
  } catch {
    yield put(fetchCitiesFailure());
  }
}

export function* fetchInsuranceCompaniesSaga() {
  try {
    const insuranceCompanies: IInsuranceCompany[] = yield searchApi.fetchInsuranceCompanies();
    yield put(fetchInsuranceCompaniesSuccess(insuranceCompanies));
  } catch {
    yield put(fetchInsuranceCompaniesFailure());
  }
}

export function* fetchBanksSaga() {
  try {
    const banks: IBank[] = yield searchApi.fetchBanks();
    yield put(fetchBanksSuccess(banks));
  } catch {
    yield put(fetchBanksFailure());
  }
}

export function* fetchGrowthTypesSaga() {
  try {
    const growthTypes: IGrowthType[] = yield searchApi.fetchGrowthTypes();
    yield put(fetchGrowthTypesSuccess(growthTypes));
  } catch {
    yield put(fetchGrowthTypesFailure());
  }
}

export function* fetchCurrencyTypesSaga() {
  try {
    const currencyTypes: ICurrencyType[] = yield searchApi.fetchCurrencyTypes();
    yield put(fetchCurrencyTypesSuccess(currencyTypes));
  } catch {
    yield put(fetchCurrencyTypesFailure());
  }
}

export function* fetchPaymentTypesSaga() {
  try {
    const paymentTypes: IPaymentType[] = yield searchApi.fetchPaymentTypes();
    yield put(fetchPaymentTypesSuccess(paymentTypes));
  } catch {
    yield put(fetchPaymentTypesFailure());
  }
}

export function* fetchTermOfSaleTypesSaga() {
  try {
    const termOfSaleTypes: IPaymentType[] = yield searchApi.fetchTermOfSaleTypes();
    yield put(fetchTermOfSaleTypesSuccess(termOfSaleTypes));
  } catch {
    yield put(fetchTermOfSaleTypesFailure());
  }
}

export function* fetchLimitRateTypesSaga() {
  try {
    const limitRates: ILimitRateType[] = yield searchApi.fetchLimitRateTypes();
    yield put(fetchLimitRateTypesSuccess(limitRates));
  } catch {
    yield put(fetchLimitRateTypesFailure());
  }
}
