import React, {FC} from 'react';
import {Form, Input, Row, Col} from 'antd';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import {ISalesFieldCreateComponent} from '../types';
import {CREATE_SALES_FIELD_INFO} from '../../../../constants';
import ContentContainer from '../../../../components/ContentContainer';
import {useAppSelector} from '../../../../hooks/useRedux';

const CompanyInfo: FC<ISalesFieldCreateComponent> = ({isLoading, onSubmit, onCancel}) => {
  const [form] = Form.useForm();
  const customerSale = useAppSelector((state: any) => state.customer.customerSale);
  const customer = useAppSelector((state: any) => state.customer.customerCreateQueryData);

  const handleSubmit = (values: any) => {
    onSubmit(values);
  };

  return (
    <div>
      <Text type="h2" bold>
        {CREATE_SALES_FIELD_INFO.COMPANY_INFO.title}
      </Text>
      <ContentContainer maxWidth={400}>
        <Form
          form={form}
          style={{marginTop: '2em'}}
          layout="vertical"
          requiredMark={false}
          initialValues={{
            ...customerSale,
          }}
          onFinish={handleSubmit}
        >
          <Text className="m-0" type="h5">
            Ünvanı:
          </Text>
          <Input
            style={{marginTop: '1em', marginBottom: '2em'}}
            value={customer?.title || customerSale?.accountName}
            readOnly
          />
          <Text className="m-0" type="h5">
            Vergi Kimlik No:
          </Text>
          <Input
            style={{marginTop: '1em', marginBottom: '2em'}}
            value={customer?.taxId || customerSale?.taxNumber}
            readOnly
          />
          <Text className="m-0" type="h5">
            Vergi Dairesi:
          </Text>
          <Input
            style={{marginTop: '1em', marginBottom: '2em'}}
            value={customer?.taxAdministration || customerSale?.taxAdministration}
            readOnly
          />
          <Text className="m-0" type="h5">
            Adres:
          </Text>
          <Input
            style={{marginTop: '1em', marginBottom: '2em'}}
            value={customer?.address || customerSale?.address}
            readOnly
          />
          <Form.Item name="companyPhone" label="Sabit Telefon">
            <Input addonBefore="+90" style={{width: '100%'}} />
          </Form.Item>

          <Form.Item name="requestedProduct" label="Talep Edilen Ürün">
            <Input type="text" />
          </Form.Item>

          <Text type="h3" bold style={{marginTop: '1em', marginBottom: '1em'}}>
            İletişimde Olduğumuz Yetkili Bilgileri
          </Text>

          <Form.Item name="contactFullname" label="Adı">
            <Input type="text" />
          </Form.Item>

          <Form.Item name="contactTitle" label="Ünvanı">
            <Input type="text" />
          </Form.Item>

          <Form.Item name="contactPhoneNumber" label="Telefon">
            <Input addonBefore="+90" style={{width: '100%'}} />
          </Form.Item>

          <Form.Item
            name="contactEmailAddress"
            label="E-Posta Adresi"
            rules={[
              {type: 'email', message: 'Lütfen geçerli bir e-posta adresi giriniz!'},
              {
                message: 'Lütfen e-posta adresi giriniz!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Row gutter={20}>
            <Col span={13}>
              <Form.Item className="m-0">
                <Button
                  style={{paddingLeft: `${isLoading ? '10px' : '25px'}`}}
                  type="primary"
                  loading={isLoading}
                  htmlType="submit"
                  size="large"
                  block
                >
                  Kaydet ve Devam Et
                </Button>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item className="m-0">
                <Button type="default" disabled={isLoading} size="large" onClick={onCancel} block>
                  Kaydet ve Çık
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ContentContainer>
    </div>
  );
};

export default CompanyInfo;
