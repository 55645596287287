import React, {useState, VFC} from 'react';
import {Col, notification, Row} from 'antd';
import readXlsxFile from 'read-excel-file';
import Button from '../../../components/Button';
import ContentContainer from '../../../components/ContentContainer';
import Text from '../../../components/Text';
import Upload from '../../../components/Upload';
import customerApi from '../../../Api/Customer';
import Table from '../../../components/Table';

const CustomerCreateBulk: VFC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [tableData, setTableData] = useState<any>([]);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);

  const downloadSample = () => {
    customerApi.downloadCustomerExcel();
  };

  const removeFile = () => {
    setFile(null);
    setTableData([]);
  };

  const uploadFile = async () => {
    if (file) {
      setIsLoadingUpload(true);
      try {
        const formData = new FormData();
        formData.append('file', file);
        const response: any = await customerApi.uploadCustomerExcel(formData);
        if (response && response.message) {
          notification.success({
            message: 'İşlem Başarılı',
            description: response.message,
          });
        }
        removeFile();
      } finally {
        setIsLoadingUpload(false);
      }
    }
  };

  const onUploadFile = async (f: File) => {
    try {
      setFile(f);
      const rows = await readXlsxFile(f);
      rows.shift(); // headers
      setTableData(rows);
    } catch {
      removeFile();
    }
  };

  return (
    <ContentContainer>
      <Text className="mb-big" type="h2">
        Toplu Müşteri Listesi Yükle
      </Text>

      <Row className="mb-big" justify="space-between" align="middle">
        <Col>
          <Row align="middle">
            <Upload
              label="Excel Yükle"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              fileList={file ? [file] : []}
              onChange={(val: any) => onUploadFile(val.file)}
              onRemove={removeFile}
            />

            <Button className="ml" onClick={downloadSample}>
              Örnek Dosya İndir
            </Button>
          </Row>
        </Col>

        {!!file && (
          <Button
            className="ml-big"
            type="primary"
            onClick={uploadFile}
            disabled={!file}
            loading={isLoadingUpload}
          >
            Gönder
          </Button>
        )}
      </Row>

      {!!tableData?.length && (
        <Table
          dataSource={tableData}
          columns={[
            'Müşteri Kodu',
            'Firma Adı',
            'VKN/TCKN',
            'Vergi Dairesi',
            'Şehir',
            'Tür',
            'İletişim Kurulacak Kişi',
            'İletişim Numarası',
          ].map((label, idx) => ({dataIndex: idx, title: label}))}
        />
      )}
    </ContentContainer>
  );
};

export default CustomerCreateBulk;
