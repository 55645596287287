import React, {useEffect, useState} from 'react';
import {Col, Table} from 'antd';
import moment from 'moment';
import Text from '../../../../components/Text';
import {convertNumberDotSeperated} from '../../../../utils/string';
import {useAppSelector} from '../../../../hooks/useRedux';
import customerApi from '../../../../Api/Customer';
import {convertFileToAntdFile, FilePromise} from '../../../../utils/file';
import {distortValue} from '../../../../utils';
import {CustomIcon} from '../../../../components/Icon';

const WarrantInfo = () => {
  const customer = useAppSelector((state) => state.customer.customerCreateQueryData);
  const [warrantData, setWarrantData] = useState<any>();

  const fetchInitialData = async () => {
    const initialData = await customerApi.fetchCustomerWarrantInfo(customer.accountId);
    const documents = await Promise.all(
      initialData.guaranteeLetters.map(
        (garanteeLetter) => garanteeLetter && FilePromise(garanteeLetter),
      ),
    );
    const guaranteeLetterDocuments = documents.map((file) => convertFileToAntdFile(file as File));
    const guaranteeLetterInfos = distortValue(
      initialData.guaranteeLetterInfos,
      'guaranteeFund',
      convertNumberDotSeperated,
    );

    const mappedLetterInfos = guaranteeLetterInfos.map((letter) => {
      try {
        return {...letter, termDate: moment(letter.termDate).format('DD/MM/YYYY')};
      } catch {
        return {...letter, termDate: undefined};
      }
    });
    setWarrantData({
      guaranteeLetterInfos: mappedLetterInfos,
      dbsList: distortValue(initialData.dbsList, 'value', convertNumberDotSeperated) || [],
      liens: distortValue(initialData.liens, 'lienAmount', convertNumberDotSeperated) || [],
      otherInfos: distortValue(initialData.otherInfos, 'value', convertNumberDotSeperated) || [],
      letter1: guaranteeLetterDocuments[0] ? [guaranteeLetterDocuments[0]] : [],
      letter2: guaranteeLetterDocuments[1] ? [guaranteeLetterDocuments[1]] : [],
      letter3: guaranteeLetterDocuments[2] ? [guaranteeLetterDocuments[2]] : [],
    });
  };

  useEffect(() => {
    fetchInitialData();
  }, [customer]);

  return (
    <div className="w-100" id="customer-tax-data">
      <div className="section-head mb-20">
        <CustomIcon icon="check-circle" className="mr" />
        <Text type="h2" className="section-title">
          Teminat Bilgileri
        </Text>
      </div>
      <div className="pl-20">
        <div className="w-100 d-flex justify-between">
          <Col span={12}>
            <Text bold block className="primary-text mb-10">
              Teminat Mektubu:
            </Text>
            <Table
              rowClassName="cursor-pointer"
              columns={[
                {
                  title: 'Tutar',
                  dataIndex: 'guaranteeFund',
                },
                {
                  title: 'Banka',
                  dataIndex: 'bankName',
                },
                {
                  title: 'Vade',
                  dataIndex: 'termDate',
                },
              ]}
              bordered
              dataSource={warrantData?.guaranteeLetterInfos}
              pagination={false}
              rowKey="guaranteeId"
            />
          </Col>
          <Col span={12}>
            <Text bold block className="primary-text mb-10">
              DBS:
            </Text>
            <Table
              rowClassName="cursor-pointer"
              columns={[
                {
                  title: 'Tutar',
                  dataIndex: 'value',
                },
                {
                  title: 'Banka',
                  dataIndex: 'bankName',
                },
              ]}
              bordered
              dataSource={warrantData?.dbsList}
              pagination={false}
              rowKey="dbsId"
            />
          </Col>
        </div>
        <br />
        <div>
          <Col span={24}>
            <Text bold block className="primary-text mb-10">
              İpotek:
            </Text>
            <Table
              rowClassName="cursor-pointer"
              columns={[
                {
                  title: 'Tutar',
                  dataIndex: 'lienAmount',
                },
                {
                  title: 'Açıklama',
                  dataIndex: 'description',
                },
              ]}
              bordered
              dataSource={warrantData?.liens}
              pagination={false}
              rowKey="lienId"
            />
          </Col>
        </div>
        <br />
        <div>
          <Col span={24}>
            <Text bold block className="primary-text mb-10">
              Diğer:
            </Text>
            <Table
              rowClassName="cursor-pointer"
              columns={[
                {
                  title: 'Tutar',
                  dataIndex: 'value',
                },
                {
                  title: 'Açıklama',
                  dataIndex: 'description',
                },
              ]}
              bordered
              dataSource={warrantData?.otherInfos}
              pagination={false}
              rowKey="id"
            />
          </Col>
        </div>
      </div>
    </div>
  );
};

export default WarrantInfo;
