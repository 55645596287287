import {
  IBank,
  ICity,
  ICurrencyType,
  IGrowthType,
  IInsuranceCompany,
  ILimitRateType,
  IPaymentType,
  ITermOfSaleType,
} from '../../Api/Search/types';
import {globalsActionTypes} from './globalsTypes';

interface IGlobalReducer {
  cities: ICity[];
  isLoadingCities: boolean;

  insuranceCompanies: IInsuranceCompany[];
  isLoadingInsuranceCompanies: boolean;

  banks: IBank[];
  isLoadingBanks: boolean;

  growthTypes: IGrowthType[];
  isLoadingGrowthTypes: boolean;

  currencyTypes: ICurrencyType[];
  isLoadingCurrencyTypes: boolean;

  paymentTypes: IPaymentType[];
  isLoadingPaymentTypes: boolean;

  termOfSaleTypes: ITermOfSaleType[];
  isLoadingTermOfSaleTypes: boolean;

  limitRateTypes: ILimitRateType[];
  isLoadingLimitRateTypes: boolean;
}

const initialState: IGlobalReducer = {
  cities: [],
  isLoadingCities: true,

  insuranceCompanies: [],
  isLoadingInsuranceCompanies: true,

  banks: [],
  isLoadingBanks: true,

  growthTypes: [],
  isLoadingGrowthTypes: true,

  currencyTypes: [],
  isLoadingCurrencyTypes: true,

  paymentTypes: [],
  isLoadingPaymentTypes: true,

  termOfSaleTypes: [],
  isLoadingTermOfSaleTypes: true,

  limitRateTypes: [],
  isLoadingLimitRateTypes: true,
};

export default (
  state = initialState,
  action: {type: string; payload: any} = {type: '', payload: {}},
): typeof initialState => {
  switch (action.type) {
    case globalsActionTypes.FETCH_CITIES:
      return {...state, isLoadingCities: true};
    case globalsActionTypes.FETCH_CITIES_FAILURE:
      return {...state, isLoadingCities: false};
    case globalsActionTypes.FETCH_CITIES_SUCCESS:
      return {...state, isLoadingCities: false, cities: action.payload};

    case globalsActionTypes.FETCH_INSURANCE_COMPANIES:
      return {...state, isLoadingInsuranceCompanies: true};
    case globalsActionTypes.FETCH_INSURANCE_COMPANIES_FAILURE:
      return {...state, isLoadingInsuranceCompanies: false};
    case globalsActionTypes.FETCH_INSURANCE_COMPANIES_SUCCESS:
      return {...state, isLoadingInsuranceCompanies: false, insuranceCompanies: action.payload};

    case globalsActionTypes.FETCH_BANKS:
      return {...state, isLoadingBanks: true};
    case globalsActionTypes.FETCH_BANKS_FAILURE:
      return {...state, isLoadingBanks: false};
    case globalsActionTypes.FETCH_BANKS_SUCCESS:
      return {...state, isLoadingBanks: false, banks: action.payload};

    case globalsActionTypes.FETCH_GROWTH_TYPES:
      return {...state, isLoadingGrowthTypes: true};
    case globalsActionTypes.FETCH_GROWTH_TYPES_FAILURE:
      return {...state, isLoadingGrowthTypes: false};
    case globalsActionTypes.FETCH_GROWTH_TYPES_SUCCESS:
      return {...state, isLoadingGrowthTypes: false, growthTypes: action.payload};

    case globalsActionTypes.FETCH_CURRENCY_TYPES:
      return {...state, isLoadingCurrencyTypes: true};
    case globalsActionTypes.FETCH_CURRENCY_TYPES_FAILURE:
      return {...state, isLoadingCurrencyTypes: false};
    case globalsActionTypes.FETCH_CURRENCY_TYPES_SUCCESS:
      return {...state, isLoadingCurrencyTypes: false, currencyTypes: action.payload};

    case globalsActionTypes.FETCH_PAYMENT_TYPES:
      return {...state, isLoadingPaymentTypes: true};
    case globalsActionTypes.FETCH_PAYMENT_TYPES_FAILURE:
      return {...state, isLoadingPaymentTypes: false};
    case globalsActionTypes.FETCH_PAYMENT_TYPES_SUCCESS:
      return {...state, isLoadingPaymentTypes: false, paymentTypes: action.payload};

    case globalsActionTypes.FETCH_TERM_OF_SALE_TYPES:
      return {...state, isLoadingTermOfSaleTypes: true};
    case globalsActionTypes.FETCH_TERM_OF_SALE_TYPES_FAILURE:
      return {...state, isLoadingTermOfSaleTypes: false};
    case globalsActionTypes.FETCH_TERM_OF_SALE_TYPES_SUCCESS:
      return {...state, isLoadingTermOfSaleTypes: false, termOfSaleTypes: action.payload};

    case globalsActionTypes.FETCH_LIMIT_RATE_TYPES:
      return {...state, isLoadingLimitRateTypes: true};
    case globalsActionTypes.FETCH_LIMIT_RATE_TYPES_FAILURE:
      return {...state, isLoadingLimitRateTypes: false};
    case globalsActionTypes.FETCH_LIMIT_RATE_TYPES_SUCCESS:
      return {...state, isLoadingLimitRateTypes: false, limitRateTypes: action.payload};
    default:
      return state;
  }
};
