import {takeLatest} from 'redux-saga/effects';
import actionTypes from './actionTypes';
import {fetchUserSaga, loginSaga, logoutSaga} from './user/userSagas';
import {
  fetchBanksSaga,
  fetchCitiesSaga,
  fetchCurrencyTypesSaga,
  fetchGrowthTypesSaga,
  fetchInsuranceCompaniesSaga,
  fetchLimitRateTypesSaga,
  fetchPaymentTypesSaga,
  fetchTermOfSaleTypesSaga,
} from './globals/globalsSagas';

export default function* watchSagas(): Generator {
  // USER
  yield takeLatest(actionTypes.LOGIN, loginSaga);
  yield takeLatest(actionTypes.LOGOUT, logoutSaga);
  yield takeLatest(actionTypes.FETCH_USER, fetchUserSaga);
  // yield takeLatest(actionTypes.UPDATE_USER, updateUserSaga);

  // GLOBALS
  yield takeLatest(actionTypes.FETCH_CITIES, fetchCitiesSaga);
  yield takeLatest(actionTypes.FETCH_BANKS, fetchBanksSaga);
  yield takeLatest(actionTypes.FETCH_GROWTH_TYPES, fetchGrowthTypesSaga);
  yield takeLatest(actionTypes.FETCH_CURRENCY_TYPES, fetchCurrencyTypesSaga);
  yield takeLatest(actionTypes.FETCH_PAYMENT_TYPES, fetchPaymentTypesSaga);
  yield takeLatest(actionTypes.FETCH_TERM_OF_SALE_TYPES, fetchTermOfSaleTypesSaga);
  yield takeLatest(actionTypes.FETCH_LIMIT_RATE_TYPES, fetchLimitRateTypesSaga);
  yield takeLatest(actionTypes.FETCH_INSURANCE_COMPANIES, fetchInsuranceCompaniesSaga);
}
