import React, {FC} from 'react';
import {useTheme} from 'styled-components';
import {Col, Row} from 'antd';
import {ProgressStepContainer, Progress} from './styles';
import Text from '../Text';

interface Props {
  title?: string;
  value: number;
  steps: number;
  className?: string;
}

const ProgressStep: FC<Props> = ({title = '', value = 0, steps = 0, className = ''}) => {
  const theme = useTheme();
  const percent = (value * 100) / steps;

  return (
    <Row className={className}>
      <ProgressStepContainer>
        <Row justify="space-between">
          <Col>
            <Text type="h4" bold>
              {title}
            </Text>
          </Col>
          <Col>
            <Text type="h4" color="smoke" weight={600}>
              <span className="primary">{value}</span>/{steps}
            </Text>
          </Col>
        </Row>
        <Progress
          percent={percent}
          steps={steps}
          showInfo={false}
          strokeColor={theme.colors.green}
        />
      </ProgressStepContainer>
    </Row>
  );
};

export default ProgressStep;
