import {IUser} from '../../Api/User/types';
import LocalStorageService from '../../services/LocalStorageService';
import {userActionTypes, DefaultUserAction} from './userTypes';

const initialUser: IUser = {
  id: 0,
  name: '',
  email: '',
  role: '',
  token: '',
};

const initialState = {
  isLoggedIn: !!LocalStorageService.getAuthToken(),
  isLoginLoading: false,
  user: initialUser,
  isUserLoading: false,
};

export default (
  state = initialState,
  action: DefaultUserAction = {type: '', payload: {}},
): typeof initialState => {
  switch (action.type) {
    case userActionTypes.LOGIN:
      return {...state, isLoginLoading: true};
    case userActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoginLoading: false,
        isLoggedIn: true,
        user: action.payload,
      };
    case userActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isLoginLoading: false,
        isLoggedIn: false,
        user: initialUser,
      };
    case userActionTypes.LOGOUT_SUCCESS:
      return {...state, user: initialUser, isLoggedIn: false};

    case userActionTypes.FETCH_USER:
      return {...state, isUserLoading: true};
    case userActionTypes.FETCH_USER_SUCCESS:
      return {...state, isUserLoading: false, user: action.payload};
    case userActionTypes.FETCH_USER_FAILURE:
      return {...state, isUserLoading: false, user: initialUser};

    default:
      return state;
  }
};
