import React, {useState} from 'react';
import {Button, Drawer, Col, Row, Tabs, InputNumber, Input, Form} from 'antd';
import ContentContainer from '../../../components/ContentContainer';
import Text from '../../../components/Text';
import {CollateralList} from './style';
import Icon from '../../../components/Icon';
import {BulkUploadItems} from './data';
import {IPopupData, EditableCellProps} from './types';
import UploadBulkItemPopup from './UploadBulkItemPopup';
import UploadHistory from './UploadHistory';
import CustomerCreateBulk from '../CustomerCreateBulk';

const {TabPane} = Tabs;

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing && <div className="edit-bg" />}
      {editing && title ? (
        <Form.Item
          className="edit-cell-input"
          name={dataIndex}
          style={{margin: 0}}
          rules={[
            {
              required: true,
              message: `${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const UploadBulkList = () => {
  const [showDetail, setShowDetail] = useState(-1);
  const [visible, setVisible] = useState(false);
  const [popupData, setPopupData] = useState<IPopupData>();

  const handleTab = (key: any) => {
    console.log(key);
  };

  const handleShowDetail = (index: any) => {
    setShowDetail(index);
    setVisible(true);
    setPopupData({...BulkUploadItems[index]});
  };

  const handleClose = () => {
    setShowDetail(-1);
    setVisible(false);
    setPopupData(undefined);
  };

  return (
    <ContentContainer horizontalPadding>
      <Text type="h2" style={{fontWeight: '500'}}>
        Toplu Liste Yükle
      </Text>
      <Row style={{flexWrap: 'nowrap'}} gutter={40}>
        <Col flex={1}>
          <CollateralList>
            <Tabs defaultActiveKey="2" onChange={handleTab}>
              <TabPane tab="Toplu Müşteri Yükle" key="1">
                <CustomerCreateBulk />
              </TabPane>
              <TabPane tab="Toplu Teminat Yükle" key="2">
                {BulkUploadItems.map((item, index) => (
                  <div key={item.key} className="collateral-item">
                    <div>
                      <Text
                        type="h4"
                        style={{
                          fontSize: '16px',
                          fontWeight: '500',
                          marginBottom: showDetail === index ? '2px' : '0.5em',
                        }}
                      >
                        {item.text}
                      </Text>
                      {showDetail === index && (
                        <div>
                          <Text type="h5" color="primary" style={{fontSize: '14px'}}>
                            {item.description}
                          </Text>
                          <div className="desc-btns">
                            <Button
                              onClick={() => handleShowDetail(index)}
                              type="default"
                              className="mr"
                            >
                              Üzerine Ekle
                            </Button>
                            <Button onClick={() => handleShowDetail(index)} type="primary">
                              Başka Dosya Yükle
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                    {showDetail === index ? (
                      <div
                        role="button"
                        aria-hidden="true"
                        tabIndex={0}
                        onClick={() => {
                          setShowDetail(-1);
                          console.log('buttn click');
                        }}
                      >
                        <Icon className="mr" size="2x" color="primary" icon="angle-right" />
                      </div>
                    ) : (
                      <Button
                        type="primary"
                        className="upload-list-btn"
                        onClick={() => handleShowDetail(index)}
                      >
                        <Icon className="mr" size="1x" color="primary" icon="plus" /> Yükle
                      </Button>
                    )}
                  </div>
                ))}
              </TabPane>
              <TabPane tab="Toplu Çalışma Geçmişi Yükle" key="3">
                <UploadHistory setVisible={setVisible} setPopupData={setPopupData} />
              </TabPane>
            </Tabs>
            <Drawer
              title={popupData?.text}
              placement="right"
              width="65%"
              maskClosable={false}
              onClose={handleClose}
              visible={visible}
            >
              <UploadBulkItemPopup popupData={popupData} EditableCell={EditableCell} />
            </Drawer>
          </CollateralList>
        </Col>
      </Row>
    </ContentContainer>
  );
};

export default UploadBulkList;
