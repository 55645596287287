import React, {useEffect, useState} from 'react';
import {Table} from 'antd';
import moment from 'moment';
import Text from '../../../../components/Text';
import {convertNumberDotSeperated, convertToDotSeperated} from '../../../../utils/string';
import {useAppSelector} from '../../../../hooks/useRedux';
import customerApi from '../../../../Api/Customer';
import {convertFileToAntdFile, FilePromise} from '../../../../utils/file';
import {getCityByProvinceId} from '../../../../utils';
import {CustomIcon} from '../../../../components/Icon';

const TradeRegistryInfo = () => {
  const customer = useAppSelector((state) => state.customer.customerCreateQueryData);
  const [generalData, setGeneralData] = useState<any>();
  const [city, setCity] = useState('');
  const {cities, isLoadingCities} = useAppSelector((state) => state.globals);

  const fetchInitialData = async () => {
    const initialData = await customerApi.fetchCustomerRecordInfo(customer?.accountId);
    const gazettes = await Promise.all(
      initialData.documents.map(
        (gazetteDocument) => gazetteDocument && FilePromise(gazetteDocument),
      ),
    );
    const gazetteFiles = gazettes.map((file) => convertFileToAntdFile(file as File));
    setGeneralData({
      provinceId: initialData.provinceId,
      tradeRegistryNumber: initialData.tradeRegistryNumber,
      gazette1: gazetteFiles[0] ? [gazetteFiles[0]] : [],
      gazette2: gazetteFiles[1] ? [gazetteFiles[1]] : [],
      gazette3: gazetteFiles[2] ? [gazetteFiles[2]] : [],
      totalEquity: convertNumberDotSeperated(initialData.totalEquity),
      growthStories: initialData.accountHistories.map((_history) => ({
        accountHistoryId: _history.accountHistoryId,
        description: _history.description,
        parameterId: _history.parameterId,
        date: moment(_history.date).format('DD-MM-YYYY'),
      })),
      shareholderStructures: initialData.shareholders.map((shareHolder) => ({
        shareholderId: shareHolder.shareholderId,
        sharePercentage: shareHolder.sharePercentage,
        shareholderName: shareHolder.shareholderName,
        shareCapital: convertNumberDotSeperated(shareHolder.shareCapital),
      })),
    });
  };

  useEffect(() => {
    if (customer?.accountId) {
      fetchInitialData();
    }
  }, [customer]);

  const getCityName = () => {
    if (generalData?.provinceId) {
      setCity(getCityByProvinceId(cities, generalData?.provinceId));
    }
  };

  useEffect(() => {
    if (!isLoadingCities) {
      getCityName();
    }
  }, [isLoadingCities, generalData]);

  return (
    <div className="w-100" id="customer-tax-data">
      <div className="section-head mb-20">
        <CustomIcon icon="paper" className="mr" />
        <Text type="h2" className="section-title">
          Ticaret Sicil Gazetesi Bilgileri
        </Text>
      </div>
      <div className="pl-20">
        <Text bold block className="primary-text">
          Mersis No:
        </Text>
        <Text>{generalData?.tradeRegistryNumber}</Text>
        <br />
        <br />

        <Text bold block className="primary-text">
          Şehir:
        </Text>
        <Text>{city}</Text>
        <br />
        <br />
        <Text bold block className="primary-text">
          Toplam Sermaye:
        </Text>
        <Text>{generalData?.totalEquity}</Text>
        <br />
        <br />
        <div>
          <Text bold block className="primary-text mb-10">
            Ortaklık Yapısı:
          </Text>
          <div className="w-100 d-flex justify-between">
            <Table
              rowClassName="cursor-pointer"
              columns={[
                {
                  title: 'Firma/Kişi',
                  dataIndex: 'shareholderName',
                },
                {
                  title: 'Sermaye',
                  dataIndex: 'shareCapital',
                  render: (text: React.ReactNode) => <>{convertToDotSeperated(`${text}`)} TL</>,
                },
                {
                  title: 'Hisse Oranı',
                  dataIndex: 'sharePercentage',
                  render: (text: React.ReactNode) => <>%{text}</>,
                },
              ]}
              bordered
              dataSource={generalData?.shareholderStructures}
              pagination={false}
              rowKey="shareholderId"
            />
          </div>
        </div>
        <br />
        <br />
        <div>
          <Text bold block className="primary-text mb-10">
            Gelişme Hikayesi:
          </Text>
          <div className="w-100 d-flex justify-between">
            <Table
              rowClassName="cursor-pointer"
              columns={[
                {
                  title: 'Gelişme',
                  dataIndex: 'parameterId',
                },
                {
                  title: 'Tarih',
                  dataIndex: 'date',
                },
                {
                  title: 'Açıklama',
                  dataIndex: 'description',
                },
              ]}
              bordered
              dataSource={generalData?.growthStories}
              pagination={false}
              rowKey="accountHistoryId"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeRegistryInfo;
