import React, {FC, useEffect, useState} from 'react';
import {Col, Divider, Form, Input, notification, Row, Select, Spin} from 'antd';
import {Label} from 'recharts';
import moment from 'moment';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import InputPrice from '../../../../components/InputPrice';
import Upload from '../../../../components/Upload';
import ProgressStep from '../../../../components/ProgressStep';
import {convertFileToAntdFile, FilePromise, getBase64, normFile} from '../../../../utils/file';
import {ICustomerCreateComponent} from '../types';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useRedux';
import customerApi from '../../../../Api/Customer';
import {convertDotSeperatedToFloat, convertNumberDotSeperated} from '../../../../utils/string';
import {ICustomerWarrantOtherInfo, IGuaranteeLetter, ILien} from '../../../../Api/Customer/types';
import {distortValue} from '../../../../utils';
import {IApiImage} from '../../../../Api/types';
import searchApi from '../../../../Api/Search';
import {fetchBanks} from '../../../../store/globals/globalsActions';
import DatePicker from '../../../../components/DatePicker';
import {setInsuranceRatingData} from '../../../../store/customer/customerActions';

const {Option} = Select;

const WarrantInfo: FC<ICustomerCreateComponent> = ({onSubmit, onCancel}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [warrantData, setWarrantData] = useState<any>(null);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const customer = useAppSelector((state) => state.customer.customerCreateQueryData);
  const {banks, isLoadingBanks} = useAppSelector((state) => state.globals);

  const fetchInitialData = async () => {
    const factoringData: any = await customerApi.fetchFactoringData(customer.accountId);
    const akreditifData: any = await customerApi.fetchAkreditifData(customer.accountId);
    const insuranceRatingData: any = await customerApi.fetchInsuranceRatingData(customer.accountId);
    insuranceRatingData && dispatch(setInsuranceRatingData(insuranceRatingData[0]));
    const initialData = await customerApi.fetchCustomerWarrantInfo(customer.accountId);
    setWarrantData(initialData);
    const documents = await Promise.all(
      initialData.guaranteeLetters.map(
        (garanteeLetter) => garanteeLetter && FilePromise(garanteeLetter),
      ),
    );
    const guaranteeLetterDocuments = documents.map((file) => convertFileToAntdFile(file as File));
    const guaranteeLetterInfos = distortValue(
      initialData.guaranteeLetterInfos,
      'guaranteeFund',
      convertNumberDotSeperated,
    );

    const mappedLetterInfos = guaranteeLetterInfos.map((letter) => {
      try {
        return {
          ...letter,
          currency: letter.currency || 'TL',
          termDate: moment(letter.termDate),
        };
      } catch {
        return {
          ...letter,
          termDate: undefined,
        };
      }
    });

    const dbsListMapped = initialData.dbsList.reduce((newVal: any, val: any) => {
      newVal.push({
        ...val,
        currency: val.currency || 'TL',
        definedLimitValue: convertNumberDotSeperated(val.definedLimitValue),
        usedDBSCredit: convertNumberDotSeperated(val.usedDBSCredit),
        availableDBSCredit: convertNumberDotSeperated(val.availableDBSCredit),
        installedInvoicesAmount: convertNumberDotSeperated(val.installedInvoicesAmount),
      });
      return newVal;
    }, []);

    const factoringMap = factoringData.reduce((newVal: any, val: any) => {
      newVal.push({
        ...val,
        currency: val.currency || 'TL',
        factoringLimitAmount: convertNumberDotSeperated(val.factoringLimitAmount),
        factoringRiskAmount: convertNumberDotSeperated(val.factoringRiskAmount),
        factoringGuaranteeAmount: convertNumberDotSeperated(val.factoringGuaranteeAmount),
      });
      return newVal;
    }, []);

    const akreditifMap = akreditifData.reduce((newVal: any, val: any) => {
      newVal.push({
        ...val,
        currency: val.currency || 'TL',
        akreditifLimitTutari: convertNumberDotSeperated(val.akreditifLimitTutari),
        akreditifRiskTutari: convertNumberDotSeperated(val.akreditifRiskTutari),
        akreditifGecerlilikTarihi2: moment(val.akreditifGecerlilikTarihi2),
      });
      return newVal;
    }, []);

    const initialValues = {
      guaranteeLetterInfos: mappedLetterInfos,
      dbsList: dbsListMapped,
      liens: distortValue(initialData.liens, 'amount', convertNumberDotSeperated),
      otherInfos: distortValue(initialData.otherInfos, 'value', convertNumberDotSeperated),
      factoring: factoringMap,
      akreditif: akreditifMap,
      insuranceRating: distortValue(
        insuranceRatingData,
        'alacakTemlikiTutari',
        convertNumberDotSeperated,
      ),
      letter1: guaranteeLetterDocuments[0] ? [guaranteeLetterDocuments[0]] : [],
      letter2: guaranteeLetterDocuments[1] ? [guaranteeLetterDocuments[1]] : [],
      letter3: guaranteeLetterDocuments[2] ? [guaranteeLetterDocuments[2]] : [],
    };

    if (!initialValues.dbsList.length) initialValues.dbsList = [{}];
    if (!initialValues.guaranteeLetterInfos.length)
      initialValues.guaranteeLetterInfos = [{termDate: undefined}];
    if (!initialValues.liens.length) initialValues.liens = [{}];
    if (!initialValues.otherInfos.length) initialValues.otherInfos = [];

    form.setFieldsValue(initialValues);
    setIsLoadingInitialData(false);
  };

  const handleOnSubmit = async (formValues: any) => {
    try {
      setIsLoadingSubmit(true);
      const guaranteeLetterInfos = formValues?.guaranteeLetterInfos[0]?.guaranteeFund
        ? (distortValue(
            formValues.guaranteeLetterInfos,
            'guaranteeFund',
            convertDotSeperatedToFloat,
          ) as IGuaranteeLetter[])
        : [];

      const liens = formValues?.liens[0]?.amount
        ? (distortValue(formValues.liens, 'amount', convertDotSeperatedToFloat) as ILien[])
        : [];

      const otherInfos = formValues?.otherInfos[0]?.value
        ? (distortValue(
            formValues.otherInfos,
            'value',
            convertDotSeperatedToFloat,
          ) as ICustomerWarrantOtherInfo[])
        : [];

      const insuranceRating = formValues?.insuranceRating[0]?.alacakTemlikiTutari
        ? distortValue(
            formValues.insuranceRating,
            'alacakTemlikiTutari',
            convertDotSeperatedToFloat,
          )
        : [];

      const dbsList = formValues?.dbsList?.reduce((newVal: any, val: any) => {
        if (
          val.definedLimitValue ||
          val.usedDBSCredit ||
          val.availableDBSCredit ||
          val.installedInvoicesAmount ||
          val.bankName
        ) {
          newVal.push({
            ...val,
            definedLimitValue: convertDotSeperatedToFloat(val.definedLimitValue),
            usedDBSCredit: convertDotSeperatedToFloat(val.usedDBSCredit),
            availableDBSCredit: convertDotSeperatedToFloat(val.availableDBSCredit),
            installedInvoicesAmount: convertDotSeperatedToFloat(val.installedInvoicesAmount),
          });
        }
        return newVal;
      }, []);

      const factoringMap = formValues?.factoring.reduce((newVal: any, val: any) => {
        newVal.push({
          ...val,
          factoringLimitAmount: convertDotSeperatedToFloat(val.factoringLimitAmount),
          factoringRiskAmount: convertDotSeperatedToFloat(val.factoringRiskAmount),
          factoringGuaranteeAmount: convertDotSeperatedToFloat(val.factoringGuaranteeAmount),
        });
        return newVal;
      }, []);

      const akreditifMap = formValues?.akreditif.reduce((newVal: any, val: any) => {
        newVal.push({
          ...val,
          akreditifLimitTutari: convertNumberDotSeperated(val.akreditifLimitTutari),
          akreditifRiskTutari: convertNumberDotSeperated(val.akreditifRiskTutari),
        });
        return newVal;
      }, []);

      const guaranteeLetterDocuments =
        formValues?.letter1[0] || formValues?.letter2[0] || formValues?.letter1[0]
          ? ((await Promise.all([
              formValues?.letter1[0]?.originFileObj &&
                getBase64(formValues?.letter1[0]?.originFileObj),
              formValues?.letter2[0]?.originFileObj &&
                getBase64(formValues?.letter2[0]?.originFileObj),
              formValues?.letter3[0]?.originFileObj &&
                getBase64(formValues?.letter3[0]?.originFileObj),
            ])) as IApiImage[])
          : [];

      await customerApi.uploadCustomerWarrantInfo({
        accountId: customer.accountId,
        guaranteeLetterDocuments: guaranteeLetterDocuments.filter((doc) => doc !== undefined),
        guaranteeLetterInfos,
        liens,
        dbsList,
        otherInfos,
      });

      await customerApi.updateFactoringData(customer.accountId, factoringMap);
      await customerApi.updateAkreditifData(customer.accountId, akreditifMap);
      await customerApi.updateInsuranceRatingData(customer.accountId, insuranceRating);
      onSubmit();
    } catch (error) {
      console.log(error);
      setIsLoadingSubmit(false);
    }
  };

  useEffect(() => {
    if (customer.accountId && customer.accountId !== 0) {
      fetchInitialData();
      dispatch(fetchBanks());
    }
  }, [customer]);

  const handleDeleteDocument = (file: any, key: any) => {
    searchApi.deleteDocument(file.tciDoumentId).then((res) => {
      console.log(res, 'res');
      notification.success({message: 'İşlem Başarılı', description: 'Dosya silindi yanıtı'});
      form.setFieldsValue({[key]: []});
    });
  };

  const SelectAfter = (
    <Select className="curency-dp" style={{width: 70}} autoClearSearchValue>
      <Option value="TL">TL</Option>
      <Option value="USD">USD</Option>
      <Option value="EUR">EUR</Option>
      <Option value="GBP">GBP</Option>
    </Select>
  );

  return (
    <Spin spinning={isLoadingInitialData}>
      <ProgressStep className="mb-big" title="Teminat Bilgileri" value={3} steps={8} />

      <Text type="h2" bold>
        Teminat Bilgileri
      </Text>

      <Form
        form={form}
        name="warrantInfo"
        layout="vertical"
        requiredMark={false}
        initialValues={{company: customer.title}}
        onFinish={handleOnSubmit}
        scrollToFirstError
      >
        <Row>
          <Col span={10}>
            <Form.Item name="company" label="Firma Adı">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Text type="h3" bold>
          1. Teminat Mektubu
        </Text>

        <Form.List name="guaranteeLetterInfos">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({name, ...restField}, idx) => (
                <Row gutter={20} key={idx}>
                  <Col span={7}>
                    <Form.Item {...restField} label="Tutar" name={[name, 'guaranteeFund']}>
                      <InputPrice
                        displayPrefix={false}
                        addonAfter={
                          <Form.Item
                            initialValue="TL"
                            {...restField}
                            name={[name, 'currency']}
                            noStyle
                          >
                            {SelectAfter}
                          </Form.Item>
                        }
                        placeholder="Örn. 123.234,00"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item {...restField} label="Banka" name={[name, 'bankName']}>
                      <Select
                        options={banks.map(({label}) => ({Label, value: label}))}
                        loading={isLoadingBanks}
                        placeholder="Örn. Akbank"
                        optionFilterProp="label"
                        showSearch
                      />
                    </Form.Item>
                  </Col>

                  <Col span={5}>
                    <Form.Item {...restField} label="Vade" name={[name, 'termDate']}>
                      <DatePicker style={{width: '100%'}} />
                    </Form.Item>
                  </Col>

                  <Col span={7}>
                    <Form.Item
                      {...restField}
                      label="Kesin/Geçici"
                      initialValue="KESİN"
                      name={[name, 'temporaryOrPermanent']}
                    >
                      <Select autoClearSearchValue>
                        <Option value="KESİN">Kesin</Option>
                        <Option value="GEÇİCİ">Geçici</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {idx !== 0 && (
                    <Col span={2}>
                      <Form.Item label=" ">
                        <Button type="dashed" onClick={() => remove(name)} danger>
                          -
                        </Button>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              ))}
              <Row>
                <Col span={20}>
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      + Ekle
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>

        <Row className="mt" gutter={20}>
          <Col>
            <Form.Item name="letter1" valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload
                label="PDF Yükle"
                accept=".doc,.docx,application/pdf"
                documentId={warrantData?.guaranteeLetters[0]?.tciDoumentId}
                onRemove={() => handleDeleteDocument(warrantData?.guaranteeLetters[0], 'letter1')}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="letter2" valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload
                label="PDF Yükle"
                accept=".doc,.docx,application/pdf"
                documentId={warrantData?.guaranteeLetters[1]?.tciDoumentId}
                onRemove={() => handleDeleteDocument(warrantData?.guaranteeLetters[1], 'letter2')}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="letter3" valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload
                label="PDF Yükle"
                accept=".doc,.docx,application/pdf"
                documentId={warrantData?.guaranteeLetters[2]?.tciDoumentId}
                onRemove={() => handleDeleteDocument(warrantData?.guaranteeLetters[2], 'letter3')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Text type="h3" bold>
          2. DBS
        </Text>

        <Form.List name="dbsList">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({name, ...restField}, idx) => (
                <Row gutter={20} key={idx}>
                  <Col span={6}>
                    <Form.Item
                      {...restField}
                      label="Tanımlı DBS Limiti"
                      name={[name, 'definedLimitValue']}
                    >
                      <InputPrice
                        displayPrefix={false}
                        addonAfter={
                          <Form.Item
                            initialValue="TL"
                            {...restField}
                            name={[name, 'currency']}
                            noStyle
                          >
                            {SelectAfter}
                          </Form.Item>
                        }
                        placeholder="Örn. 123.234,00"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      {...restField}
                      label="Kullanılan DBS Kredisi"
                      name={[name, 'usedDBSCredit']}
                    >
                      <InputPrice
                        displayPrefix={false}
                        addonAfter={
                          <Form.Item
                            initialValue="TL"
                            {...restField}
                            name={[name, 'currency']}
                            noStyle
                          >
                            {SelectAfter}
                          </Form.Item>
                        }
                        placeholder="Örn. 123.234,00"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      {...restField}
                      label="Kullanılabilir DBS Limiti"
                      name={[name, 'availableDBSCredit']}
                    >
                      <InputPrice
                        displayPrefix={false}
                        addonAfter={
                          <Form.Item
                            initialValue="TL"
                            {...restField}
                            name={[name, 'currency']}
                            noStyle
                          >
                            {SelectAfter}
                          </Form.Item>
                        }
                        placeholder="Örn. 123.234,00"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      {...restField}
                      label="Yüklü Fatura Tutarı"
                      name={[name, 'installedInvoicesAmount']}
                    >
                      <InputPrice
                        displayPrefix={false}
                        addonAfter={
                          <Form.Item
                            initialValue="TL"
                            {...restField}
                            name={[name, 'currency']}
                            noStyle
                          >
                            {SelectAfter}
                          </Form.Item>
                        }
                        placeholder="Örn. 123.234,00"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item {...restField} label="Banka" name={[name, 'bankName']}>
                      <Select
                        options={banks.map(({label}) => ({Label, value: label}))}
                        loading={isLoadingBanks}
                        placeholder="Örn. Akbank"
                        optionFilterProp="label"
                        showSearch
                      />
                    </Form.Item>
                  </Col>
                  {idx !== 0 && (
                    <Col span={2}>
                      <Form.Item label=" ">
                        <Button type="dashed" onClick={() => remove(name)} danger>
                          -
                        </Button>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              ))}
              <Row>
                <Col span={20}>
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      + Ekle
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>

        <Divider />

        <Text type="h3" bold>
          3. İpotek
        </Text>

        <Form.List name="liens">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({name, ...restField}, idx) => (
                <Row gutter={20} key={idx}>
                  <Col span={7}>
                    <Form.Item {...restField} label="Tutar" name={[name, 'amount']}>
                      <InputPrice
                        displayPrefix={false}
                        addonAfter={
                          <Form.Item
                            initialValue="TL"
                            {...restField}
                            name={[name, 'currency']}
                            noStyle
                          >
                            {SelectAfter}
                          </Form.Item>
                        }
                        placeholder="Örn. 123.234,00"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item {...restField} label="Açıklama" name={[name, 'description']}>
                      <Input />
                    </Form.Item>
                  </Col>
                  {idx !== 0 && (
                    <Col span={2}>
                      <Form.Item label=" ">
                        <Button type="dashed" onClick={() => remove(name)} danger>
                          -
                        </Button>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              ))}
              <Row>
                <Col span={18}>
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      + Ekle
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>

        <Divider />

        <Text type="h3" bold>
          4.1 Akreditif
        </Text>

        <Form.List name="akreditif">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({name, ...restField}, idx) => (
                <Row gutter={20} key={idx}>
                  <Col span={7}>
                    <Form.Item
                      {...restField}
                      label="Akreditif Limit Tutarı"
                      name={[name, 'akreditifLimitTutari']}
                    >
                      <InputPrice
                        displayPrefix={false}
                        addonAfter={
                          <Form.Item
                            initialValue="TL"
                            {...restField}
                            name={[name, 'currency']}
                            noStyle
                          >
                            {SelectAfter}
                          </Form.Item>
                        }
                        placeholder="Örn. 123.234,00"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      {...restField}
                      label="Akreditif Risk Tutarı"
                      name={[name, 'akreditifRiskTutari']}
                    >
                      <InputPrice
                        displayPrefix={false}
                        addonAfter={
                          <Form.Item
                            initialValue="TL"
                            {...restField}
                            name={[name, 'currency']}
                            noStyle
                          >
                            {SelectAfter}
                          </Form.Item>
                        }
                        placeholder="Örn. 123.234,00"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      {...restField}
                      label="Akreditif Geçerlilik Tarihi"
                      name={[name, 'akreditifGecerlilikTarihi2']}
                    >
                      <DatePicker style={{width: '100%'}} />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item {...restField} label="Açıklama" name={[name, 'description']}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item label=" ">
                      <Button type="dashed" onClick={() => remove(name)} danger>
                        -
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col span={23}>
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      + Ekle
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>

        <Divider />

        <Text type="h3" bold>
          4.2 Faktoring
        </Text>

        <Form.List name="factoring">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({name, ...restField}, idx) => (
                <Row gutter={20} key={idx}>
                  <Col span={7}>
                    <Form.Item
                      {...restField}
                      label="Faktoring Garanti Limiti"
                      name={[name, 'factoringGuaranteeAmount']}
                    >
                      <InputPrice
                        displayPrefix={false}
                        addonAfter={
                          <Form.Item
                            initialValue="TL"
                            {...restField}
                            name={[name, 'currency']}
                            noStyle
                          >
                            {SelectAfter}
                          </Form.Item>
                        }
                        placeholder="Örn. 123.234,00"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      {...restField}
                      label="Faktoring Risk Tutarı"
                      name={[name, 'factoringRiskAmount']}
                    >
                      <InputPrice
                        displayPrefix={false}
                        addonAfter={
                          <Form.Item
                            initialValue="TL"
                            {...restField}
                            name={[name, 'currency']}
                            noStyle
                          >
                            {SelectAfter}
                          </Form.Item>
                        }
                        placeholder="Örn. 123.234,00"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item
                      {...restField}
                      label="Faktoring Adı"
                      name={[name, 'factoringCompanyName']}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item {...restField} label="Muhabir Adı" name={[name, 'muhabirAdi']}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item label=" ">
                      <Button type="dashed" onClick={() => remove(name)} danger>
                        -
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col span={23}>
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      + Ekle
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>

        <Divider />

        <Text type="h3" bold>
          4.3 Alacak Temliki
        </Text>

        <Form.List name="insuranceRating">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({name, ...restField}, idx) => (
                <Row gutter={20} key={idx}>
                  <Col span={7}>
                    <Form.Item
                      {...restField}
                      label="Alacak Temliki Tutarı"
                      name={[name, 'alacakTemlikiTutari']}
                    >
                      <InputPrice
                        displayPrefix={false}
                        addonAfter={
                          <Form.Item
                            initialValue="TL"
                            {...restField}
                            name={[name, 'currency']}
                            noStyle
                          >
                            {SelectAfter}
                          </Form.Item>
                        }
                        placeholder="Örn. 123.234,00"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item {...restField} label="Kurum Adı" name={[name, 'bankName']}>
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={9}>
                    <Form.Item
                      {...restField}
                      label="Temlik Detayları"
                      name={[name, 'temlikDetaylari']}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={2}>
                    <Form.Item label=" ">
                      <Button type="dashed" onClick={() => remove(name)} danger>
                        -
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col span={16}>
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      + Ekle
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>

        <Divider />

        <Text type="h3" bold>
          4.4 Diğer Teminat
        </Text>

        <Form.List name="otherInfos">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({name, ...restField}, idx) => (
                <Row gutter={20} key={idx}>
                  <Col span={7}>
                    <Form.Item {...restField} label="Tutar" name={[name, 'value']}>
                      <InputPrice
                        displayPrefix={false}
                        addonAfter={
                          <Form.Item
                            initialValue="TL"
                            {...restField}
                            name={[name, 'currency']}
                            noStyle
                          >
                            {SelectAfter}
                          </Form.Item>
                        }
                        placeholder="Örn. 123.234,00"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item {...restField} label="Teminat Adı" name={[name, 'teminatAdi']}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item {...restField} label="Açıklama" name={[name, 'description']}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item label=" ">
                      <Button type="dashed" onClick={() => remove(name)} danger>
                        -
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col span={16}>
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      + Ekle
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>

        <Row gutter={20}>
          <Col span={9}>
            <Form.Item className="m-0">
              <Button type="primary" htmlType="submit" size="large" loading={isLoadingSubmit} block>
                Kaydet ve Devam Et
              </Button>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item className="m-0">
              <Button
                type="default"
                size="large"
                loading={isLoadingSubmit}
                onClick={onCancel}
                block
              >
                Kaydet ve Çık
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default WarrantInfo;
