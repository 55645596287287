import React, {FC, useEffect} from 'react';
import {Select, Form, Input, Row, Col} from 'antd';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import ContentContainer from '../../../../components/ContentContainer';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useRedux';
import {fetchPaymentTypes, fetchTermOfSaleTypes} from '../../../../store/globals/globalsActions';
import {ISalesFieldCreateComponent} from '../types';
import {CREATE_SALES_FIELD_INFO} from '../../../../constants';

const SaleUnitWork: FC<ISalesFieldCreateComponent> = ({isLoading, onSubmit, onCancel}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const customerSale = useAppSelector((state: any) => state.customer.customerSale);
  const {paymentTypes, isLoadingPaymentTypes, termOfSaleTypes, isLoadingTermOfSaleTypes} =
    useAppSelector((state) => state.globals);

  useEffect(() => {
    dispatch(fetchPaymentTypes());
    dispatch(fetchTermOfSaleTypes());
  }, []);

  return (
    <div>
      <Text type="h2" bold>
        {CREATE_SALES_FIELD_INFO.SALES_UNIT_WORK_CONDITION.title}
      </Text>
      <ContentContainer maxWidth={400}>
        <Form
          form={form}
          style={{marginTop: '2em'}}
          layout="vertical"
          requiredMark={false}
          initialValues={{...customerSale}}
          onFinish={onSubmit}
        >
          <Form.Item name="expectedSalesTon" label="Satış Beklentisi (Yıllık - Ton)">
            <Input type="number" />
          </Form.Item>

          <Form.Item name="expectedSalesTL" label="Satış Beklentisi (Yıllık- TL)">
            <Input type="number" />
          </Form.Item>

          <Form.Item name="requestedLimitAmount" label="Talep Edilen Limit Tutarı">
            <Input type="number" />
          </Form.Item>

          <Form.Item name="requestedMaturityDate" label="Talep Edilen Vade">
            <Select options={termOfSaleTypes} loading={isLoadingTermOfSaleTypes} />
          </Form.Item>

          <Form.Item name="paymentMethodParameterId" label="Talep Edilen Ödeme Şekli">
            <Select options={paymentTypes} loading={isLoadingPaymentTypes} />
          </Form.Item>

          <Row gutter={20}>
            <Col span={13}>
              <Form.Item className="m-0">
                <Button type="primary" loading={isLoading} htmlType="submit" size="large" block>
                  Kaydet ve Devam Et
                </Button>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item className="m-0">
                <Button type="default" size="large" disabled={isLoading} onClick={onCancel} block>
                  Kaydet ve Çık
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ContentContainer>
    </div>
  );
};

export default SaleUnitWork;
