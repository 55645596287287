import {Layout} from 'antd';
import styled from 'styled-components';

const {Content} = Layout;

export default styled(Content)`
  /* 
    display:flex;
    flex-direction: column; 
    */
  position: relative;
  width: 100vw;
  max-width: ${({theme}) => theme.main.limitedContentWidth}px;
  background: ${({theme}) => theme.colors.background};

  margin: auto;
  margin-top: ${({theme}) => theme.header.height + theme.main.margin}px;
  padding: ${({theme}) => theme.main.padding}px ${({theme}) => theme.content.paddingHorizontal};
`;
