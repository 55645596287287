import React from 'react';
import Content from './styles';
import Props from './types';

const ContentContainer: React.FC<Props> = (props) => {
  const {children, verticalPadding = false, horizontalPadding = false, ...restProps} = props;

  return (
    <Content verticalPadding={verticalPadding} horizontalPadding={horizontalPadding} {...restProps}>
      {children}
    </Content>
  );
};

export default ContentContainer;
