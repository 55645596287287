import React, {Dispatch, SetStateAction, useCallback, useState} from 'react';
import {Col, Divider, Popover, Row} from 'antd';
import {Link} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/useRedux';
import Icon from '../Icon';
import AccountDropdownStyled, {AccountButton} from './styles';
import {logout} from '../../store/user/userActions';
import Text from '../Text';
import paths from '../../routes/paths';

const AccountMenu: React.FC<{setIsDropdownVisible: Dispatch<SetStateAction<boolean>>}> = ({
  setIsDropdownVisible,
}) => {
  const dispatch = useAppDispatch();
  const {user} = useAppSelector((state) => state.user);

  const closeDropdown = () => {
    setIsDropdownVisible(false);
  };
  const onClickLogout = () => dispatch(logout());

  const checkRole = (roleName: string) => {
    return user.role.includes(roleName);
  };

  return (
    <div style={{width: checkRole('MANAGER') || checkRole('ADMIN') ? 'auto' : '210px'}}>
      <Link to={paths.user}>
        <AccountButton className="p-0" onClick={closeDropdown}>
          <Text className="m-0" type="h4" bold>
            Hesap Bilgileri
          </Text>
          <Text type="h5" color="smoke" bold>
            Hesap bilgilerini düzenle.
          </Text>
        </AccountButton>
      </Link>

      <Divider className="m" />

      {(checkRole('MANAGER') || checkRole('ADMIN')) && (
        <>
          <Link to={paths.createUser}>
            <AccountButton className="p-0" onClick={closeDropdown}>
              <Text className="m-0" type="h4" bold>
                Kullanıcı Ekle
              </Text>
              <Text type="h5" color="smoke" bold>
                Uygulamaya yeni kullanıcılar tanımla.
              </Text>
            </AccountButton>
          </Link>

          <Divider className="m" />

          <Link to={paths.users}>
            <AccountButton className="p-0" onClick={closeDropdown}>
              <Text className="m-0" type="h4" bold>
                Kullanıcı Listesi
              </Text>
              <Text type="h5" color="smoke" bold>
                Kayıtlı kullanıcıların listesi gör.
              </Text>
            </AccountButton>
          </Link>

          <Divider className="m" />
        </>
      )}

      <Link to={paths.login}>
        <AccountButton className="p-0" onClick={onClickLogout}>
          <Text className="m-0" type="h4" color="red" bold>
            Çıkış
          </Text>
        </AccountButton>
      </Link>
    </div>
  );
};

function AccountDropdown() {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const user = useAppSelector((state) => state.user.user);

  const AccountMenuMemo = useCallback(
    () => <AccountMenu setIsDropdownVisible={setIsDropdownVisible} />,
    [setIsDropdownVisible],
  );

  return (
    <AccountDropdownStyled>
      <Popover
        className="cursor-pointer"
        content={AccountMenuMemo}
        trigger="click"
        placement="bottomRight"
        onVisibleChange={setIsDropdownVisible}
        visible={isDropdownVisible}
      >
        <Row className="h-100" align="middle">
          <Col>
            <Icon className="mr" icon="user" size="lg" color="primary" />
          </Col>
          <Col>
            <Text className="m-0" type="h5" color="primary" weight={300} style={{lineHeight: 2}}>
              Hesabım
            </Text>
            <Text className="m-0" type="h4" color="primary" style={{lineHeight: 1}} bold>
              {user?.name}
            </Text>
          </Col>
          <Col>
            <Icon className="ml" icon="angle-down" size="lg" color="primary" />
          </Col>
        </Row>
      </Popover>
    </AccountDropdownStyled>
  );
}

export default AccountDropdown;
