/* eslint no-nested-ternary: "off" */
import React, {useState, useEffect} from 'react';
import {Button, Col, Row, notification, Space, Form} from 'antd';
import readXlsxFile from 'read-excel-file';
import moment from 'moment';
import _ from 'lodash';
import ContentContainer from '../../../../components/ContentContainer';
import Upload from '../../../../components/Upload';
import Icon from '../../../../components/Icon';
import uploadFilesApi from '../../../../Api/UploadFiles';
import Table from '../../../../components/Table';
import {downloadFileResponse, generateDataObject} from '../data';
import Text from '../../../../components/Text';
import {convertToDotSeperated} from '../../../../utils/string';

const UploadBulkItemPopup = ({popupData, EditableCell}: any) => {
  const [form] = Form.useForm();
  const [file, setFile] = useState<File | null>(null);
  const [backupFile, setBackupFile] = useState<File | null>(null);
  const [tableData, setTableData] = useState<any>([]);
  const [tableUpdateData, setTableUpdateData] = useState<any>([]);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [editingKey, setEditingKey] = useState(-1);
  const [deleteKey, setDeleteKey] = useState(-1);

  const downloadSample = () => {
    switch (popupData.key) {
      case 'guarantee':
        uploadFilesApi.downloadGuaranteeExcel();
        break;
      case 'insurance':
        uploadFilesApi.downloadInsuranceExcel();
        break;
      case 'dbs':
        uploadFilesApi.downloadDbsExcel();
        break;
      case 'lien':
        uploadFilesApi.downloadLienExcel();
        break;
      case 'akreditif':
        uploadFilesApi.downloadAkreditifExcel();
        break;
      case 'factoring':
        uploadFilesApi.downloadAccountFactoringExcel();
        break;
      case 'temliki':
        uploadFilesApi.downloadTemlikiExcel();
        break;
      case 'diğer_teminat':
        uploadFilesApi.downloadOthersExcel();
        break;
      case 'risk':
        uploadFilesApi.downloadAlacakRiskExcel();
        break;
      case 'endorsement':
        uploadFilesApi.downloadEndorsementExcel();
        break;
      default:
    }
  };

  const removeFile = () => {
    setFile(null);
    setTableData([]);
  };

  const updateFile = async () => {
    setIsLoadingUpload(true);
    try {
      let response: any = '';
      switch (popupData.key) {
        case 'guarantee':
          response = await uploadFilesApi.updateGuaranteeExcel(tableUpdateData, 0);
          break;
        case 'insurance':
          response = await uploadFilesApi.updateInsuranceExcel(tableUpdateData, 0);
          break;
        case 'dbs':
          response = await uploadFilesApi.updateDbsExcel(tableUpdateData, 0);
          break;
        case 'lien':
          response = await uploadFilesApi.updateLienExcel(tableUpdateData, 0);
          break;
        case 'akreditif':
          response = await uploadFilesApi.updateAkreditifExcel(tableUpdateData, 0);
          break;
        case 'factoring':
          response = await uploadFilesApi.updateAccountFactoringExcel(tableUpdateData, 0);
          break;
        case 'temliki':
          response = await uploadFilesApi.updateTemlikiExcel(tableUpdateData, 0);
          break;
        case 'diğer_teminat':
          response = await uploadFilesApi.updateOthersExcel(tableUpdateData, 0);
          break;
        case 'risk':
          response = await uploadFilesApi.updateAlacakRiskExcel(tableUpdateData, 0);
          break;
        case 'endorsement':
          response = await uploadFilesApi.updateEndorsementExcel(tableUpdateData, 0);
          break;
        default:
      }
      if (response) {
        setIsLoadingUpload(false);
        setTableUpdateData([]);
        notification.success({
          message: 'İşlem Başarılı',
        });
      }
      removeFile();
    } finally {
      setIsLoadingUpload(false);
    }
  };

  const isCheckError = (cols: any) => {
    return (
      typeof cols[cols.length - 1] === 'string' &&
      cols[cols.length - 1] &&
      cols.length > popupData.columns.length - 1
    );
  };

  const onUploadFile = async (f: File) => {
    try {
      setFile(f);
      setBackupFile(f);
      const rows = await readXlsxFile(f);
      rows.shift(); // headers
      setTableData(rows);
      console.log(rows, 'rows rows');
    } catch (e) {
      console.log(e, 'error');
      removeFile();
    }
  };

  const onUploadErrorFile = async (f: File, errors: any) => {
    try {
      setFile(f);
      const rows = await readXlsxFile(f);
      rows.shift(); // headers
      const newRows = rows.reduce((newRow: any, row: any, index: any) => {
        row.push(errors[index + 2]);
        newRow.push(row);
        return newRow;
      }, []);
      setTableData(newRows);
      if (errors && errors !== {}) {
        notification.error({
          message: 'Dosyada Hatalar Bulunuyor, Düzeltip Tekrar Deneyiniz!',
        });
      }
    } catch (e) {
      console.log(e, 'error');
      removeFile();
    }
  };

  const uploadFile = async (type: any) => {
    if (tableUpdateData.length > 0) {
      setIsLoadingUpload(true);
      updateFile();
      return;
    }
    if (file) {
      setIsLoadingUpload(true);
      try {
        const formData = new FormData();
        formData.append('file', file);
        let fileName: any = '';
        let response: any = '';
        switch (popupData.key) {
          case 'guarantee':
            type === 'new'
              ? (response = await uploadFilesApi.deleteUploadGuaranteeExcel(formData))
              : (response = await uploadFilesApi.uploadGuaranteeExcel(formData));
            fileName = 'Toplu_Teminat_Mektubu_Response';
            break;
          case 'insurance':
            type === 'new'
              ? (response = await uploadFilesApi.deleteUploadInsuranceExcel(formData))
              : (response = await uploadFilesApi.uploadInsuranceExcel(formData));
            fileName = 'Toplu Alacak Sigortası_Response';
            break;
          case 'dbs':
            type === 'new'
              ? (response = await uploadFilesApi.deleteUploadDbsExcel(formData))
              : (response = await uploadFilesApi.uploadDbsExcel(formData));
            fileName = 'Toplu DBS Yükle_Response';
            break;
          case 'lien':
            type === 'new'
              ? (response = await uploadFilesApi.deleteUploadLienExcel(formData))
              : (response = await uploadFilesApi.uploadLienExcel(formData));
            fileName = 'Toplu İpotek Yükleme_Response';
            break;
          case 'akreditif':
            type === 'new'
              ? (response = await uploadFilesApi.deleteUploadAkreditifExcel(formData))
              : (response = await uploadFilesApi.uploadAkreditifExcel(formData));
            fileName = 'Toplu Akreditif Yükleme_Response';
            break;
          case 'factoring':
            type === 'new'
              ? (response = await uploadFilesApi.deleteUploadAccountFactoringExcel(formData))
              : (response = await uploadFilesApi.uploadAccountFactoringExcel(formData));
            fileName = 'Toplu Faktoring Yükleme_Response';
            break;
          case 'temliki':
            type === 'new'
              ? (response = await uploadFilesApi.deleteUploadTemlikiExcel(formData))
              : (response = await uploadFilesApi.uploadTemlikiExcel(formData));
            fileName = 'Toplu Alacak Temliki Yükleme';
            break;
          case 'diğer_teminat':
            type === 'new'
              ? (response = await uploadFilesApi.deleteUploadOthersExcel(formData))
              : (response = await uploadFilesApi.uploadOthersExcel(formData));
            fileName = 'Toplu Diğer Teminat Yükleme';
            break;
          case 'risk':
            type === 'new'
              ? (response = await uploadFilesApi.deleteUploadAlacakRiskExcel(formData))
              : (response = await uploadFilesApi.uploadAlacakRiskExcel(formData));
            fileName = 'Toplu Alacak Risk Tablosu Yükleme';
            break;
          case 'endorsement':
            type === 'new'
              ? (response = await uploadFilesApi.deleteUploadEndorsementExcel(formData))
              : (response = await uploadFilesApi.uploadEndorsementExcel(formData));
            fileName = 'Toplu Vade Ciro Tablosu Yükleme';
            break;
          default:
        }
        if (response && response.item2) {
          removeFile();
          if (
            response.item1 &&
            response.item1 !== {} &&
            Object.keys(response.item1) &&
            Object.keys(response.item1).length > 0
          ) {
            await downloadFileResponse(response.item2, fileName);
            onUploadErrorFile(backupFile as File, response.item1);
          } else {
            notification.success({
              message: 'İşlem Başarılı',
            });
          }
        }
      } finally {
        setIsLoadingUpload(false);
      }
    }
  };

  useEffect(() => {
    removeFile();
  }, [popupData]);

  const handleDelete = (record: any, index: any) => {
    setDeleteKey(index);
  };

  const handleEdit = (record: any, index: any) => {
    form.setFieldsValue({...record});
    setEditingKey(index);
  };

  const editConfirm = async () => {
    const foundIndex =
      tableUpdateData.length > 0 && tableUpdateData.findIndex((val: any) => val.id === editingKey);
    if (foundIndex && foundIndex >= 0) {
      tableUpdateData[foundIndex] = form.getFieldsValue();
    } else {
      const newObj = generateDataObject(form.getFieldsValue(), popupData.columns);
      tableUpdateData.push({id: editingKey, accountId: 0, ...newObj});
    }
    setTableUpdateData([...tableUpdateData]);
    tableData[editingKey] = _.values(form.getFieldsValue());
    setTableData([...tableData]);
    setEditingKey(-1);
  };

  const isEditing = (index: any) => index === editingKey;

  const onClose = () => {
    setEditingKey(-1);
    setDeleteKey(-1);
  };

  const columns: any = popupData?.columns
    ? [...popupData.columns].map((label, idx) => ({
        dataIndex: idx,
        title: label,
        editable: true,
        render: (value: string, record: any, index: any) => {
          return idx === popupData.columns.length - 1 ? (
            <div>
              <Space className="edit-space" size="middle">
                <div
                  role="button"
                  aria-hidden="true"
                  tabIndex={0}
                  onClick={() => handleDelete(record, index)}
                >
                  <Icon
                    size="lg"
                    color={isCheckError(record) ? 'inherit' : 'red'}
                    icon="trash-alt"
                  />
                </div>
                <div
                  role="button"
                  aria-hidden="true"
                  tabIndex={0}
                  onClick={() => handleEdit(record, index)}
                >
                  <Icon size="lg" color="primary" icon="pen" />
                </div>
              </Space>
              {isEditing(index) || deleteKey === index ? (
                <div className="edit-popup">
                  <div className="desc-btns">
                    <Button onClick={onClose} type="default" className="mr">
                      Vazgeç
                    </Button>
                    <Button onClick={editConfirm} type="primary">
                      Evet
                    </Button>
                  </div>
                </div>
              ) : (
                <Text type="h5" color="inherit">
                  {isCheckError(record) && record[record.length - 1]}
                </Text>
              )}
            </div>
          ) : (
            <div>
              {typeof value === 'object' || (typeof value === 'string' && value?.includes('/'))
                ? moment(new Date(value)).format('DD/MM/YYYY')
                : idx !== 0 && typeof value === 'number'
                ? convertToDotSeperated(value)
                : value}
            </div>
          );
        },
      }))
    : [];

  const mergedColumns = columns?.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any, index: any) => ({
        record,
        inputType: col.dataIndex === 2 ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(index),
      }),
    };
  });

  return (
    <ContentContainer>
      <Row className="mb-big" justify="space-between" align="middle">
        <Col>
          <Upload
            label="Excel Yükle"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            fileList={file ? [file] : []}
            onChange={(val: any) => onUploadFile(val.file)}
            onRemove={removeFile}
          />
        </Col>
        <Col>
          <div className="desc-btns">
            <Button
              onClick={() => uploadFile('old')}
              disabled={!file}
              loading={isLoadingUpload}
              type="default"
              className="mr"
            >
              Üzerine Ekle
            </Button>
            <Button
              className="mr"
              disabled={!file}
              loading={isLoadingUpload}
              onClick={() => uploadFile('new')}
              type="primary"
            >
              Portföyü Baştan Yükle
            </Button>
            <Button onClick={downloadSample} type="primary">
              Şablon İndir
            </Button>
          </div>
        </Col>
      </Row>
      {!!tableData?.length && (
        <Form form={form} component={false}>
          <Table
            dataSource={tableData}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={mergedColumns}
          />
        </Form>
      )}
    </ContentContainer>
  );
};

export default UploadBulkItemPopup;
