import React from 'react';
import {Select, Spin} from 'antd';
import {SelectProps} from 'antd/es/select';
import {debounce} from 'lodash';

export interface IAutoComplete<ValueType = any>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

function AutoComplete<
  ValueType extends {key?: string; label: React.ReactNode; value: string | number} = any,
>({fetchOptions, debounceTimeout = 800, ...props}: IAutoComplete) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState<ValueType[]>([]);
  const fetchRef = React.useRef(0);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      filterOption={false}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      labelInValue
      autoClearSearchValue
      showSearch
      onSearch={debounceFetcher}
      onChange={() => debounceFetcher('')}
      {...props}
      options={options}
    />
  );
}

export const {Option} = Select;
export default AutoComplete;
