import React from 'react';
import {Col, Table} from 'antd';
import Text from '../../../../components/Text';
import {convertToDotSeperated} from '../../../../utils/string';
import BarChart from '../../../../components/Charts/BarChart';
import {ICustomerProfit} from '../../../../Api/Customer/types';
import {useAppSelector} from '../../../../hooks/useRedux';
import {CustomIcon} from '../../../../components/Icon';

const GeneralInfo = () => {
  const customer = useAppSelector((state) => state.customer.customerCreateQueryData);
  const tableCols = [
    {
      title: 'Takvim Yılı',
      dataIndex: 'year',
    },
    {
      title: 'Beyan Olunan Matrah',
      dataIndex: 'taxAssessment',
      render: (text: React.ReactNode) => <>{convertToDotSeperated(`${text}`)}</>,
    },
    {
      title: 'Tahakkuk Eden Vergi',
      dataIndex: 'accrued',
      render: (text: React.ReactNode) => <>{convertToDotSeperated(`${text}`)}</>,
    },
  ];

  const mapProfits = (data: ICustomerProfit[]) => {
    return (
      (data?.length > 0 &&
        data
          .map((profit: ICustomerProfit) => ({
            ...profit,
            datetime: new Date(`01/01/${profit.year}`),
          }))
          .reverse()) ||
      []
    );
  };

  return (
    <div className="w-100" id="customer-tax-data">
      <div className="section-head mb-20">
        <CustomIcon icon="home" className="mr" />
        <Text type="h2" className="section-title">
          Temel Bilgiler
        </Text>
      </div>
      <div className="pl-20">
        <Text bold block className="primary-text">
          Ticaret Unvanı:
        </Text>
        <Text>{customer?.title}</Text>
        <br />
        <br />

        <Text bold block className="primary-text">
          Vergi Kimlik No:
        </Text>
        <Text>{customer?.taxId}</Text>
        <br />
        <br />
        <Text bold block className="primary-text">
          Vergi Dairesi:
        </Text>
        <Text>{customer?.taxAdministration}</Text>
        <br />
        <br />
        <Text bold block className="primary-text">
          İş Yeri Adresi:
        </Text>
        <Text>{customer?.address}</Text>
        <br />
        <br />
        <Text bold block className="primary-text">
          İşe Başlama Tarihi:
        </Text>
        <Text>{customer?.startDate}</Text>
        <br />
        <br />
        <Text bold block className="primary-text">
          Ana Faaliyet Kodu ve Adı:
        </Text>
        <Text>{customer?.operationDetail}</Text>
        <br />
        <br />

        {customer?.profits.length > 0 && (
          <div>
            <Text bold block className="primary-text mb-10">
              Son 3 Yıla Ait Kar Bilgisi:
            </Text>
            <div className="w-100 d-flex justify-between">
              <Col span={12}>
                <div className="graph-box">
                  <BarChart
                    data={mapProfits(customer?.profits)}
                    xKey="datetime"
                    yBarsLeft={[
                      {
                        key: 'taxAssessment',
                        displayKey: 'Kar',
                        fill: 'primary',
                        color: 'primary',
                      },
                    ]}
                    yUnitLeft=" ₺"
                  />
                </div>
              </Col>
              <Col span={11}>
                <Table
                  rowClassName="cursor-pointer"
                  columns={tableCols}
                  bordered
                  dataSource={customer?.profits}
                  pagination={false}
                  rowKey="year"
                />
              </Col>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneralInfo;
