import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {ButtonProps} from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default styled<React.FC<ButtonProps>>(Button as any)`
  user-select: none !important;
  box-shadow: none;

  &.ant-btn-lg {
    padding-left: ${({theme}) => theme.main.paddingMedium}px;
    padding-right: ${({theme}) => theme.main.paddingMedium}px;
  }

  background-color: ${({type, theme}) => {
    switch (type) {
      case 'primary-faded':
        return theme.colors.primaryFaded;
      default:
        return '';
    }
  }};
  color: ${({type, theme}) => {
    switch (type) {
      case 'primary-faded':
        return theme.colors.primary;
      default:
        return '';
    }
  }};

  border-color: ${({type, theme}) => {
    switch (type) {
      case 'primary-faded':
        return theme.colors.primaryFaded;
      default:
        return '';
    }
  }};

  &.ant-btn:hover,
  &.ant-btn:focus {
    background-color: ${({type, theme}) => {
      switch (type) {
        case 'primary-faded':
          return theme.colors.primaryFaded;
        default:
          return '';
      }
    }};
    color: ${({type, theme}) => {
      switch (type) {
        case 'primary-faded':
          return theme.colors.primary;
        default:
          return '';
      }
    }};
    border-color: ${({type, theme}) => {
      switch (type) {
        case 'primary-faded':
          return theme.colors.primaryFaded;
        default:
          return '';
      }
    }};
  }
`;
