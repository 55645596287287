import React, {FC, useEffect, useState} from 'react';
import {Col, Form, Input, notification, Row, Spin} from 'antd';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import Upload from '../../../../components/Upload';
import ProgressStep from '../../../../components/ProgressStep';
import {convertFileToAntdFile, FilePromise, getBase64, normFile} from '../../../../utils/file';
import {ICustomerCreateComponent} from '../types';
import {useAppSelector} from '../../../../hooks/useRedux';
import customerApi from '../../../../Api/Customer';
import {IApiImage} from '../../../../Api/types';
import searchApi from '../../../../Api/Search';

const FindeksInfo: FC<ICustomerCreateComponent> = ({onSubmit, onCancel}) => {
  const [form] = Form.useForm();
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const customer = useAppSelector((state) => state.customer.customerCreateQueryData);
  const [findekData, setFindekData] = useState<any>(null);

  const fetchInitialData = async () => {
    const {chequeReport, qrCodedChequeReport, riskReport} =
      await customerApi.fetchCustomerFindeksInfo(customer.accountId);

    setFindekData({chequeReport, qrCodedChequeReport, riskReport});

    const documents = await Promise.all([
      chequeReport ? FilePromise(chequeReport) : null,
      qrCodedChequeReport ? FilePromise(qrCodedChequeReport) : null,
      riskReport ? FilePromise(riskReport) : null,
    ]);

    const [chequeReportFile, qrCodedChequeReportFile, riskReportFile] = documents.map(
      (file) => file && convertFileToAntdFile(file as File),
    );
    form.setFieldsValue({
      chequeReport: chequeReportFile ? [chequeReportFile] : [],
      qrCodedChequeReport: qrCodedChequeReportFile ? [qrCodedChequeReportFile] : [],
      riskReport: riskReportFile ? [riskReportFile] : [],
    });
    setIsLoadingInitialData(false);
  };

  const handleSubmit = async (values: any) => {
    try {
      setIsLoadingSubmit(true);

      const riskReport: IApiImage | undefined =
        values?.riskReport[0]?.originFileObj &&
        (await getBase64(values.riskReport[0].originFileObj));
      const qrCodedChequeReport: IApiImage | undefined =
        values?.qrCodedChequeReport[0]?.originFileObj &&
        (await getBase64(values.qrCodedChequeReport[0].originFileObj));
      const chequeReport: IApiImage | undefined =
        values?.chequeReport[0]?.originFileObj &&
        (await getBase64(values.chequeReport[0].originFileObj));

      await customerApi.uploadCustomerFindeksInfo({
        accountId: customer.accountId,
        riskReport,
        qrCodedChequeReport,
        chequeReport,
      });
      onSubmit();
    } catch (error) {
      console.log(error);
      setIsLoadingSubmit(false);
    }
  };

  useEffect(() => {
    if (customer.accountId && customer.accountId !== 0) {
      fetchInitialData();
    }
  }, [customer]);

  const handleDeleteDocument = (file: any, key: any) => {
    searchApi.deleteDocument(file.tciDoumentId).then((res) => {
      console.log(res, 'res');
      notification.success({message: 'İşlem Başarılı', description: 'Dosya silindi yanıtı'});
      form.setFieldsValue({[key]: []});
    });
  };

  return (
    <Spin spinning={isLoadingInitialData}>
      <ProgressStep className="mb-big" title="Findeks Bilgileri" value={5} steps={8} />
      <Text type="h2" bold>
        Findeks Bilgileri
      </Text>

      <Form
        form={form}
        name="findeksInfo"
        layout="vertical"
        requiredMark={false}
        initialValues={{company: customer.title}}
        onFinish={handleSubmit}
      >
        <Row>
          <Col span={10}>
            <Form.Item name="company" label="Firma Adı">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Text type="h4" weight="initial" bold>
          Findeks Risk Raporu
        </Text>
        <Form.Item name="riskReport" valuePropName="fileList" getValueFromEvent={normFile}>
          <Upload
            label="PDF Yükle"
            accept=".doc,.docx,application/pdf"
            documentId={findekData?.riskReport?.tciDoumentId}
            onRemove={() => handleDeleteDocument(findekData?.riskReport, 'riskReport')}
          />
        </Form.Item>

        <Text type="h4" weight="initial" bold>
          Findeks Karekodlu Çek Raporu
        </Text>
        <Form.Item name="qrCodedChequeReport" valuePropName="fileList" getValueFromEvent={normFile}>
          <Upload
            label="PDF Yükle"
            accept=".doc,.docx,application/pdf"
            documentId={findekData?.qrCodedChequeReport?.tciDoumentId}
            onRemove={() =>
              handleDeleteDocument(findekData?.qrCodedChequeReport, 'qrCodedChequeReport')
            }
          />
        </Form.Item>

        <Text type="h4" weight="initial" bold>
          Findeks Çek Raporu
        </Text>
        <Form.Item name="chequeReport" valuePropName="fileList" getValueFromEvent={normFile}>
          <Upload
            label="PDF Yükle"
            accept=".doc,.docx,application/pdf"
            documentId={findekData?.chequeReport?.tciDoumentId}
            onRemove={() => handleDeleteDocument(findekData?.chequeReport, 'chequeReport')}
          />
        </Form.Item>

        <Row gutter={20}>
          <Col span={8}>
            <Form.Item className="m-0">
              <Button type="primary" htmlType="submit" size="large" loading={isLoadingSubmit} block>
                Kaydet ve Devam Et
              </Button>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item className="m-0">
              <Button
                type="default"
                size="large"
                loading={isLoadingSubmit}
                onClick={onCancel}
                block
              >
                Kaydet ve Çık
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default FindeksInfo;
