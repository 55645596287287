/* eslint-disable import/prefer-default-export */
import {Col, Row} from 'antd';
import styled from 'styled-components';

export const SummaryRow = styled(Row)`
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  overflow: hidden;
`;
export const SummaryCol = styled(Col)`
  border: 1px solid #e8e8e8;
  padding: 10px;

  &.smoke {
    background: ${({theme}) => theme.colors.lightSmoke};
  }
`;
