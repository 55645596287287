import {useMediaQuery} from 'react-responsive';
import {useTheme} from 'styled-components';

const useResponsive = (): {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  xxl: boolean;
} => {
  const theme = useTheme();
  const {xsMaxSize, smMinSize, mdMinSize, lgMinSize, xlMinSize, xxlMinSize} = theme.main;

  const xs = useMediaQuery({query: `(max-width: ${xsMaxSize}px)`});
  const sm = useMediaQuery({query: `(min-width: ${smMinSize}px)`});
  const md = useMediaQuery({query: `(min-width: ${mdMinSize}px)`});
  const lg = useMediaQuery({query: `(min-width: ${lgMinSize}px)`});
  const xl = useMediaQuery({query: `(min-width: ${xlMinSize}px)`});
  const xxl = useMediaQuery({query: `(min-width: ${xxlMinSize}px)`});

  return {
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
  };
};

export default useResponsive;
