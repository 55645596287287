import React from 'react';
import {SelectProps} from 'antd/es/select';
import AutoComplete from '../AutoComplete';
import StyledAutoCompleteContainer, {SearchIconContainer} from './styles';
import {CustomIcon} from '../Icon';

export interface IAutoComplete<ValueType = any>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}
function StyledAutoComplete(props: IAutoComplete) {
  return (
    <StyledAutoCompleteContainer>
      <SearchIconContainer>
        <CustomIcon className="h-100 w-100 d-block m-auto" icon="search" />
      </SearchIconContainer>
      <AutoComplete {...props} />
    </StyledAutoCompleteContainer>
  );
}

export default StyledAutoComplete;
