import {tciApiV2} from '..';

export default {
  downloadGuaranteeExcel(): Promise<any> {
    return tciApiV2.downloadFile('Guarantee/import/template', 'Toplu_Teminat_Mektubu');
  },
  deleteUploadGuaranteeExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('Guarantee/import?firstDeletaAll=true', formData);
  },
  uploadGuaranteeExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('Guarantee/import', formData);
  },
  updateGuaranteeExcel(data: any, accountId: any): Promise<number> {
    return tciApiV2.put(`Guarantee/${accountId}`, data);
  },
  downloadInsuranceExcel(): Promise<any> {
    return tciApiV2.downloadFile('InsuranceRating/import/template', 'Toplu Alacak Sigortası');
  },
  deleteUploadInsuranceExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('InsuranceRating/import?firstDeletaAll=true', formData);
  },
  uploadInsuranceExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('InsuranceRating/import', formData);
  },
  updateInsuranceExcel(data: any, accountId: any): Promise<number> {
    return tciApiV2.put(`InsuranceRating/${accountId}`, data);
  },
  downloadDbsExcel(): Promise<any> {
    return tciApiV2.downloadFile('Dbs/import/template', 'Toplu DBS Yükle');
  },
  deleteUploadDbsExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('Dbs/import?firstDeletaAll=true', formData);
  },
  uploadDbsExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('Dbs/import', formData);
  },
  updateDbsExcel(data: any, accountId: any): Promise<number> {
    return tciApiV2.put(`Dbs/${accountId}`, data);
  },
  downloadLienExcel(): Promise<any> {
    return tciApiV2.downloadFile('Lien/import/template', 'Toplu İpotek Yükleme');
  },
  deleteUploadLienExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('Lien/import?firstDeletaAll=true', formData);
  },
  uploadLienExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('Lien/import', formData);
  },
  updateLienExcel(data: any, accountId: any): Promise<number> {
    return tciApiV2.put(`Lien/${accountId}`, data);
  },
  downloadAkreditifExcel(): Promise<any> {
    return tciApiV2.downloadFile('Akreditif/import/template', 'Toplu Akreditif Yükleme');
  },
  deleteUploadAkreditifExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('Akreditif/import?firstDeletaAll=true', formData);
  },
  uploadAkreditifExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('Akreditif/import', formData);
  },
  updateAkreditifExcel(data: any, accountId: any): Promise<number> {
    return tciApiV2.put(`Akreditif/${accountId}`, data);
  },
  downloadAccountFactoringExcel(): Promise<any> {
    return tciApiV2.downloadFile('Account/factoring/import/template', 'Toplu Faktoring Yükleme');
  },
  deleteUploadAccountFactoringExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('Account/factoring/import?firstDeletaAll=true', formData);
  },
  uploadAccountFactoringExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('Account/factoring/import', formData);
  },
  updateAccountFactoringExcel(data: any, accountId: any): Promise<number> {
    return tciApiV2.put(`Account/factoring/${accountId}`, data);
  },
  downloadTemlikiExcel(): Promise<any> {
    return tciApiV2.downloadFile(
      'TopluAlacak/templik/import/template',
      'Toplu Alacak Temliki Yükleme',
    );
  },
  deleteUploadTemlikiExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('TopluAlacak/templik/import?firstDeletaAll=true', formData);
  },
  uploadTemlikiExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('TopluAlacak/templik/import', formData);
  },
  updateTemlikiExcel(data: any, accountId: any): Promise<number> {
    return tciApiV2.put(`TopluAlacak/templik/${accountId}`, data);
  },
  downloadOthersExcel(): Promise<any> {
    return tciApiV2.downloadFile('Diger/import/template', 'Toplu Diğer Teminat Yükleme');
  },
  deleteUploadOthersExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('Diger/import?firstDeletaAll=true', formData);
  },
  uploadOthersExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('Diger/import', formData);
  },
  updateOthersExcel(data: any, accountId: any): Promise<number> {
    return tciApiV2.put(`Diger/${accountId}`, data);
  },

  downloadAlacakRiskExcel(): Promise<any> {
    return tciApiV2.downloadFile(
      'TopluAlacak/risk/import/template',
      'Toplu Alacak Risk Tablosu Yükleme',
    );
  },
  deleteUploadAlacakRiskExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('TopluAlacak/risk/import?firstDeletaAll=true', formData);
  },
  uploadAlacakRiskExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('TopluAlacak/risk/import', formData);
  },
  updateAlacakRiskExcel(data: any, accountId: any): Promise<number> {
    return tciApiV2.put(`TopluAlacak/risk/${accountId}`, data);
  },
  downloadEndorsementExcel(): Promise<any> {
    return tciApiV2.downloadFile(
      'Account/endorsement/import/template',
      'Toplu Vade Ciro Tablosu Yükleme',
    );
  },
  deleteUploadEndorsementExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('Account/endorsement/import?firstDeletaAll=true', formData);
  },
  uploadEndorsementExcel(formData: FormData): Promise<number> {
    return tciApiV2.post('Account/endorsement/import', formData);
  },
  updateEndorsementExcel(data: any, accountId: any): Promise<number> {
    return tciApiV2.put(`Account/endorsement/${accountId}`, data);
  },
};
