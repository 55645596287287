import moment, {Moment} from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const getRemainingPremiumDates = (premiumExpirationDate: string) => {
  const expirationDate = moment(premiumExpirationDate);
  const today = moment();

  return Math.abs(today.diff(expirationDate, 'days')) + 1;
};

export const getDateString = (date: Moment | string) => {
  const format = 'LLLL';
  if (moment.isMoment(date)) {
    return date.format(format);
  }
  return moment(date).format(format);
};
