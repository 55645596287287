import React, {FC} from 'react';
import {Col, Divider, Row} from 'antd';
import Button from '../../../../../components/Button';
import Text from '../../../../../components/Text';
import ProgressStep from '../../../../../components/ProgressStep';
import Table from '../../../../../components/Table';
import {ICustomerProfit, ICustomerQuery} from '../../../../../Api/Customer/types';
import BarChart from '../../../../../components/Charts/BarChart';

interface Props extends ICustomerQuery {
  onSubmit: () => void;
  onCancel: () => void;
}

const TaxData: FC<Props> = ({
  title,
  taxId,
  taxAdministration,
  address,
  startDate,
  operationDetail,
  profits,
  onSubmit,
  onCancel,
}) => {
  const mapProfits = (data: ICustomerProfit[]) => {
    return data
      .map((profit: ICustomerProfit) => ({...profit, datetime: new Date(`01/01/${profit.year}`)}))
      .reverse();
  };

  return (
    <div id="customer-tax-data">
      <Text className="m-0" type="h3" bold>
        Ticaret Unvanı:
      </Text>
      <Text>{title}</Text>
      <br />
      <br />
      <Text className="m-0" type="h3" bold>
        Vergi Kimlik No:
      </Text>
      <Text>{taxId}</Text>
      <br />
      <br />
      <Text className="m-0" type="h3" bold>
        Vergi Dairesi:
      </Text>
      <Text>{taxAdministration}</Text>
      <br />
      <br />
      <Text className="m-0" type="h3" bold>
        İş Yeri Adresi:
      </Text>
      <Text>{address}</Text>
      <br />
      <br />
      <Text className="m-0" type="h3" bold>
        İşe Başlama Tarihi:
      </Text>
      <Text>{startDate}</Text>
      <br />
      <br />
      <Text className="m-0" type="h3" bold>
        Ana Faaliyet Kodu ve Adı:
      </Text>
      <Text>{operationDetail}</Text>

      <Divider />

      <Text className="m-0" type="h3" bold>
        Son 3 yıl Beyan Olunan Matrah
      </Text>

      <BarChart
        data={mapProfits(profits)}
        xKey="datetime"
        yBarsLeft={[
          {
            key: 'taxAssessment',
            displayKey: 'Kar',
            fill: 'primary',
            color: 'primary',
          },
        ]}
        yUnitLeft=" ₺"
      />

      <Table
        className="my"
        pagination={false}
        dataSource={mapProfits(profits)}
        columns={[
          {
            title: 'Takvim Yılı',
            dataIndex: 'datetime',
            key: 'datetime',
            render: (date: Date) => date.getFullYear(),
          },
          {
            title: 'Beyan Olunan Matrah',
            dataIndex: 'taxAssessment',
            key: 'taxAssessment',
          },
          {
            title: 'Tahakkuk Eden Vergi',
            dataIndex: 'accrued',
            key: 'accrued',
          },
        ]}
      />

      <ProgressStep className="my-big" title="Temel Bilgiler" value={1} steps={8} />

      <Row gutter={20}>
        <Col span={16}>
          <Button type="primary" block onClick={onSubmit}>
            Kaydet ve Devam Et
          </Button>
        </Col>
        <Col span={8}>
          <Button block onClick={onCancel}>
            Kaydet ve Çık
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default TaxData;
