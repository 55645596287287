import styled, {css} from 'styled-components';
import Props from './types';

export default styled.div<Props>`
  ${({margin, theme}) =>
    margin &&
    css`
      margin: ${theme.card.margin}px;
    `};
  ${({padding, theme}) =>
    padding &&
    css`
      padding: ${theme.card.padding}px;
    `};

  border-radius: ${({theme}) => theme.card.borderRadius}px;
  ${({theme, borderBottomColor}) =>
    borderBottomColor &&
    css`
      border-bottom: 6px solid ${theme.getThemedColor(borderBottomColor)};
    `};
  ${({theme, borderLeftColor}) =>
    borderLeftColor &&
    css`
      border-left: 6px solid ${theme.getThemedColor(borderLeftColor)};
    `};

  background: ${({theme, background}) =>
    background ? theme.getThemedColor(background) : theme.card.background};

  color: ${({color}) => color};
  box-shadow: ${({theme, shadow}) => (shadow ? theme.main.shadow : '')};
  word-break: break-word;

  overflow-x: ${({overflowX}) => overflowX || 'auto'};
  overflow-y: ${({overflowY}) => overflowY || 'auto'};
  transition: ${({theme}) => theme.main.defaultAnimation};

  &:hover {
    cursor: ${({onClick}) => onClick && 'pointer'};
    box-shadow: ${({theme, hover}) => hover && theme.main.shadowHover};
    ${({hover}) =>
      hover &&
      css`
        transform: scale(1.02);
      `};
  }

  &.full-height {
    margin-bottom: 0px;
    margin-top: 0px;
    height: 100%;
  }

  .card-cover {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
`;
