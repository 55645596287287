import React from 'react';
import {Row, Tag, Tooltip} from 'antd';

const CustomLegend = ({payload, selectedKeys, onClick}) => (
  <Row justify="center">
    {payload.map((entry, index) => (
      <Tooltip key={`item-${index}`} title={entry && entry.payload.tip}>
        <Tag
          color={selectedKeys.includes(entry.dataKey) ? entry.color : ''}
          onClick={() => onClick(entry)}
          style={{cursor: 'pointer'}}
        >
          <span style={{userSelect: 'none'}}>{entry.value}</span>
        </Tag>
      </Tooltip>
    ))}
  </Row>
);

CustomLegend.defaultProps = {
  payload: [],
  selectedKeys: [],
  onClick: null,
};

export default CustomLegend;
