import React, {useEffect, useState} from 'react';
import {useTheme} from 'styled-components';
import moment from 'moment';
import {
  LineChart as RCLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {Spin} from 'antd';
import CustomTooltip from './CustomTooltip';
import CustomLegend from './CustomLegend';

const LineChart = ({
  height,
  data,
  xKey,
  yLinesLeft,
  yUnitLeft,
  yLinesRight,
  yUnitRight,
  tickFormatter,
  syncId,
  grid,
  title,
  loading,
  animationActive,
}) => {
  const theme = useTheme();
  const [isDateX, setIsDateX] = useState(false); // decides format of the x-axis
  const [shouldDisplayTime, setShouldDisplayTime] = useState(false); // decides format of the x-axis
  const [selectedKeys, setSelectedKeys] = useState([
    ...yLinesLeft.map(({key}) => key),
    ...yLinesRight.map(({key}) => key),
  ]); // lines will be visible if it is selected

  const {getThemedColor} = theme;
  useEffect(() => {
    if (data && xKey && xKey === 'datetime') {
      console.log(xKey);
      const isDate = !!(data[0] && moment(data[0][xKey]).isValid());
      setIsDateX(isDate);
      if (isDate) {
        const startDate = moment(data[0][xKey]);
        const endDate = moment(data[data.length - 1][xKey]);
        const dayDiff = Math.abs(startDate.diff(endDate, 'days'));
        setShouldDisplayTime(dayDiff <= 2);
      }
    }
  }, [xKey, data]);

  const handleClickLegend = ({dataKey}) => {
    setSelectedKeys((prevState) => {
      if (prevState.includes(dataKey)) {
        return prevState.filter((key) => key !== dataKey);
      }
      return [...prevState, dataKey];
    });
  };

  const getXFormat = () => {
    if (isDateX) {
      if (shouldDisplayTime) {
        return (tick) => {
          const date = moment(tick);
          return date.isValid() ? date.format('HH:mm') : '';
        };
      }
      return (tick) => {
        const date = moment(tick);
        return date.isValid() ? date.format('DD/MM') : '';
      };
    }
    return tickFormatter;
  };

  if (loading) {
    return (
      <Spin spinning>
        <div style={{height}} />
      </Spin>
    );
  }

  return (
    <div>
      <h2 className="primary">{title}</h2>
      <ResponsiveContainer height={height}>
        <RCLineChart
          data={data}
          syncId={syncId || null}
          margin={{
            top: 25,
            right: 20,
            left: 20,
            bottom: 5,
          }}
        >
          {grid && <CartesianGrid strokeDasharray="3 3" />}
          <XAxis dataKey={xKey} tickFormatter={getXFormat()} />

          {/* MORE THAN ONE LINE CAN BE ON A Y-AXIS [ LEFT - RIGHT ] */}
          {yLinesLeft.length && <YAxis unit={yUnitLeft} orientation="left" yAxisId="left" />}
          {yLinesRight.length && <YAxis unit={yUnitRight} orientation="right" yAxisId="right" />}

          {[
            ...yLinesLeft.map((options) => ({...options, yAxisId: 'left'})),
            ...yLinesRight.map((options) => ({...options, yAxisId: 'right'})),
          ].map(({key, color, type, displayKey, yAxisId, tip, strokeWidth}, idx) => (
            <Line
              key={`line-${idx}`}
              name={displayKey || key}
              type="monotone"
              dataKey={key}
              stroke={
                (color && getThemedColor(color)) ||
                (idx === 0 && yAxisId !== 'right'
                  ? theme.main.primaryColor
                  : theme.main.secondaryColor)
              }
              strokeDasharray={type === 'dashed' ? '5 5' : ''}
              strokeWidth={strokeWidth || 2}
              opacity={selectedKeys.includes(key) ? 1 : 0.2}
              dot
              yAxisId={yAxisId}
              tip={tip}
              isAnimationActive={animationActive}
            />
          ))}

          {/* {
        RenderLines({
          options: [
            ...yLinesLeft.map((options) => ({ ...options, yAxisId: 'left' })),
            ...yLinesRight.map((options) => ({ ...options, yAxisId: 'right' })),
          ],
          selectedKeys,
        }) */}

          <Tooltip content={<CustomTooltip selectedKeys={selectedKeys} isLabelDate={isDateX} />} />
          <Legend
            onClick={handleClickLegend}
            content={<CustomLegend />}
            selectedKeys={selectedKeys}
          />
        </RCLineChart>
      </ResponsiveContainer>
    </div>
  );
};

LineChart.defaultProps = {
  height: 300,
  data: [],
  yLinesLeft: [],
  yLinesRight: [],
  yUnitLeft: '',
  yUnitRight: '',
  tickFormatter: (tick) => tick,
  syncId: '',
  grid: true,
  title: '',
  loading: false,
  animationActive: true,
};

export default LineChart;

// const RenderLines = ({ options, selectedKeys }) => {
//   const theme = useTheme();

//   if (!options) return null;

//   return (Array.isArray(options) ? options : [options]).map(({
//     key, color, type, displayKey, yAxisId,
//   }, idx) => (
//     <Line
//       key={`line-${idx}`}
//       name={displayKey || key}
//       type="monotone"
//       dataKey={key}
//       stroke={color || ((idx === 0 && yAxisId !== 'right') ? theme.main.primaryColor : theme.main.secondaryColor)}
//       strokeDasharray={type === 'dashed' ? '5 5' : ''}
//       opacity={selectedKeys.includes(key) ? 1 : 0.2}
//       dot={false}
//       yAxisId={yAxisId}
//     />
//   ));
// };
// RenderLines.propTypes = {
//   options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
//   selectedKeys: PropTypes.array,
// };
// RenderLines.defaultProps = {
//   selectedKeys: [],
// };
