/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/prefer-default-export
export function distortValue(arr: any[], key: string | number, mapper: (arg0: any) => any) {
  return arr.map((item: {[x: string]: any}) => ({...item, [key]: mapper(item[key])}));
}

export function getCityByProvinceId(cities: any[], provinceId: string | number) {
  const foundCity: any = cities.find((_city) => _city.id === provinceId);
  return foundCity.label;
}
