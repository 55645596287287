import {tciApi, tciApiV2} from '..';
import {ICustomerQuery, ICustomersResponse} from '../Customer/types';

export default {
  queryCustomer(
    taxNumber: string,
    taxOfficeName?: string,
    isCandidate?: boolean,
    customerKindId?: number,
  ): Promise<ICustomerQuery> {
    const idKey = taxNumber.toString.length === 11 ? 'Tckn' : 'Vkn';
    return tciApi.post('/autotax/query', {
      [idKey]: taxNumber,
      vergiDairesi: taxOfficeName,
      isCandidate,
      customerKindId,
    });
  },

  deleteCustomerSale(customerId: any): Promise<ICustomersResponse> {
    return tciApiV2.delete(`/Customer/${customerId}`);
  },

  uploadCustomerSales(data: any): Promise<ICustomerQuery> {
    return tciApiV2.post('/Customer', data);
  },

  fetchCustomerSales({
    search = undefined,
    page = 1,
    size = 10,
  }: {
    search?: string;
    page?: number;
    size?: number;
  } = {}): Promise<ICustomersResponse> {
    return tciApiV2.get('/Customer', {params: {search, page, size}});
  },

  fetchSaleCustomer(id: number): Promise<ICustomersResponse> {
    return tciApiV2.get(`/Account/${id}/customer`);
  },

  fetchSaleCompanyAnalysis(id: number): Promise<ICustomersResponse> {
    return tciApiV2.get(`/Customer/${id}/company-analysis-available`);
  },

  sendSaleAnalysis(id: number): Promise<ICustomersResponse> {
    return tciApiV2.patch(`/Customer/${id}/send-analysis`);
  },
};
