import {
  IBank,
  ICity,
  ICurrencyType,
  IGrowthType,
  IInsuranceCompany,
  ILimitRateType,
  IPaymentType,
  ITermOfSaleType,
} from '../../Api/Search/types';
import {globalsActionTypes} from './globalsTypes';

export const fetchCities = () => ({type: globalsActionTypes.FETCH_CITIES});
export const fetchCitiesFailure = () => ({type: globalsActionTypes.FETCH_CITIES_FAILURE});
export const fetchCitiesSuccess = (cities: ICity[]) => ({
  type: globalsActionTypes.FETCH_CITIES_SUCCESS,
  payload: cities,
});

export const fetchInsuranceCompanies = () => ({type: globalsActionTypes.FETCH_INSURANCE_COMPANIES});
export const fetchInsuranceCompaniesFailure = () => ({
  type: globalsActionTypes.FETCH_INSURANCE_COMPANIES_FAILURE,
});
export const fetchInsuranceCompaniesSuccess = (insuranceCompanies: IInsuranceCompany[]) => ({
  type: globalsActionTypes.FETCH_INSURANCE_COMPANIES_SUCCESS,
  payload: insuranceCompanies,
});

export const fetchBanks = () => ({type: globalsActionTypes.FETCH_BANKS});
export const fetchBanksFailure = () => ({
  type: globalsActionTypes.FETCH_BANKS_FAILURE,
});
export const fetchBanksSuccess = (banks: IBank[]) => ({
  type: globalsActionTypes.FETCH_BANKS_SUCCESS,
  payload: banks,
});

export const fetchGrowthTypes = () => ({type: globalsActionTypes.FETCH_GROWTH_TYPES});
export const fetchGrowthTypesFailure = () => ({
  type: globalsActionTypes.FETCH_GROWTH_TYPES_FAILURE,
});
export const fetchGrowthTypesSuccess = (growthTypes: IGrowthType[]) => ({
  type: globalsActionTypes.FETCH_GROWTH_TYPES_SUCCESS,
  payload: growthTypes,
});

export const fetchCurrencyTypes = () => ({type: globalsActionTypes.FETCH_CURRENCY_TYPES});
export const fetchCurrencyTypesFailure = () => ({
  type: globalsActionTypes.FETCH_CURRENCY_TYPES_FAILURE,
});
export const fetchCurrencyTypesSuccess = (currencyTypes: ICurrencyType[]) => ({
  type: globalsActionTypes.FETCH_CURRENCY_TYPES_SUCCESS,
  payload: currencyTypes,
});

export const fetchPaymentTypes = () => ({type: globalsActionTypes.FETCH_PAYMENT_TYPES});
export const fetchPaymentTypesFailure = () => ({
  type: globalsActionTypes.FETCH_PAYMENT_TYPES_FAILURE,
});
export const fetchPaymentTypesSuccess = (paymentTypes: IPaymentType[]) => ({
  type: globalsActionTypes.FETCH_PAYMENT_TYPES_SUCCESS,
  payload: paymentTypes,
});

export const fetchTermOfSaleTypes = () => ({type: globalsActionTypes.FETCH_TERM_OF_SALE_TYPES});
export const fetchTermOfSaleTypesFailure = () => ({
  type: globalsActionTypes.FETCH_TERM_OF_SALE_TYPES_FAILURE,
});
export const fetchTermOfSaleTypesSuccess = (termOfSaleTypes: ITermOfSaleType[]) => ({
  type: globalsActionTypes.FETCH_TERM_OF_SALE_TYPES_SUCCESS,
  payload: termOfSaleTypes,
});

export const fetchLimitRateTypes = () => ({type: globalsActionTypes.FETCH_LIMIT_RATE_TYPES});
export const fetchLimitRateTypesFailure = () => ({
  type: globalsActionTypes.FETCH_LIMIT_RATE_TYPES_FAILURE,
});
export const fetchLimitRateTypesSuccess = (limitRateTypes: ILimitRateType[]) => ({
  type: globalsActionTypes.FETCH_LIMIT_RATE_TYPES_SUCCESS,
  payload: limitRateTypes,
});
