import React, {FC} from 'react';
import {Col, Row} from 'antd';
import {CustomIcon} from '../../../../components/Icon';
import Text from '../../../../components/Text';
import CustomerInfoStatusContainer from '../../../Customer/CustomerDetail/CustomerInfoStatus/styles';

interface Props {
  className?: string;
  icon: string;
  text: string;
  onClick: () => void;
}

const SaleInfoStatus: FC<Props> = ({className, icon, text, onClick}) => {
  return (
    <CustomerInfoStatusContainer className={`cursor-pointer ${className || ''}`} onClick={onClick}>
      <Row justify="space-between" align="middle">
        <Col>
          <CustomIcon icon={icon} className="mr-big" />
          <Text>{text}</Text>
        </Col>

        <Col>
          <Text color="primary">Bilgileri Giriniz {'>'}</Text>
        </Col>
      </Row>
    </CustomerInfoStatusContainer>
  );
};

export default SaleInfoStatus;
