class LocalStorageService {
  constructor() {
    this.authTokenKey = 'TCI_AUTH';
    this.tokenType = 'Bearer';
  }

  getAuthToken() {
    return localStorage.getItem(this.authTokenKey);
  }

  setAuthToken(token) {
    localStorage.setItem(this.authTokenKey, `${this.tokenType} ${token}`);
  }

  removeAuthToken() {
    localStorage.removeItem(this.authTokenKey);
  }
}

export default new LocalStorageService();
