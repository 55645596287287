const paths = {
  default: '/',
  login: '/login',
  passwordRecovery: '/password-recovery',
  resetPassword: '/reset-password/:token',
  setPassword: '/set-password/:token',
  user: '/user',
  users: '/users',
  createUser: '/users/create',
  sales: '/sales',
  saleDetail: '/sale/detail/:taxNumber',
  customer: '/customer',
  customerDetail: '/customer/detail/:taxOffice/:taxNumber/:customerKindId',
  customerCreate: '/customer/create',
  customerCreateBulkList: '/create-bulks',
  customerCreateBulk: '/customer/create-bulk',
  customerInformation: '/customer/information/:taxOffice/:taxNumber',
  salesCreate: '/sales/create',

  getCustomerDetailPath(taxOffice: string, taxNumber: string | number, customerKindId: number) {
    return `/customer/detail/${taxOffice}/${taxNumber}/${customerKindId}`;
  },

  getSalesDetailPath(taxNumber: string | number) {
    return `/sale/detail/${taxNumber}`;
  },

  getCustomerInfoPath(taxOffice: string, taxNumber: string | number) {
    return `/customer/information/${taxOffice}/${taxNumber}`;
  },
};

export default paths;
