import styled from 'styled-components';
import {Image as ImageAntd} from 'antd';

// eslint-disable-next-line import/prefer-default-export
export const PreviewContainerStyles = styled.div`
  display: inline-block;
  width: 120px;
  padding: ${({theme}) => theme.main.padding}px;
  padding-top: 15px;
  border: 1px solid ${({theme}) => theme.colors.lightSmoke};

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Image = styled(ImageAntd)`
  border-radius: ${({theme}) => theme.main.borderRadius};
`;
