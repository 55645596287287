/* eslint-disable react/no-unstable-nested-components */
import React, {useState} from 'react';
import {Row, Col} from 'antd';
import Sider from '../../components/Layout/Sider';
// import UserInfo from './UserInfo';
import ResetPassword from './ResetPassword';
import {useAppSelector} from '../../hooks/useRedux';

const siderItems = [
  // {key: 'info', text: 'Hesap Bilgilerim', icon: 'user-circle', component: UserInfo},
  {key: 'password', text: 'Şifre Sıfırla', icon: 'lock', component: ResetPassword},
];

const UserDetail: React.VFC = () => {
  const [activeSiderKey, setActiveSiderKey] = useState(siderItems[0].key);
  const {user} = useAppSelector((state) => state.user);

  const getSiderItemByKey = (itemKey: string) => siderItems.find(({key}) => key === itemKey);
  const Component = getSiderItemByKey(activeSiderKey)?.component || ResetPassword;

  return (
    <Row gutter={40}>
      <Col>
        <Sider
          title="Merhaba"
          subTitle={user?.name}
          items={siderItems}
          activeKey={activeSiderKey}
          onChange={(itemKey) => setActiveSiderKey(itemKey)}
        />
      </Col>

      <Col flex={1}>
        <div style={{maxWidth: 600}}>
          <Component />
        </div>
      </Col>
    </Row>
  );
};

export default UserDetail;
