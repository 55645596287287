import styled from 'styled-components';
import Props from './types';

function getCssValue(maxWidth: string | number = '') {
  if (Number.isInteger(maxWidth)) return `${maxWidth}px`;
  return maxWidth;
}

export default styled.div<Props>`
  max-width: ${({theme, maxWidth}) =>
    getCssValue(maxWidth) || `${theme.main.limitedContentWidth}px`};
  margin: ${({margin}) => getCssValue(margin)};

  padding-top: ${({theme, verticalPadding}) => verticalPadding && `${theme.main.padding}px`};
  padding-bottom: ${({theme, verticalPadding}) => verticalPadding && `${theme.main.padding}px`};

  padding-left: ${({theme, horizontalPadding}) =>
    horizontalPadding && `${theme.pageContent.paddingHorizontal}px`};
  padding-right: ${({theme, horizontalPadding}) =>
    horizontalPadding && `${theme.pageContent.paddingHorizontal}px`};
`;
