import {ICustomerQuery} from '../../Api/Customer/types';

export const customerActionTypes = {
  SET_CUSTOMERS: 'SET_CUSTOMERS',
  SET_INVOICE_POPUPDATA: 'SET_INVOICE_POPUPDATA',
  SET_CUSTOMER_CREATE_QUERY_DATA: 'SET_CUSTOMER_CREATE_QUERY_DATA',
  RESET_CUSTOMER_CREATE_QUERY_DATA: 'RESET_CUSTOMER_CREATE_QUERY_DATA',
  SET_CUSTOMER_SALE_DATA: 'SET_CUSTOMER_SALE_DATA',
  SET_INSURANCE_RATING_DATA: 'SET_INSURANCE_RATING_DATA',
  SET_CUSTOMER_ACCOUNT: 'SET_CUSTOMER_ACCOUNT',
  SET_CUSTOMER_ID: 'SET_CUSTOMER_ID',
};

export interface ISetCustomerQuery {
  type: string;
  payload: ICustomerQuery;
}
