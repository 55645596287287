import React from 'react';
import {Divider} from 'antd';
import Card from '../Card';
import {getDateString} from '../../utils/date';
import {convertNumberDotSeperated} from '../../utils/string';

const CustomTooltip = ({active, payload, label, selectedKeys, isLabelDate}) => {
  if (active) {
    return (
      <Card className="shadow-hover">
        <p className="dark">{isLabelDate ? getDateString(label) : label}</p>

        {label && <Divider className="m" />}

        {payload &&
          payload.map(({value, name, dataKey, color}, idx) => (
            <div
              key={`tooltip-info-${idx}`}
              style={{color, opacity: selectedKeys.includes(dataKey) ? 1 : 0.2}}
            >
              <span className="bold">{`${name}:`}</span>
              <span>{` ${convertNumberDotSeperated(value)}`}</span>
            </div>
          ))}
      </Card>
    );
  }

  return null;
};

CustomTooltip.defaultProps = {
  active: false,
  payload: [],
  label: '',
  selectedKeys: [],
  isLabelDate: false,
};

export default CustomTooltip;
