/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const CustomerInfoContainer = styled.div`
  .main-title {
    color: ${({theme}) => theme.colors.primary};
    font-size: 48px;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    text-align: center;
    margin-bottom: 0px;
  }
  .section-head {
    display: flex;
    align-items: center;
    background: ${({theme}) => theme.colors.primaryFaded};
    border-radius: ${({theme}) => theme.main.borderRadius}px;
    padding: 10px 0px 10px 28px;
  }
  .section-title {
    color: ${({theme}) => theme.colors.primary};
    font-size: 24px;
    margin-bottom: 0px;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
  }
  .primary-text {
    color: ${({theme}) => theme.colors.primary};
  }
  .graph-box {
    height: auto;
    padding: 10px 40px;
    border: solid 1px #ededed;
    background-color: #fff;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .pl-20 {
    padding-left: 20px;
  }
  .download-icon {
    width: 24px;
    height: 24px;
    margin-bottom: 10px;
    color: ${({theme}) => theme.colors.primary};
  }
`;
