import React from 'react';
import Props from './types';
import CardStyles from './styles';
import Text from '../Text';

const Card: React.FC<Props> = ({
  children,
  className,
  style,
  color,
  borderBottomColor,
  borderLeftColor,
  background,
  shadow = false,
  hover = false,
  margin = true,
  padding = true,
  title = '',
  onClick,
  onMouseEnter,
  onMouseLeave,
  overflowX = 'hidden',
  overflowY = 'hidden',
}) => {
  return (
    <CardStyles
      className={className}
      style={style}
      color={color}
      borderBottomColor={borderBottomColor}
      borderLeftColor={borderLeftColor}
      background={background}
      shadow={shadow}
      hover={hover}
      margin={margin}
      padding={padding}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      overflowX={overflowX}
      overflowY={overflowY}
    >
      {title && (
        <Text type="h1" color="primary" bold>
          {title}
        </Text>
      )}
      {children}
    </CardStyles>
  );
};

export default Card;
