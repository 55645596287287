import React, {useEffect} from 'react';
import {Row, Spin} from 'antd';
import {useDispatch} from 'react-redux';
import ContentContainer from '../../../components/ContentContainer';
import Text from '../../../components/Text';
import {useAppSelector} from '../../../hooks/useRedux';
import {
  fetchCities,
  fetchCurrencyTypes,
  fetchPaymentTypes,
} from '../../../store/globals/globalsActions';
import {CustomerInfoContainer} from './style';
import GeneralInfo from './GeneralInfo';
import TradeRegistryInfo from './TradeRegistryInfo';
import InsuranceInfo from './InsuranceInfo';
import WarrantInfo from './WarrantInfo';
import WorkingConditionInfo from './WorkingConditionInfo';

const CustomerInfo = () => {
  const dispatch = useDispatch();

  const {isLoadingPaymentTypes, isLoadingCurrencyTypes} = useAppSelector((state) => state.globals);
  const {customerCreateQueryData: customer} = useAppSelector((state) => state.customer);

  useEffect(() => {
    dispatch(fetchCities());
    dispatch(fetchPaymentTypes());
    dispatch(fetchCurrencyTypes());
  }, []);

  return (
    <Spin spinning={isLoadingPaymentTypes || isLoadingCurrencyTypes}>
      <div id="print-pdf">
        <ContentContainer>
          <CustomerInfoContainer>
            <Row className="mb-big" align="middle" justify="center" gutter={20}>
              <div>
                <Text type="h2" className="main-title">
                  {customer?.title}
                </Text>
                <Text type="h2" className="main-title">
                  VKN : {customer?.taxId}{' '}
                  {/* <Icon className="download-icon" onClick={handlePdf} icon="file-download" /> */}
                </Text>
              </div>
            </Row>
            <Row gutter={[10, 40]}>
              <GeneralInfo />
              <TradeRegistryInfo />
              <WarrantInfo />
              <InsuranceInfo />
              <WorkingConditionInfo />
            </Row>
          </CustomerInfoContainer>
        </ContentContainer>
      </div>
    </Spin>
  );
};

export default CustomerInfo;
