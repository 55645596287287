import {priceIntoFloat} from './string';

/* eslint-disable import/prefer-default-export */
export function calculateCommisionRate(v: number) {
  return (100 * v) / 1000;
}

export const getNetWorkingCapital = (workingAssets: any, shortTermLiabilities: any) => {
  let val1: any = 0;
  let val2: any = 0;
  if (typeof workingAssets === 'string') {
    val1 = priceIntoFloat(workingAssets);
  }
  if (typeof shortTermLiabilities === 'string') {
    val2 = priceIntoFloat(shortTermLiabilities);
  }
  if ((val1 && val1 !== 0 && val1 !== '') || (val2 && val2 !== 0 && val2 !== '')) {
    const cal = val1 - val2;
    return cal.toFixed(4);
  }
  return 0;
};

export const getCariOn = (workingAssets: any, shortTermLiabilities: any) => {
  let val1: any = 0;
  let val2: any = 0;
  if (typeof workingAssets === 'string') {
    val1 = priceIntoFloat(workingAssets);
  }
  if (typeof shortTermLiabilities === 'string') {
    val2 = priceIntoFloat(shortTermLiabilities);
  }
  if (val1 && val1 !== 0 && val1 !== '' && val2 && val2 !== 0 && val2 !== '') {
    const cal = val1 / val2;
    return cal.toFixed(4);
  }
  return 0;
};

export const getFinancingRate = (
  ownFunds: any,
  shortTermLiabilities: any,
  longTermLiabilities: any,
) => {
  let val1: any = 0;
  let val2: any = 0;
  let val3: any = 0;
  if (typeof ownFunds === 'string') {
    val1 = priceIntoFloat(ownFunds);
  }
  if (typeof shortTermLiabilities === 'string') {
    val2 = priceIntoFloat(shortTermLiabilities);
  }
  if (typeof shortTermLiabilities === 'string') {
    val3 = priceIntoFloat(longTermLiabilities);
  }
  if (
    val1 &&
    val1 !== 0 &&
    val1 !== '' &&
    ((val2 && val2 !== 0 && val2 !== '') || (val3 && val3 !== 0 && val3 !== ''))
  ) {
    const cal = val1 / (val2 + val3);
    return cal.toFixed(4);
  }
  return 0;
};
