import styled from 'styled-components';

export const TitleContainer = styled.div`
  border-bottom: 2px solid ${({theme}) => theme.colors.lightSmoke};
  padding: ${({theme}) => theme.main.padding}px;
`;

export default styled.div`
  width: 400px;
  border: 2px solid ${({theme}) => theme.colors.lightSmoke};
  border-radius: ${({theme}) => theme.main.borderRadius}px;
`;
