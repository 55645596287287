import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {ConfigProvider, notification} from 'antd';
import moment from 'moment';
import store from './store';
import './types.d';
import 'moment/locale/tr'; // without this line it didn't work

import App from './App';

moment.locale('tr');
// TODO: move antd config
const validateMessages = {required: 'Bu alanı boş bırakmayınız!'};
notification.config({top: 100});

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ConfigProvider form={{validateMessages}}>
        <App />
      </ConfigProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);
