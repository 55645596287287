import React, {useEffect, useState} from 'react';
import {Col, notification, Row, Menu} from 'antd';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setCustomersList} from '../../../store/customer/customerActions';
import ContentContainer from '../../ContentContainer';
import {HeaderContainer, Logo} from './styles';
import AccountDropdown from '../../AccountDropdown';
import {useAppSelector} from '../../../hooks/useRedux';
import LogoPng from '../../../assets/logo.png';
import customerApi from '../../../Api/Customer';
import PATHS from '../../../routes/paths';
import StyledAutoComplete from '../../StyledAutoComplete';
import {ICustomer} from '../../../Api/Customer/types';

const {SubMenu} = Menu;

const Header: React.VFC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentPath = location.pathname;

  const [activeKey, setActiveKey] = useState('sub1');
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const {isLoggedIn, user} = useAppSelector((state) => state.user);

  const headerLayout = isLoggedIn ? 'space-between' : 'center';

  const searchCustomer = async (search: string) => {
    const {data} = await customerApi.fetchCustomers({search});
    setCustomers(data);
    dispatch(setCustomersList(data));
    return data.map((customer) => ({...customer, value: customer.id, label: customer.companyName}));
  };
  const onSelectCustomer = (customerId: number) => {
    const customer: any = customers.find(({id}) => id === customerId);

    if (!customer?.taxOffice || !customer.taxNumber || !customer.customerKindId) {
      notification.warning({
        message: 'Eksik Bilgi',
        description: 'Müşterinin VKN/TCKN ve Vergi Dairesi bilgilerini kontrol ediniz.',
      });
    } else {
      history.push(
        PATHS.getCustomerDetailPath(
          customer?.taxOffice,
          customer.taxNumber,
          customer.customerKindId,
        ),
      );
    }
  };

  useEffect(() => {
    if (currentPath === PATHS.sales || currentPath === PATHS.salesCreate) {
      setActiveKey('sub1');
    }
    if (currentPath === PATHS.customer || currentPath === PATHS.customerCreate) {
      setActiveKey('sub2');
    }
    if (currentPath === PATHS.users || currentPath === PATHS.createUser) {
      setActiveKey('sub3');
    }
    if (currentPath === PATHS.customerCreateBulkList) {
      setActiveKey('7');
    }
  }, [location.pathname]);

  const checkRole = (roleName: string) => {
    return user.role.includes(roleName);
  };

  return (
    <HeaderContainer>
      <ContentContainer className="h-100" verticalPadding={false} margin="auto">
        <Row className="h-100" justify={headerLayout} align="middle" gutter={20}>
          <Col>
            <Link to={PATHS.default}>
              <Logo src={LogoPng} alt="logo" />
            </Link>
          </Col>

          {isLoggedIn && (
            <>
              <Col>
                <div style={{display: 'flex'}}>
                  <Menu
                    onClick={(e) => setActiveKey(e.key)}
                    mode="horizontal"
                    selectedKeys={[activeKey]}
                    style={{width: 680, height: 65, marginTop: 21, fontSize: '16px'}}
                  >
                    {(checkRole('MANAGER') || checkRole('SELLER') || checkRole('CREDITOR')) && (
                      <SubMenu key="sub1" title="Saha Analizleri">
                        <Menu.Item key="1">
                          <Link to={PATHS.sales}>Saha Analizlerim</Link>
                        </Menu.Item>
                        <Menu.Item key="2">
                          <Link to={PATHS.salesCreate}>Saha Analizi Başlat</Link>
                        </Menu.Item>
                      </SubMenu>
                    )}
                    {(checkRole('MANAGER') || checkRole('CREDITOR')) && (
                      <SubMenu key="sub2" title="Firma Analizleri">
                        <Menu.Item key="3">
                          <Link to={PATHS.customer}>Firma Analizlerim</Link>
                        </Menu.Item>
                        <Menu.Item key="4">
                          <Link to={PATHS.customerCreate}>Firma Analizi Başlat</Link>
                        </Menu.Item>
                      </SubMenu>
                    )}
                    {(checkRole('MANAGER') || checkRole('SELLER') || checkRole('CREDITOR')) && (
                      <Menu.Item key="7">
                        <Link to={PATHS.customerCreateBulkList}>Toplu Liste Yükle</Link>
                      </Menu.Item>
                    )}
                  </Menu>
                </div>
              </Col>

              <Col span={4}>
                <StyledAutoComplete
                  className="w-100"
                  placeholder="Müşteri ara…"
                  value={null}
                  showArrow={false}
                  onSelect={({value}: {value: number}) => onSelectCustomer(value)}
                  fetchOptions={searchCustomer}
                />
              </Col>

              <Col>
                <AccountDropdown />
              </Col>
            </>
          )}
        </Row>
      </ContentContainer>
    </HeaderContainer>
  );
};

export default Header;
