/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import HttpService from '../services/HttpService';

const apuUrl = process.env.REACT_APP_API_URL;
const urlExtension = 'api/v1';
const urlExtensionV2 = 'api/v2';

export const baseURL = apuUrl
  ? `${apuUrl}/${urlExtension}`
  : `https://service-${window.location.hostname}/${urlExtension}`;

export const tciApi = new HttpService({
  baseURL,
});

export const baseURLV2 = apuUrl
  ? `${apuUrl}/${urlExtensionV2}`
  : `https://service-${window.location.hostname}/${urlExtensionV2}`;

export const tciApiV2 = new HttpService({
  baseURL: baseURLV2,
});
