import React, {useState} from 'react';
import {Row, Col, Form, Input, notification} from 'antd';
import Card from '../../../components/Card';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
// import LogoFonRadar from '../../../assets/logo-big.png';
import userApi from '../../../Api/User';
import Icon from '../../../components/Icon';

const PasswordRecovery: React.VFC = () => {
  const [recoveredEmail, setRecoveredEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = async ({email}: {email: string}) => {
    setLoading(true);
    try {
      await userApi.sendResetPasswordEmail(email);
      notification.success({message: 'Lütfen mailinizi kontrol ediniz!'});
      setRecoveredEmail(email);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (recoveredEmail) {
    return (
      <div>
        <Text type="h2" align="center" bold>
          Şifremi Unuttum
        </Text>
        <Row className="mt-big" justify="center" align="middle" gutter={20}>
          <Col>
            <Icon icon="check-circle" size="3x" color="green" />
          </Col>
          <Col>
            <Text className="m-0">
              <b>{recoveredEmail}</b> adresinize bir email gönderdik.
            </Text>
            <Text className="m-0" type="h4">
              Lütfen epostanızda gelen kutusunu veya spam bölümünü kontrol ediniz.
            </Text>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <Row justify="center">
      <Col xs={24} md={12} lg={8}>
        <Card>
          <Text type="h2" align="center" bold>
            Şifremi Unuttum
          </Text>
          <Form name="passwordRecovery" onFinish={onSubmit}>
            <Form.Item
              name="email"
              rules={[
                {type: 'email', message: 'Lütfen geçerli bir e-posta adresi giriniz!'},
                {
                  required: true,
                  message: 'Lütfen email giriniz!',
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item>
              <Button
                className="mt"
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
                block
              >
                Gönder
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default PasswordRecovery;
