import React, {useEffect, useState, VFC} from 'react';
import {Button, Input, Row, Col, Checkbox} from 'antd';
import ContentContainer from '../../../components/ContentContainer';
import Text from '../../../components/Text';
import Table from '../../../components/Table';
import userApi from '../../../Api/User';
import {ICustomer} from '../../../Api/Customer/types';
import Icon from '../../../components/Icon';
import {IUSERROLE, USERROLE} from '../../../constants';
import {getUserRole} from '../../../utils/string';

const UserList: VFC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<ICustomer[]>([]);
  const [usersRoles, setUsersRoles] = useState<any>();
  const [usersCount, setUsersCount] = useState(0);
  const [pagingPage, setPagingPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [deleteKey, setDeleteKey] = useState('');
  const [editKey, setEditKey] = useState(-1);
  const [roleSelect, setRole] = useState<any>([]);

  const handleDelete = (value: any) => {
    setDeleteKey(value);
  };

  const fetchusers = async (search = '', page = 1) => {
    try {
      setIsLoading(true);
      const usersData: any = await userApi.fetchUsers({search, page});
      setUsers(usersData);
      setUsersCount(usersData.length);
    } finally {
      setIsLoading(false);
    }
  };

  const deletConfirm = async () => {
    try {
      setIsLoading(true);
      const deleteResponse: any = await userApi.deleteUser(deleteKey);
      if (deleteResponse) fetchusers();
    } finally {
      setIsLoading(false);
    }
    console.log('Delete record');
  };

  const renderCol = (value: any) => (
    <div style={{display: 'block'}} role="button" tabIndex={-1} aria-hidden="true">
      {value}
    </div>
  );

  const handleSave = async (id: any) => {
    const userRes = await userApi.updateUserRole(id, roleSelect.join(', '));
    if (userRes) {
      fetchusers();
      setRole([]);
      setEditKey(-1);
    }
  };

  const fetchRoles = async () => {
    const userRoles = await userApi.fetchUserRoles();
    setUsersRoles(userRoles);
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleEdit = (value: any, index: any) => {
    setEditKey(index);
    if (value.includes(',')) {
      setRole(value.split(','));
    } else {
      setRole([value]);
    }
  };

  const renderRoleCol = (value: IUSERROLE, record: any, index: any) => (
    <div style={{display: 'block'}} role="button" tabIndex={-1} aria-hidden="true">
      {editKey === index ? (
        <>
          <Checkbox.Group value={roleSelect} onChange={(e: any) => setRole(e)}>
            <Row>
              {usersRoles?.length > 0 &&
                usersRoles.map((role: IUSERROLE, i: any) => (
                  <Col span={15} key={i}>
                    <Checkbox value={role} style={{lineHeight: '32px'}}>
                      {USERROLE[role]}
                    </Checkbox>
                  </Col>
                ))}
            </Row>
          </Checkbox.Group>
          {editKey === index && roleSelect.length > 0 && (
            <div className="delete-popup" style={{left: '0px', top: '155px', height: '110px'}}>
              <Text type="h5">Kullanıcının yetkileri güncellenecektir, emin misiniz?</Text>
              <div className="desc-btns">
                <Button
                  onClick={() => {
                    setEditKey(-1);
                    setRole([]);
                  }}
                  type="default"
                  className="mr"
                >
                  Vazgeç
                </Button>
                <Button onClick={() => handleSave(record.id)} type="primary">
                  Evet
                </Button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          role="button"
          tabIndex={-1}
          aria-hidden="true"
          onClick={() => handleEdit(value, index)}
        >
          {value.includes(',') ? getUserRole(value) : USERROLE[value]}
        </div>
      )}
    </div>
  );

  const tableCols = [
    {
      title: 'İsim - Soyisim',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (value: any) => renderCol(value),
    },
    {
      title: 'E-posta Adresi',
      dataIndex: 'email',
      key: 'email',
      render: (value: any) => renderCol(value),
    },
    {
      title: 'Kullanıcı Yetkileri',
      dataIndex: 'role',
      key: 'role',
      render: (value: any, record: any, index: any) => renderRoleCol(value, record, index),
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (value: string) => (
        <div style={{zIndex: 10}}>
          <div role="button" aria-hidden="true" tabIndex={0} onClick={() => handleDelete(value)}>
            <Icon size="lg" color="red" icon="trash-alt" />
          </div>
          {deleteKey === value && (
            <div className="delete-popup">
              <Text type="h5">Satırı silmek istediğinden emin misin?</Text>
              <div className="desc-btns">
                <Button onClick={() => setDeleteKey('')} type="default" className="mr">
                  Vazgeç
                </Button>
                <Button onClick={deletConfirm} style={{backgroundColor: 'red'}} type="primary">
                  Evet Sil
                </Button>
              </div>
            </div>
          )}
        </div>
      ),
    },
  ];

  const onChangePage = (page: number) => {
    setPagingPage(page);
    fetchusers(searchText, page);
  };

  useEffect(() => {
    fetchusers();
  }, []);

  return (
    <ContentContainer maxWidth={1125}>
      <Text type="h2">Kullanıcı Listesi</Text>
      <div className="search-input">
        <Input
          style={{marginTop: '1em', marginBottom: '2em'}}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Kullanıcı ara"
          prefix={<Icon size="lg" color="defaultSmoke" icon="search" />}
          suffix={<Button type="primary">Ara</Button>}
        />
      </div>

      <Table
        className="mt cursor-pointer"
        rowKey="id"
        dataSource={users}
        pagination={{
          total: usersCount,
          current: pagingPage,
          onChange: onChangePage,
        }}
        columns={tableCols}
        loading={isLoading}
      />
    </ContentContainer>
  );
};

export default UserList;
