import React, {useEffect, useState, VFC} from 'react';
import {Button, Input, notification} from 'antd';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import ContentContainer from '../../../components/ContentContainer';
import Text from '../../../components/Text';
import Table from '../../../components/Table';
import PATHS from '../../../routes/paths';
import saleApi from '../../../Api/Sale';
import {ICustomer} from '../../../Api/Customer/types';
import Icon from '../../../components/Icon';
import {setCustomerAccount, setCustomerSaleData} from '../../../store/customer/customerActions';

const SalesList: VFC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(true);
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [customerCount, setCustomerCount] = useState(0);
  const [pagingPage, setPagingPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [deleteKey, setDeleteKey] = useState('');

  const handleDelete = (index: any) => {
    setDeleteKey(index);
  };

  const fetchCustomerSale = async (search = '', page = 1) => {
    try {
      setIsLoadingCustomers(true);
      const customersData = await saleApi.fetchCustomerSales({search, page});
      setCustomers(customersData.data);
      setCustomerCount(customersData.totalDataCount);
    } finally {
      setIsLoadingCustomers(false);
    }
  };

  const deletConfirm = async () => {
    try {
      setIsLoadingCustomers(true);
      const deleteResponse = await saleApi.deleteCustomerSale(deleteKey);
      if (deleteResponse) {
        setIsLoadingCustomers(false);
        fetchCustomerSale();
        setDeleteKey('');
      }
    } finally {
      setIsLoadingCustomers(false);
      setDeleteKey('');
    }
  };

  const routeToCustomerSaleDetail = (taxNumber: number, customer: any) => {
    if (!taxNumber) {
      notification.warning({
        message: 'Eksik Bilgi',
        description: 'Müşterinin VKN/TCKN ve Vergi Dairesi bilgilerini kontrol ediniz.',
      });
    } else {
      dispatch(setCustomerSaleData(customer));
      history.push(PATHS.getSalesDetailPath(taxNumber));
    }
  };

  const renderCol = (value: any, record: any) => (
    <div
      style={{display: 'block'}}
      role="button"
      tabIndex={-1}
      aria-hidden="true"
      onClick={() => routeToCustomerSaleDetail(record.taxNumber, record)}
    >
      {value || '-'}
    </div>
  );

  const routeToCustomerDetail = (taxOffice: string, taxNumber: number, customerKindId: number) => {
    if (!taxOffice || !taxNumber) {
      notification.warning({
        message: 'Eksik Bilgi',
        description: 'Sahanin VKN/TCKN ve Vergi Dairesi bilgilerini kontrol ediniz.',
      });
    } else {
      history.push(PATHS.getCustomerDetailPath(taxOffice, taxNumber, customerKindId));
    }
  };

  const handleFirma = (record: any) => {
    if (record.status === 'Firma Analizi Yapılıyor') {
      routeToCustomerDetail(record.taxAdministration, record.taxNumber, 1);
      return;
    }
    dispatch(setCustomerAccount(record));
    history.push(`${PATHS.customerCreate}?id=${record.accountId}`);
  };

  const tableCols = [
    {
      title: 'Müşteri Adı',
      dataIndex: 'accountName',
      key: 'accountName',
      render: (value: any, record: any) => renderCol(value, record),
    },
    {
      title: 'Müşteri Kodu',
      dataIndex: 'accountCode',
      key: 'accountCode',
      render: (value: any, record: any) => renderCol(value, record),
    },
    {
      title: 'Vergi Kimlik No',
      dataIndex: 'taxNumber',
      key: 'taxNumber',
      render: (value: any, record: any) => renderCol(value, record),
    },
    {
      title: 'Vergi Dairesi',
      dataIndex: 'taxAdministration',
      key: 'taxAdministration',
      render: (value: any, record: any) => renderCol(value, record),
    },
    {
      title: 'Şehir',
      dataIndex: 'province',
      key: 'province',
      render: (value: any, record: any) => renderCol(value, record),
    },
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      render: (value: any, record: any) => renderCol(value, record),
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (value: string, record: any) => (
        <div style={{zIndex: 10, display: 'flex'}}>
          <div role="button" aria-hidden="true" tabIndex={0} onClick={() => handleDelete(value)}>
            <Icon size="lg" color="red" icon="trash-alt" />
          </div>
          {deleteKey === value && (
            <div className="delete-popup">
              <Text type="h5">Saha Analizi silinecektir, emin misiniz?</Text>
              <div className="desc-btns">
                <Button onClick={() => setDeleteKey('')} type="default" className="mr">
                  Vazgeç
                </Button>
                <Button onClick={deletConfirm} style={{backgroundColor: 'red'}} type="primary">
                  Evet Sil
                </Button>
              </div>
            </div>
          )}
          {record.accountId && (
            <div
              style={{marginLeft: 20}}
              role="button"
              aria-hidden="true"
              tabIndex={0}
              onClick={() => handleFirma(record)}
            >
              <Icon style={{fontSize: '1.6em'}} color="primary" icon="home" />
              <Icon
                style={{position: 'absolute', left: 69, top: 11}}
                size="1x"
                color="primary"
                icon="plus-circle"
              />
            </div>
          )}
        </div>
      ),
    },
  ];

  const searchCustomer = (text: string) => {
    setSearchText(text);
    setPagingPage(1);
    fetchCustomerSale(text, 1);
  };
  const onChangePage = (page: number) => {
    setPagingPage(page);
    fetchCustomerSale(searchText, page);
  };

  useEffect(() => {
    fetchCustomerSale();
  }, []);

  return (
    <ContentContainer maxWidth={1125}>
      <Text type="h2">Saha Analizlerim</Text>
      <div className="search-input">
        <Input
          style={{marginTop: '1em', marginBottom: '2em'}}
          onChange={(e) => searchCustomer(e.target.value)}
          placeholder="Müşteri ara"
          prefix={<Icon size="lg" color="defaultSmoke" icon="search" />}
          suffix={<Button type="primary">Ara</Button>}
        />
      </div>

      <Table
        className="mt cursor-pointer"
        rowKey="id"
        dataSource={customers}
        pagination={{
          total: customerCount,
          current: pagingPage,
          onChange: onChangePage,
        }}
        columns={tableCols}
        loading={isLoadingCustomers}
      />
    </ContentContainer>
  );
};

export default SalesList;
