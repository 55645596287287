// eslint-disable-next-line import/prefer-default-export
export const customerSaleData = {
  taxNumber: '',
  customerRepresentative: '',
  formDate: null,
  mainActivitySubject: '',
  companyPhone: '',
  requestedProduct: '',
  contactFullname: '',
  contactTitle: '',
  contactPhoneNumber: '',
  contactEmailAddress: '',
  expectedSalesTon: 0,
  expectedSalesTL: 0,
  requestedLimitAmount: 0,
  requestedMaturityDate: 0,
  paymentMethodParameterId: 0,
  domesticEndorsement: 0,
  abroadEndorsement: 0,
  ownProductionFacility: false,
  customerGroupAndSectorDynamics: '',
  continuingProjects: '',
  hasBigInvestment: false,
  groupCompanyName: '',
  brandName: '',
  sectorInformationOfPartners: '',
  productUsageCapacity: '',
  bizimDisimizdaKimlerdenUrunTeminEdiyor: '',
  makineParkuruHakkindaBilgi: '',
  talepEttigiUrunDisindaBirUrunSatilabilirMi: false,
  baskaSektorlerdeFaaliyetiVarsaIsmi: '',
  firmaBizeHangiKanallardanUlasti: '',
  domesticEndorsement2: 0,
  taxAdministration: '',
  province: '',
  note: '',
};
