import styled from 'styled-components';

const height = '40px';

export default styled.div`
  position: relative;

  .ant-select {
    .ant-select-selector {
      border: none;
      background-color: #f1f9ff !important;
      height: ${height};

      & .ant-select-selection-placeholder {
        line-height: ${height} !important;
      }
    }
    input {
      height: ${height} !important;
    }
  }
`;

export const SearchIconContainer = styled.div`
  z-index: 10;
  position: absolute;
  right: -20px;

  height: 100%;
  width: ${height};
  padding: 10px;

  border-radius: 50%;
  background: ${({theme}) => theme.colors.primary};
`;
