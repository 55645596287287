// Use mobile values for < 576px

const theme = {
  main: {
    margin: 7,
    marginBig: 15,
    padding: 7,
    paddingBig: 15,

    textFontSize: 'initial',
    h1FontSize: '6.5vw',
    h2FontSize: '5vw',
    h3FontSize: '4vw',
    h4FontSize: '3.5vw',
    h5FontSize: '3vw',
    hugeFontSize: '15vw',
    bigFontSize: '10vw',
    largeFontSize: '9vw',
  },
  pageContent: {
    paddingHorizontal: 10,
  },
};

export default theme;
