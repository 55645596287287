import * as FileSaver from 'file-saver';
import {convertFileToAntdFile} from '../../../utils/file';
import {IPopupData} from './types';

// eslint-disable-next-line import/prefer-default-export
export const columnsEnum: any = {
  'Müşteri Kodu': 'customerCode',
  'Müşteri Adı': 'customerName',
  'Teminat Mektubu Tutarı': 'amount',
  'Para Birimi': 'currency',
  Birim: 'currency',
  'Kesin/Geçici': 'temporaryOrPermanent',
  'Banka Adı': 'bankName',
  Vade: 'termDate',
  'Talep Edilen Tutar': 'requestedAmount',
  'Onaylanan Tutar': 'acceptedAmount',
  'Karar Tarihi': 'decisionDate',
  'Limit Geçerlilik Tarihi': 'validityDate',
  'Sigorta Adı': 'insuranceCompanyName',
  'DRA Notu': 'dRARank',
  Notlar: 'evaluationDescription',
  'Değerlendirme Yorumu 1': 'comment1',
  'Değerlendirme Yorumu 2': 'comment2',
  'Değerlendirme Yorumu 3': 'comment3',
  'İpotek Tutarı': 'amount',
  Açıklama: 'description',
  'Tanımlı DBS Limit': 'definedLimitValue',
  'Kullanılan DBS Kredisi': 'usedDBSCredit',
  'Kullanılabilir DBS Limiti': 'availableDBSCredit',
  'Yüklü Fatura Tutarı': 'installedInvoicesAmount',
  'Akreditif Tutarı': 'akreditifTutari',
  'Faktöring Limit Tutarı': 'factoringLimitAmount',
  'Faktöring Adı': 'factoringCompanyName',
};

export const generateDataObject = (value: any, columns: any) => {
  const data = columns.reduce((newData: any, col: any, index: number) => {
    if (col === '') {
      return newData;
    }
    const newObj = {
      [`${columnsEnum[`${col}`]}`]: col === 'Müşteri Kodu' ? `${value[index]}` : value[index],
    };
    return {...newData, ...newObj};
  }, {});
  return data;
};

export const BulkUploadItems: IPopupData[] = [
  {
    key: 'guarantee',
    text: 'Toplu Teminat Mektubu Yükle',
    description: '2019, Onur Sencer Ar. , 125.232,00, TL, Ziraat Bankası, 6/8/2022',
    columns: [
      'Müşteri Kodu',
      'Müşteri Adı',
      'Teminat Mektubu Tutarı',
      'Para Birimi',
      'Kesin/Geçici',
      'Banka Adı',
      'Vade',
      '',
    ],
  },
  {
    key: 'insurance',
    text: 'Toplu Alacak Sigortasi Yükle',
    description: '',
    columns: [
      'Müşteri Kodu',
      'Müşteri Adı',
      'Talep Edilen Tutar',
      'Onaylanan Tutar',
      'Para Birimi',
      'Karar Tarihi',
      'Limit Geçerlilik Tarihi',
      'Sigorta Adı',
      'DRA Notu',
      'Değerlendirme Yorumu 1',
      'Değerlendirme Yorumu 2',
      'Değerlendirme Yorumu 3',
      '',
    ],
  },
  {
    key: 'dbs',
    text: 'Toplu DBS Yükle',
    description: '',
    columns: [
      'Müşteri Kodu',
      'Müşteri Adı',
      'Banka Adı',
      'Para Birimi',
      'Tanımlı DBS Limit',
      'Kullanılan DBS Kredisi',
      'Kullanılabilir DBS Limiti',
      'Yüklü Fatura Tutarı',
      '',
    ],
  },
  {
    key: 'lien',
    text: 'Toplu İpotek Yükle',
    description: '',
    columns: ['Müşteri Kodu', 'Müşteri Adı', 'İpotek Tutarı', 'Para Birimi', 'Açıklama', ''],
  },
  {
    key: 'akreditif',
    text: 'Toplu Akreditif Yükle',
    description: '',
    columns: [
      'Müşteri Kodu',
      'Müşteri Adı',
      'Akreditif Limit Tutarı',
      'Akreditif Risk Tutarı',
      'Birim',
      'Akreditif Geçerlilik Tarihi',
      'Açıklama',
      '',
    ],
  },
  {
    key: 'factoring',
    text: 'Toplu Faktoring Yükle',
    description: '',
    columns: [
      'Müşteri Kodu',
      'Müşteri Adı',
      'Faktoring Garanti Limiti',
      'Faktoring Risk Tutarı',
      'Birim',
      'Faktoring Adı',
      'Muhabir Adı',
      '',
    ],
  },
  {
    key: 'temliki',
    text: 'Alacak Temliki Yükle',
    description: '',
    columns: [
      'Müşteri Kodu',
      'Müşteri Adı',
      'Alacak Temliki Tutarı',
      'Birim',
      'Kurum Adı',
      'Temlik Detayları',
      '',
    ],
  },
  {
    key: 'diğer_teminat',
    text: 'Diğer Teminat Yükle',
    description: '',
    columns: ['Müşteri Kodu', 'Müşteri Adı', 'Tutar', 'Para Birimi', 'Teminat Adı', 'Açıklama', ''],
  },
];

export const BulkUploadHistoryItems: IPopupData[] = [
  {
    key: 'endorsement',
    text: 'Toplu Vade Ciro Tablosu Yükle',
    columns: [
      'ERP Kodu',
      'Firma Adı',
      '2021 (YTD Ciro)',
      '2020 Ciro',
      '2019 Ciro',
      '2018 Ciro',
      'Birim',
      'Vade',
      '',
    ],
  },
  {
    key: 'risk',
    text: 'Toplu Alacak Risk Tablosu Yükle',
    columns: [
      'ERP Kodu',
      'Firma Adı',
      'Açık Hesap Bakiyesi',
      'Vadesi Gelmemiş Çek',
      'Toplam Alacak',
      'Bekleyen Sipariş',
      'Para Birimi',
      '',
    ],
  },
];

export const generateObjects = (_currentSheet: any) => {
  const rows: any = Object.values(_currentSheet);
  const keys = rows[0];
  const result = [];
  for (let i = 1; i < rows.length; i += 1) {
    const row = rows[i];
    result.push(Object.fromEntries(keys.map((_: any, j: any) => [keys[j], row[j]])));
  }
  return result;
};

export const blobToFile = (theBlob: any, fileName: string): File => {
  const fileNew = convertFileToAntdFile(
    new File([theBlob], fileName, {lastModified: new Date().getTime()}),
  );
  console.log(fileNew, fileNew.originFileObj, 'fileNew');
  return fileNew;
};

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const b64toBlob = (b64Data: any, sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: fileType});
  return blob;
};

export const downloadFileResponse = (response?: any, fileName?: string) => {
  const blob = b64toBlob(response);
  FileSaver.saveAs(blob, `${fileName}`);
};
