import React, {FC, useEffect, useState} from 'react';
import {Select, Drawer, Form, Input} from 'antd';
import {useLocation} from 'react-router-dom';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import ContentContainer from '../../../../components/ContentContainer';
import TaxData from './TaxData';
import customerApi from '../../../../Api/Customer';
import searchApi from '../../../../Api/Search';
import {ITaxOffice} from '../../../../Api/Search/types';
import {ICustomerCreateComponent} from '../types';
import {ICustomerQuery} from '../../../../Api/Customer/types';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useRedux';
import {setCustomerAccount, setCustomerQueryData} from '../../../../store/customer/customerActions';
import {fetchCities} from '../../../../store/globals/globalsActions';

const {Option} = Select;

const GeneralInfo: FC<ICustomerCreateComponent> = ({onSubmit, onCancel}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const params = new URLSearchParams(useLocation().search);

  const [taxOffices, setTaxOffices] = useState<ITaxOffice[]>([]);
  const [isLoadingtaxOffices, setIsLoadingTaxOffices] = useState(false);
  const [isLoadingQueryCustomer, setIsLoadingQueryCustomer] = useState(false);
  const [isInfoDrawerVisible, setIsInfoDrawerVisible] = useState(false);

  const [province, setProvince] = useState<number>();
  const [customerData, setCustomerData] = useState<ICustomerQuery | any>(); // TODO: any!
  const [isCustomerQueryDataSaved, setIsCustomerQueryDataSaved] = useState(false);
  const {cities, isLoadingCities} = useAppSelector((state) => state.globals);
  const customerAccount: any = useAppSelector((state) => state.customer.customerAccount);

  const toggleDrawer = () => setIsInfoDrawerVisible((isVisible) => !isVisible);

  const fetchTaxOffices = async (provinceCode: number) => {
    try {
      setIsLoadingTaxOffices(true);
      const taxOfficesData = await searchApi.fetchTaxOffices(provinceCode);
      setTaxOffices(taxOfficesData);
    } catch {
      setTaxOffices([]);
    } finally {
      setIsLoadingTaxOffices(false);
    }
  };

  const queryCustomer = async ({
    id,
    taxOffice,
    customerKindId,
    accountCode,
  }: {
    id: number;
    taxOffice: number;
    customerKindId: number;
    accountCode: string;
  }) => {
    try {
      setIsLoadingQueryCustomer(true);
      const taxOfficeData = taxOffices.find(({value}) => value === taxOffice);
      const customer = await customerApi.queryCustomer(
        id.toString(),
        taxOfficeData?.label,
        false,
        customerKindId,
        accountCode,
      );
      setCustomerData({...customer, customerKindId, accountCode});
      if (customerAccount) {
        dispatch(setCustomerQueryData({...customer, ...customerAccount, accountCode}));
      }
      setIsInfoDrawerVisible(true);
    } finally {
      setIsLoadingQueryCustomer(false);
    }
  };

  const onChangeProvince = (provinceCode: number) => {
    setProvince(provinceCode);
    fetchTaxOffices(provinceCode);
    form.setFieldsValue({taxOffice: undefined});
  };
  const saveTaxData = () => {
    setIsCustomerQueryDataSaved(true);
    setIsInfoDrawerVisible(false);
  };
  const handleOnSubmit = () => {
    dispatch(setCustomerQueryData(customerData));
    onSubmit();
  };

  useEffect(() => {
    dispatch(fetchCities());
  }, []);

  useEffect(() => {
    if (cities && cities.length > 0 && customerAccount && customerAccount.province) {
      const provinceData: any = cities.find(
        (value: any) => value.label === customerAccount.province,
      );
      if (provinceData) {
        form.setFieldsValue({
          id: customerAccount.taxNumber,
          province: provinceData,
          customerKindId: 1,
        });
        setProvince(provinceData.value);
        fetchTaxOffices(provinceData.value);
      } else {
        form.setFieldsValue({
          id: customerAccount.taxNumber,
          customerKindId: 1,
        });
      }
    } else {
      form.resetFields();
    }
  }, [cities]);

  useEffect(() => {
    if (!params.get('id')) {
      form.resetFields();
      dispatch(setCustomerAccount(''));
    }
  }, [params.get('id')]);

  useEffect(() => {
    if (customerAccount && customerAccount.province && customerAccount.taxNumber) {
      const taxOfficesData: any = taxOffices.find((value: any) =>
        value.label.toLowerCase().includes(customerAccount.taxAdministration.toLowerCase()),
      );
      if (taxOfficesData) {
        if (!customerData && !isLoadingQueryCustomer) {
          queryCustomer({
            id: customerAccount.taxNumber,
            taxOffice: taxOfficesData.value,
            customerKindId: 1,
            accountCode: customerAccount.accountCode,
          });
        }
        form.setFieldsValue({taxOffice: taxOfficesData.value});
      }
    }
  }, [taxOffices]);

  return (
    <div>
      <Text type="h2" bold>
        Temel Bilgiler{' '}
      </Text>
      <ContentContainer maxWidth={isCustomerQueryDataSaved ? 600 : 400}>
        {isCustomerQueryDataSaved ? (
          <TaxData
            accountId={customerData?.accountId}
            title={customerData?.title}
            taxId={customerData?.taxId}
            taxAdministration={customerData?.taxAdministration}
            address={customerData?.address}
            startDate={customerData?.startDate}
            operationDetail={customerData?.operationDetail}
            profits={customerData?.profits || []}
            onSubmit={handleOnSubmit}
            onCancel={onCancel}
          />
        ) : (
          <>
            <Form form={form} layout="vertical" requiredMark={false} onFinish={queryCustomer}>
              <Form.Item name="accountCode" label="Müşteri Kodu">
                <Input type="text" />
              </Form.Item>

              <Form.Item
                hasFeedback
                name="id"
                label="VKN / TCKN"
                rules={[
                  {
                    validator: (_, value) =>
                      value?.toString().length === 11 || value?.toString().length === 10
                        ? Promise.resolve()
                        : Promise.reject(new Error('Geçerlı bir VKN / TCKN giriniz!')),
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>

              <Form.Item name="province" label="Şehir" rules={[{required: true}]}>
                <Select
                  placeholder="örn. İstanbul"
                  optionFilterProp="label"
                  options={cities}
                  disabled={isLoadingCities}
                  loading={isLoadingCities}
                  showSearch
                  onChange={onChangeProvince}
                />
              </Form.Item>

              <Form.Item name="taxOffice" label="Vergi Dairesi" rules={[{required: true}]}>
                <Select
                  options={taxOffices}
                  disabled={!province}
                  loading={isLoadingtaxOffices}
                  optionFilterProp="label"
                  autoClearSearchValue
                  showSearch
                />
              </Form.Item>

              <Form.Item name="customerKindId" label="Firma Türü" rules={[{required: true}]}>
                <Select
                  disabled={customerAccount && customerAccount.taxNumber}
                  autoClearSearchValue
                >
                  <Option value={1}>Müşteri</Option>
                  <Option value={2}>Rakip</Option>
                  <Option value={3}>Tedarikçi</Option>
                  <Option value={4}>Diğer</Option>
                </Select>
              </Form.Item>

              <Form.Item className="m-0">
                <Button
                  className="mt"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isLoadingQueryCustomer}
                  block
                >
                  Sorgula
                </Button>
              </Form.Item>
            </Form>

            <Drawer
              title="Vergi Levhası"
              width={600}
              visible={isInfoDrawerVisible}
              onClose={toggleDrawer}
            >
              <TaxData
                accountId={customerData?.accountId}
                title={customerData?.title}
                taxId={customerData?.taxId}
                taxAdministration={customerData?.taxAdministration}
                address={customerData?.address}
                startDate={customerData?.startDate}
                operationDetail={customerData?.operationDetail}
                profits={customerData?.profits || []}
                onSubmit={saveTaxData}
                onCancel={toggleDrawer}
              />
            </Drawer>
          </>
        )}
      </ContentContainer>
    </div>
  );
};

export default GeneralInfo;
