import {UserCredentials, userActionTypes, DefaultUserAction} from './userTypes';
import {IUser} from '../../Api/User/types';

// LOGIN
export const autoLogin = (): DefaultUserAction => ({type: userActionTypes.AUTO_LOGIN});
export const login = (userCredentials: UserCredentials): DefaultUserAction => ({
  type: userActionTypes.LOGIN,
  payload: userCredentials,
});
export const loginSuccess = (user: IUser): DefaultUserAction => ({
  type: userActionTypes.LOGIN_SUCCESS,
  payload: user,
});
export const loginFailure = (): DefaultUserAction => ({type: userActionTypes.LOGIN_FAILURE});

export const logout = (): DefaultUserAction => ({type: userActionTypes.LOGOUT});
export const logoutSuccess = (): DefaultUserAction => ({type: userActionTypes.LOGOUT_SUCCESS});
export const logoutFailure = (): DefaultUserAction => ({type: userActionTypes.LOGOUT_FAILURE});

// FETCH USER
export const fetchUser = (): DefaultUserAction => ({type: userActionTypes.FETCH_USER});
export const fetchUserSuccess = (user: IUser): DefaultUserAction => ({
  type: userActionTypes.FETCH_USER_SUCCESS,
  payload: user,
});
export const fetchUserFailure = (): DefaultUserAction => ({
  type: userActionTypes.FETCH_USER_FAILURE,
});
