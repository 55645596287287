// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-param-reassign */
import {notification} from 'antd';
import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import paths from '../routes/paths';
import {dataURLtoFile} from '../utils/file';
import LocalStorageService from './LocalStorageService';

class Interceptor {
  axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;

    this.setRequestInterceptor();
    this.setResponseInterceptor();
  }

  setRequestInterceptor(): void {
    this.axiosInstance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        const authToken = LocalStorageService.getAuthToken();

        if (config?.headers && authToken) {
          config.headers.Authorization = authToken;
        }
        // config.headers.common.withCredentials = false;
        // config.headers.common.Accept = 'fund/json';

        return config;
      },
      (error) => Promise.reject(error),
    );
  }

  setResponseInterceptor(): void {
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        if (response?.headers?.['x-token']) {
          LocalStorageService.setAuthToken(response.headers['x-token']);
        }
        // TODO: REFACTOR
        if (response?.headers?.['content-type'] === 'application/pdf') {
          const fileName = response?.headers?.['x-file-name'] || 'dosya.pdf';
          const file = dataURLtoFile(response.data, fileName);
          return file;
        }

        // if (
        //   response?.headers?.['content-type'] ===
        //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        // ) {
        //   console.log(createExcelFile(response.data));
        //   return response.data;
        // }
        if (response.data) {
          return response.data;
        }
        return response;
      },

      ({response: error}) => {
        if (error.data && error.data && error.status && error.status === 503) {
          notification.error({message: error.data});
          LocalStorageService.removeAuthToken();
        } else if (error.data && error.data.status === 500 && error.data.detail) {
          notification.error({message: error.data.detail});
        } else if (error.data && error.data.message) {
          notification.error({message: error.data.message});
        } else if (error.status === 401) {
          LocalStorageService.removeAuthToken();
          if (window.location.pathname !== paths.login) {
            // window.location = paths.login;
          }
        } else {
          notification.error({message: 'Beklenmedik bir hata oluştu!'});
        }
        return Promise.reject(error);
      },
    );
  }
}

export default Interceptor;
