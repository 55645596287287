import React, {useState} from 'react';
import {Alert} from 'antd';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import userApi from '../../../Api/User';
import {useAppSelector} from '../../../hooks/useRedux';

const ResetPassword: React.VFC = () => {
  const [isSent, setIsSet] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userEmail = useAppSelector((state) => state.user.user.email);

  const sendRecoveryMail = async () => {
    setIsLoading(true);
    try {
      await userApi.sendResetPasswordEmail(userEmail);
      setIsSet(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const description =
    'Kullanıcı e-mail hesabına gönderilecek mail ile şifrenizi sıfırlayabilirsiniz.';
  const successTitle = 'Mail başarıyla gönderildi!';
  const succesDescription = 'Lütfen e-mail hesabınızı kontrol ediniz.';

  return (
    <div>
      <Text type="h2" bold>
        Şifre Sıfırla
      </Text>

      <Alert
        className="my-big"
        type={isSent ? 'success' : 'info'}
        message={isSent ? successTitle : ''}
        description={isSent ? succesDescription : description}
        showIcon
      />

      {!isSent && (
        <Button type="primary" onClick={sendRecoveryMail} loading={isLoading}>
          E-posta Gönder
        </Button>
      )}
    </div>
  );
};

export default ResetPassword;
