import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Text from '../../../../components/Text';
import {convertNumberDotSeperated} from '../../../../utils/string';
import {useAppSelector} from '../../../../hooks/useRedux';
import customerApi from '../../../../Api/Customer';
import {CustomIcon} from '../../../../components/Icon';

const InsuranceInfo = () => {
  const customer = useAppSelector((state) => state.customer.customerCreateQueryData);
  const [insuranceData, setInsuranceData] = useState<any>();

  const fetchInitialData = async () => {
    const initialData = await customerApi.fetchCustomerInsuranceInfo(customer?.accountId);
    const momentDate =
      initialData.decisionDate && moment(initialData.decisionDate).format('DD/MM/YYYY');
    setInsuranceData({
      ...initialData,
      decisionDate: momentDate,
      acceptedAmount: convertNumberDotSeperated(initialData.acceptedAmount),
      requestedAmount: convertNumberDotSeperated(initialData.requestedAmount),
    });
  };

  useEffect(() => {
    fetchInitialData();
  }, [customer]);

  return (
    <div className="w-100" id="customer-tax-data">
      <div className="section-head mb-20">
        <CustomIcon icon="umbrella" className="mr" />
        <Text type="h2" className="section-title">
          Alacak Sigortası Bilgileri
        </Text>
      </div>
      <div className="pl-20">
        <Text bold block className="primary-text">
          Sigorta Adı:
        </Text>
        <Text>{insuranceData?.insuranceCompanyId}</Text>
        <br />
        <br />
        <Text bold block className="primary-text">
          Talep Edilen Limit:
        </Text>
        <Text>{insuranceData?.requestedAmount}</Text>
        <br />
        <br />
        <Text bold block className="primary-text">
          Onaylanan Limit:
        </Text>
        <Text>{insuranceData?.acceptedAmount}</Text>
        <br />
        <br />
        <Text bold block className="primary-text">
          Notlar:
        </Text>
        <Text>{insuranceData?.evaluationDescription || '-'}</Text>
        <br />
        <br />
        <Text bold block className="primary-text">
          Karar Tarihi:
        </Text>
        <Text>{insuranceData?.decisionDate}</Text>
      </div>
    </div>
  );
};

export default InsuranceInfo;
