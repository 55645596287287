import React, {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Row, Col, Form, Input, notification} from 'antd';
import {useDispatch} from 'react-redux';
import Card from '../../../components/Card';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import LoginStyles from '../styles';
// import LogoFonRadar from '../../../assets/logo-big.png';
import userApi from '../../../Api/User';
import paths from '../../../routes/paths';
import {logout} from '../../../store/user/userActions';

const PasswordRecovery: React.VFC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const {token}: {token: string} = useParams();
  const [loading, setLoading] = useState(false);

  const onLogin = async (formValues: {password: string; passwordAgain: string}) => {
    setLoading(true);
    try {
      const {password, passwordAgain} = formValues;
      await userApi.resetPassword({token, password, passwordAgain});

      notification.success({
        message: 'Şifreniz güncellendi!',
        description: 'Yeni şifrenizi kullanarak giriş yapabilirsiniz.',
      });
      dispatch(logout());
      history.push(paths.login);
    } catch (error) {
      form.resetFields();
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginStyles>
      <Row justify="center">
        <Col xs={24} md={12} lg={8}>
          <Card>
            <Text type="h2" color="primary" align="center">
              {history.location.pathname.includes('/set-password')
                ? 'Şifre Oluştur'
                : 'Şifre Sıfırla'}
            </Text>
            <Form form={form} name="passwordRecovery" onFinish={onLogin}>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen yeni bir şifre giriniz!',
                  },
                ]}
              >
                <Input.Password placeholder="Şifre" />
              </Form.Item>

              <Form.Item
                name="passwordAgain"
                rules={[
                  {
                    validator: (_, value) =>
                      value === form.getFieldValue('password')
                        ? Promise.resolve()
                        : Promise.reject(new Error('Lütfen şifreyi tekrarlayınız!')),
                  },
                ]}
              >
                <Input.Password placeholder="Şifre Tekrar" />
              </Form.Item>

              <Form.Item>
                <Button
                  className="mt-big"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                  block
                >
                  Şifremi Güncelle
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </LoginStyles>
  );
};

export default PasswordRecovery;
