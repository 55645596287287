// import {notification} from 'antd';
import {notification} from 'antd';
import {put} from 'redux-saga/effects';
// import moment from 'moment';
// import firebase from '../../services/firebase';
import userApi from '../../Api/User';
import {IUser} from '../../Api/User/types';
import LocalStorageService from '../../services/LocalStorageService';
import {loginSuccess, loginFailure, logoutSuccess, logoutFailure} from './userActions';
import {LoginAction} from './userTypes';

// const normalizeGsmNumber = (gsmNumber = '') => {
//   const prefix1 = '+90';
//   const prefix2 = '05';

//   if (gsmNumber.startsWith(prefix1)) {
//     return gsmNumber.slice(prefix1.length);
//   }
//   if (gsmNumber.startsWith(prefix2)) {
//     return gsmNumber.slice(prefix2.length - 1); // include 5
//   }
//   return gsmNumber;
// };

export function* loginSaga({payload}: LoginAction) {
  try {
    const {email, password} = payload;

    const user: IUser = yield userApi.login(email, password);
    notification.success({message: `Hoşgeldin ${user.name}`});
    LocalStorageService.setAuthToken(user.token);

    yield put(loginSuccess(user));
  } catch (error) {
    yield put(loginFailure());
  }
}

export function* logoutSaga() {
  try {
    LocalStorageService.removeAuthToken();

    yield put(logoutSuccess());
  } catch (error) {
    yield put(logoutFailure());
  }
}

export function* fetchUserSaga() {
  try {
    const user: IUser = yield userApi.fetchUser();
    yield put(loginSuccess(user));
  } catch (error) {
    yield put(loginFailure());
  }
}
// export function* updateUserSaga({payload}: UpdateUserAction) {
//   try {
//     const gsmNumber = `0${payload.gsmNumber}`;
//     const {data} = yield userApi.patch(endpoints.smeUsers, {...payload, gsmNumber});

//     yield put(updateUserSuccess(data));
//     notification.success({message: 'Hesabınız başarıyla güncellendi.'});

//     yield put(updateUserReset());
//   } catch (error) {
//     yield put(updateUserFailure());
//   }
// }
