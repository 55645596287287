import React, {FC, useEffect, useState} from 'react';
import {Select, Drawer, Form, Input, DatePicker} from 'antd';
import {useLocation} from 'react-router-dom';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import customerApi from '../../../../Api/Customer';
import ContentContainer from '../../../../components/ContentContainer';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useRedux';
import {fetchCities} from '../../../../store/globals/globalsActions';
import {ISalesFieldCreateComponent} from '../types';
import {CREATE_SALES_FIELD_INFO} from '../../../../constants';
import searchApi from '../../../../Api/Search';
import InfoForm from './InfoForm';
import {ITaxOffice} from '../../../../Api/Search/types';
import {setCustomerAccount, setCustomerQueryData} from '../../../../store/customer/customerActions';
import {matchTurkishString} from '../../../../utils/string';

const SaleFieldInfo: FC<ISalesFieldCreateComponent> = ({isLoading, onSubmit, onCancel}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const params = new URLSearchParams(useLocation().search);
  const [isLoadingQueryCustomer, setIsLoadingQueryCustomer] = useState(false);
  const [isInfoDrawerVisible, setIsInfoDrawerVisible] = useState(false);
  const {cities, isLoadingCities} = useAppSelector((state) => state.globals);
  const [provinces, setProvinces] = useState<any>();
  const [province, setProvince] = useState<number>();
  const [taxOffices, setTaxOffices] = useState<ITaxOffice[]>([]);
  const [isLoadingtaxOffices, setIsLoadingTaxOffices] = useState(false);
  const [saleField, setSaleField] = useState<any>();
  const {customerAccount, customerCreateQueryData: customerQuery}: any = useAppSelector(
    (state) => state.customer,
  );

  const toggleDrawer = () => setIsInfoDrawerVisible((isVisible) => !isVisible);

  useEffect(() => {
    dispatch(fetchCities());
  }, []);

  useEffect(() => {
    if (cities && cities.length > 0) {
      setProvinces(
        cities.reduce((newProvince: any, prov: any) => {
          newProvince.push({value: prov.label, label: prov.label, key: prov.value});
          return newProvince;
        }, []),
      );
    }
  }, [cities]);

  const fetchTaxOffices = async (provinceCode: number) => {
    try {
      setIsLoadingTaxOffices(true);
      const taxOfficesData = await searchApi.fetchTaxOffices(provinceCode);
      setTaxOffices(
        taxOfficesData.reduce((newTaxOfc: any, taxofc: any) => {
          newTaxOfc.push({value: taxofc.label, label: taxofc.label, key: taxofc.value});
          return newTaxOfc;
        }, []),
      );
    } catch {
      setTaxOffices([]);
    } finally {
      setIsLoadingTaxOffices(false);
    }
  };

  const queryCustomer = async ({taxNumber, taxOffice}: {taxNumber: number; taxOffice: string}) => {
    try {
      setIsLoadingQueryCustomer(true);
      const customer: any = await customerApi.queryCustomer(taxNumber.toString(), taxOffice, true);
      if (customerAccount) {
        dispatch(setCustomerQueryData({...customer, ...customerAccount}));
      } else {
        dispatch(setCustomerQueryData(customer));
      }
      form.setFieldsValue({
        customerRepresentative: customer.title,
        // formDate: customer.startDate,
      });
      setIsInfoDrawerVisible(true);
    } finally {
      setIsLoadingQueryCustomer(false);
    }
  };

  const handleSaleField = (values: any) => {
    setSaleField(values);
    queryCustomer(values);
  };

  const handleInfoSubmit = () => {
    const taxAdmin = saleField.taxOffice;
    if (saleField.taxOffice) delete saleField.taxOffice;
    onSubmit({...saleField, taxAdministration: taxAdmin});
    setIsInfoDrawerVisible(false);
  };

  const onChangeProvince = (provinceLabel: any) => {
    const found = cities.find((city) => city.label === provinceLabel);
    if (found) {
      setProvince(found.value);
      fetchTaxOffices(found.value);
      form.setFieldsValue({taxOffice: undefined});
    }
  };

  const setFormData = (values: any) => {
    const provinceData: any = cities.find((value: any) => value.label === values.province);
    if (provinceData) {
      form.setFieldsValue({
        taxNumber: values.taxNumber,
        province: provinceData,
      });
      setProvince(provinceData.value);
      fetchTaxOffices(provinceData.value);
    } else {
      form.setFieldsValue({
        taxNumber: values.taxNumber,
      });
    }
  };

  useEffect(() => {
    if (cities && cities.length > 0 && customerAccount && customerAccount.province) {
      setFormData(customerAccount);
    } else {
      form.resetFields();
    }
  }, [cities]);

  useEffect(() => {
    if (!params.get('id')) {
      form.resetFields();
      dispatch(setCustomerAccount(''));
    }
  }, [params.get('id')]);

  useEffect(() => {
    if (customerAccount && customerAccount.province && customerAccount.taxNumber) {
      const taxOfficesData: any = taxOffices.find((value: any) =>
        matchTurkishString(value.label, customerAccount.taxOffice),
      );
      if (taxOfficesData) {
        form.setFieldsValue({taxOffice: taxOfficesData.value});
      }
    }
  }, [taxOffices]);

  return (
    <div>
      <Text type="h2" bold>
        {CREATE_SALES_FIELD_INFO.SALES_FIELD_INFO.title}
      </Text>
      <Text type="h4" style={{width: '60%', marginTop: '1em'}}>
        {CREATE_SALES_FIELD_INFO.SALES_FIELD_INFO.description}{' '}
      </Text>
      <ContentContainer maxWidth={400}>
        <>
          <Form
            form={form}
            onFinish={handleSaleField}
            style={{marginTop: '2em'}}
            layout="vertical"
            requiredMark={false}
          >
            <Form.Item name="customerRepresentative" label="Hazırlayan - Müşteri Temsilcisi">
              <Input type="text" />
            </Form.Item>

            <Form.Item name="formDate" label="Form Düzenleme Tarihi">
              <DatePicker placeholder="Tarih seçiniz" style={{width: '100%'}} />
            </Form.Item>

            <Form.Item name="accountCode" label="Müşteri Kodu">
              <Input type="number" />
            </Form.Item>

            <Form.Item
              name="taxNumber"
              label="Vergi Numarası"
              rules={[
                {
                  validator: (_, value) =>
                    value?.toString().length === 11 || value?.toString().length === 10
                      ? Promise.resolve()
                      : Promise.reject(new Error('Geçerlı bir VKN / TCKN giriniz!')),
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>

            <Form.Item name="province" label="Şehir" rules={[{required: true}]}>
              <Select
                placeholder="örn. İstanbul"
                optionFilterProp="label"
                options={provinces}
                disabled={isLoadingCities}
                loading={isLoadingCities}
                showSearch
                onChange={onChangeProvince}
              />
            </Form.Item>

            <Form.Item name="taxOffice" label="Vergi Dairesi" rules={[{required: true}]}>
              <Select
                options={taxOffices}
                disabled={!province}
                loading={isLoadingtaxOffices}
                optionFilterProp="label"
                autoClearSearchValue
                showSearch
              />
            </Form.Item>
            <Form.Item className="m-0">
              <Button
                className="mt"
                loading={isLoading || isLoadingQueryCustomer}
                type="primary"
                htmlType="submit"
                size="large"
                block
              >
                Sorgula
              </Button>
            </Form.Item>
          </Form>
          <Drawer title="Bilgiler" width={600} visible={isInfoDrawerVisible} onClose={toggleDrawer}>
            <div id="customer-tax-data">
              <InfoForm
                accountId={customerQuery?.accountId}
                isLoading={isLoading}
                title={customerQuery?.title}
                taxId={customerQuery?.taxId}
                taxAdministration={customerQuery?.taxAdministration}
                address={customerQuery?.address}
                startDate={customerQuery?.startDate}
                operationDetail={customerQuery?.operationDetail}
                onSubmit={handleInfoSubmit}
                onCancel={onCancel}
              />
            </div>
          </Drawer>
        </>
      </ContentContainer>
    </div>
  );
};

export default SaleFieldInfo;
