/* eslint no-nested-ternary: "off" */
/* eslint no-unsafe-optional-chaining: "off" */
import {TableProps} from 'antd';
import React, {FC} from 'react';
import StyledTable from './styles';

const Table: FC<TableProps<any>> = (props) => {
  const {columns} = props;
  return (
    <StyledTable
      rowClassName={(record: any, idx) => {
        if (
          columns &&
          typeof record[record.length - 1] === 'string' &&
          record[record.length - 1] &&
          record.length > columns?.length - 1
        ) {
          return 'red-background';
        }
        return idx % 2 === 0 ? 'light-smoke-background ' : '';
      }}
      {...props}
    />
  );
};

export default Table;
