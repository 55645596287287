import React, {useEffect, useState} from 'react';
import Text from '../../../../components/Text';
import {convertNumberDotSeperated} from '../../../../utils/string';
import {useAppSelector} from '../../../../hooks/useRedux';
import customerApi from '../../../../Api/Customer';
import {SummaryCol, SummaryRow} from '../../CustomerCreate/WorkingConditionsInfo/styles';
import {CURRENCY} from '../../../../constants';
import {CustomIcon} from '../../../../components/Icon';

const WorkingConditionInfo = () => {
  const customer: any = useAppSelector((state) => state.customer.customerCreateQueryData);
  const {paymentTypes, currencyTypes} = useAppSelector((state) => state.globals);
  const [workingConditionData, setWorkingConditionData] = useState<any>();

  const fetchInitialData = async () => {
    const initialData = await customerApi.fetchCustomerWorkingConditionsInfo(customer.accountId);
    const initalValues = initialData.salesCondition || {};
    setWorkingConditionData({
      ...initialData.guaranteeSummary,
      currency: initalValues.limitAmountCurrencyParamterId || CURRENCY.TL.value,
      summaryData: initialData.guaranteeSummary,
      monthOfTermCount: initalValues.monthOfTermCount,
      limitGuaranteeCoefficientParamterId: initalValues.limitGuaranteeCoefficientParamterId,
      limitAmount: convertNumberDotSeperated(initalValues.limitAmount),
      paymentMethodParameterId: initalValues.paymentMethodParameterId,
    });
  };

  useEffect(() => {
    fetchInitialData();
  }, [customer]);

  return (
    <div className="w-100" id="customer-tax-data">
      <div className="section-head mb-20">
        <CustomIcon icon="bag" className="mr" />
        <Text type="h2" className="section-title">
          Çalışma Koşulları
        </Text>
      </div>
      <div className="pl-20 mb-big">
        <SummaryRow>
          <SummaryCol className="smoke" span={8}>
            <Text align="center" bold block>
              Alacak Sigortası
            </Text>
            <Text align="center" block>
              {convertNumberDotSeperated(workingConditionData?.creditInsurance)} TL
            </Text>
          </SummaryCol>
          <SummaryCol className="smoke" span={8}>
            <Text align="center" bold block>
              Teminat Mektubu
            </Text>
            <Text align="center" block>
              {convertNumberDotSeperated(workingConditionData?.guaranteeLetter)} TL
            </Text>
          </SummaryCol>
          <SummaryCol className="smoke" span={8}>
            <Text align="center" bold block>
              DBS
            </Text>
            <Text align="center" block>
              {convertNumberDotSeperated(workingConditionData?.dbs)} TL
            </Text>
          </SummaryCol>

          <SummaryCol span={8}>
            <Text align="center" bold block>
              İpotek
            </Text>
            <Text align="center" block>
              {convertNumberDotSeperated(workingConditionData?.lien)} TL
            </Text>
          </SummaryCol>
          <SummaryCol span={8}>
            <Text align="center" bold block>
              Diğer
            </Text>
            <Text align="center" block>
              {convertNumberDotSeperated(workingConditionData?.other)} TL
            </Text>
          </SummaryCol>
          <SummaryCol span={8}>
            <Text align="center" bold block>
              Toplam
            </Text>
            <Text align="center" block>
              {convertNumberDotSeperated(workingConditionData?.total)} TL
            </Text>
          </SummaryCol>
        </SummaryRow>
        <br />
        <br />
        <Text bold block className="primary-text">
          Vade:
        </Text>
        <Text>{workingConditionData?.monthOfTermCount || '-'}</Text>
        <br />
        <br />
        <Text bold block className="primary-text">
          Limit Teminat Kat Sayısı:
        </Text>
        <Text>
          {convertNumberDotSeperated(workingConditionData?.limitAmount || 0)}{' '}
          {currencyTypes.find(({value}) => value === workingConditionData?.currency)?.label || '-'}
        </Text>
        <br />
        <br />
        <Text bold block className="primary-text">
          Ödeme Şekli:
        </Text>
        <Text>
          {paymentTypes.find(({value}) => value === workingConditionData?.paymentMethodParameterId)
            ?.label || '-'}
        </Text>
      </div>
    </div>
  );
};

export default WorkingConditionInfo;
