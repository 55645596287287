import React, {FC, useEffect, useState} from 'react';
import {Col, Row} from 'antd';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Sider from '../../../components/Layout/Sider';
import GeneralInfo from './GeneralInfo';
import RecordInfo from './RecordInfo';
import InsuranceInfo from './InsuranceInfo';
import WarrantInfo from './WarrantInfo';
import FindeksInfo from './FindeksInfo';
import FinancialInfo from './FinancialInfo';
import LegalDocuments from './LegalDocuments';
import ContentContainer from '../../../components/ContentContainer';
import {ICustomerCreateComponent} from './types';
import {useAppSelector} from '../../../hooks/useRedux';
import WorkingConditionsInfo from './WorkingConditionsInfo';
import PATHS from '../../../routes/paths';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import customerApi from '../../../Api/Customer';
import {CREATE_CUSTOMER_STEPS} from '../../../constants';
import {
  resetCustomerQueryData,
  setCustomerQueryData,
  setCustomersList,
} from '../../../store/customer/customerActions';

const CustomerCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{activeTabKey: string}>();
  const params = new URLSearchParams(useLocation().search);
  const [activeSiderKey, setActiveSiderKey] = useState('info');
  const {customerCreateQueryData: customer, customerAccount} = useAppSelector(
    (state) => state.customer,
  );

  const customerAccountId = customer.accountId;

  const siderItems = [
    {
      key: 'info',
      text: 'Temel Bilgiler (Mecburi)',
      icon: 'home',
      component: GeneralInfo,
      disabled: !!location?.state?.activeTabKey,
    },
    {
      key: CREATE_CUSTOMER_STEPS.RECORD_INFO.key,
      text: `${CREATE_CUSTOMER_STEPS.RECORD_INFO.title} (Mecburi)`,
      icon: CREATE_CUSTOMER_STEPS.RECORD_INFO.icon,
      component: RecordInfo,
      disabled: !customerAccountId,
    },
    {
      key: CREATE_CUSTOMER_STEPS.WARRANT_INFO.key,
      text: CREATE_CUSTOMER_STEPS.WARRANT_INFO.title,
      icon: CREATE_CUSTOMER_STEPS.WARRANT_INFO.icon,
      component: WarrantInfo,
      disabled: !customerAccountId,
    },
    {
      key: CREATE_CUSTOMER_STEPS.INSURANCE_INFO.key,
      text: CREATE_CUSTOMER_STEPS.INSURANCE_INFO.title,
      icon: CREATE_CUSTOMER_STEPS.INSURANCE_INFO.icon,
      component: InsuranceInfo,
      disabled: !customerAccountId,
    },
    {
      key: CREATE_CUSTOMER_STEPS.FINDEKS_INFO.key,
      text: CREATE_CUSTOMER_STEPS.FINDEKS_INFO.title,
      icon: CREATE_CUSTOMER_STEPS.FINDEKS_INFO.icon,
      component: FindeksInfo,
      disabled: !customerAccountId,
    },
    {
      key: CREATE_CUSTOMER_STEPS.FINANCIAL_INFO.key,
      text: CREATE_CUSTOMER_STEPS.FINANCIAL_INFO.title,
      icon: CREATE_CUSTOMER_STEPS.FINANCIAL_INFO.icon,
      component: FinancialInfo,
      disabled: !customerAccountId,
    },
    {
      key: CREATE_CUSTOMER_STEPS.LEGAL_DOCUMENTS.key,
      text: CREATE_CUSTOMER_STEPS.LEGAL_DOCUMENTS.title,
      icon: CREATE_CUSTOMER_STEPS.LEGAL_DOCUMENTS.icon,
      component: LegalDocuments,
      disabled: !customerAccountId,
    },
    {
      key: CREATE_CUSTOMER_STEPS.WORKING_CONDITIONS_INFO.key,
      text: CREATE_CUSTOMER_STEPS.WORKING_CONDITIONS_INFO.title,
      icon: CREATE_CUSTOMER_STEPS.WORKING_CONDITIONS_INFO.icon,
      component: WorkingConditionsInfo,
      disabled: !customerAccountId,
    },
  ];

  useEffect(() => {
    window.scroll(0, 0);
  }, [activeSiderKey]);

  useEffect(() => {
    if (location.state?.activeTabKey) {
      setActiveSiderKey(location.state.activeTabKey);
    }
  }, []);

  const fetchCustomers = async (search = '', page = 1) => {
    const paramId: any = params.get('id');
    const customersData: any = await customerApi.fetchCustomers({search, page});
    dispatch(setCustomersList(customersData.data));
    if (!customerAccount || customerAccount === '') {
      const findCustomer = customersData.data.find((val: any) => val.id === parseInt(paramId, 10));
      if (findCustomer) {
        const detail = await customerApi.queryCustomer(
          findCustomer.taxNumber,
          findCustomer.taxOffice,
          false,
          parseInt(findCustomer.customerKindId, 10),
          findCustomer.accountCode,
        );
        dispatch(setCustomerQueryData({...detail, ...findCustomer}));
      }
    }
  };

  useEffect(() => {
    const paramId: any = params.get('id');
    if (paramId && (!customer.accountId || customer.accountId === 0)) {
      fetchCustomers();
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetCustomerQueryData());
    };
  }, []);

  const getSiderItemByKey = (itemKey: string) => siderItems.find(({key}) => key === itemKey);
  const Component: FC<ICustomerCreateComponent> =
    getSiderItemByKey(activeSiderKey)?.component || GeneralInfo;

  const next = () => {
    const currentIdx = siderItems.findIndex(({key}) => key === activeSiderKey);

    if (currentIdx === siderItems.length - 1) {
      history.push(PATHS.customer);
    } else {
      setActiveSiderKey(siderItems[currentIdx + 1].key);
    }
  };
  const visitCustomers = () => {
    history.push(
      PATHS.getCustomerDetailPath(
        customer.taxAdministration,
        customer.taxId,
        customer.customerKindId,
      ),
    );
  };

  return (
    <ContentContainer horizontalPadding>
      <Row style={{flexWrap: 'nowrap'}} gutter={40}>
        <Col>
          <Link to={PATHS.customerCreateBulk}>
            <Button className="mb" type="dashed" block>
              <Icon className="mr" icon="users" color="inherit" /> Toplu Müşteri Kaydet
            </Button>
          </Link>
          <Sider
            items={siderItems}
            activeKey={activeSiderKey}
            onChange={(itemKey) => setActiveSiderKey(itemKey)}
          />
        </Col>

        <Col flex={1}>
          <Component onSubmit={next} onCancel={visitCustomers} />
        </Col>
      </Row>
    </ContentContainer>
  );
};

export default CustomerCreate;
