import React, {useEffect, useState, VFC} from 'react';
import {Col, Drawer, Row, Spin} from 'antd';
import _ from 'lodash';
import Text from '../../components/Text';
import {DashboardSummaryContainer} from './styles';
import {useAppSelector} from '../../hooks/useRedux';
import {convertNumberDotSeperated} from '../../utils/string';
import Button from '../../components/Button';
import Card from '../../components/Card';
import dashboardApi from '../../Api/Dashboard';
import {IDashboardSumamry, IDBSBANKS} from '../../Api/Dashboard/types';

const Dashboard: VFC = () => {
  const [dashboardData, setDashboardData] = useState<IDashboardSumamry>();
  const [darwerData, setDrawerData] = useState<IDBSBANKS>({
    'Ziraat Bankası': 0,
    'Garanti Bankası': 0,
    'Halk Bankası': 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const {user} = useAppSelector((state) => state.user);

  const toggleDrawer = () => setIsDrawerVisible((isVisible) => !isVisible);
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const data: IDashboardSumamry = await dashboardApi.fetchDashboardSummary();
      setDashboardData(data);
      if (data.dbsPerBanks) {
        setDrawerData(_.assign(darwerData, data.dbsPerBanks));
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Spin spinning={isLoading}>
      <Text type="h2" bold>
        Hoşgeldin {user?.name}
      </Text>

      <DashboardSummaryContainer>
        <Text type="h3" color="primary" weight={600}>
          {dashboardData?.companyName}
        </Text>
        <Row justify="space-between" align="bottom">
          <Col>
            <Text className="m-0" type="h4" color="primary">
              Toplam Limit:
            </Text>
            <Text type="h3" color="primary" weight={600}>
              {convertNumberDotSeperated(dashboardData?.totalLimit)} TL
            </Text>
          </Col>
          <Col>
            <Text type="h3" color="primary" weight={600}>
              Teminat Mektubu
            </Text>
            <Text className="m-0" type="h4" color="primary">
              Toplam Limit:
            </Text>
            <Text type="h3" color="primary" weight={600}>
              {convertNumberDotSeperated(dashboardData?.guaranteeLetterTotalLimit)} TL
            </Text>
          </Col>
          <Col>
            <Text type="h3" color="primary" weight={600}>
              İpotek
            </Text>
            <Text className="m-0" type="h4" color="primary">
              Toplam Tutar:
            </Text>
            <Text type="h3" color="primary" weight={600}>
              {convertNumberDotSeperated(dashboardData?.lienTotal)} TL
            </Text>
          </Col>
          <Col>
            <Text type="h3" color="primary" weight={600}>
              Alacak Sigortası
            </Text>
            <Text className="m-0" type="h4" color="primary">
              Toplam Tutar:
            </Text>
            <Text type="h3" color="primary" weight={600}>
              {convertNumberDotSeperated(dashboardData?.totalAcceptedCreditInsuranceAmount)} TL
            </Text>
          </Col>
          <Col>
            <Text type="h3" color="primary" weight={600}>
              DBS
            </Text>
            <Text className="m-0" type="h4" color="primary">
              Toplam Tutar:
            </Text>
            <Text type="h3" color="primary" weight={600}>
              {convertNumberDotSeperated(dashboardData?.dbsTotal)} TL
            </Text>
          </Col>

          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <Text className="m-0" type="h4" color="primary">
                  Ortalama Vade:
                </Text>
                <Text className="m-0" type="h3" color="primary" weight={600}>
                  {dashboardData?.avarageTerm}
                </Text>
              </Col>
              <Col>
                <Button type="link" onClick={toggleDrawer}>
                  <Text color="primary" underlined bold>
                    Tümünü Gör
                  </Text>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardSummaryContainer>

      <Drawer
        title={
          <Text className="m-0" type="h3" bold>
            DBS
          </Text>
        }
        width={500}
        visible={isDrawerVisible}
        onClose={toggleDrawer}
      >
        <Row className="ml">
          <Col span={12}>
            <Text className="m-0" type="h3" weight={600}>
              Banka Adı
            </Text>
          </Col>
          <Col span={12}>
            <Text className="m-0" type="h3" weight={600}>
              Tutar
            </Text>
          </Col>
        </Row>

        {Object.keys(darwerData).map((drawer) => (
          <Card
            key={`drawer-bank-${drawer}`}
            className="my"
            background="lightSmoke"
            margin={false}
            hover
          >
            <Row>
              <Col span={12}>
                <Text bold>{drawer}</Text>
              </Col>
              <Col span={12}>
                <Text bold>{convertNumberDotSeperated(darwerData[drawer])} TL</Text>
              </Col>
            </Row>
          </Card>
        ))}
      </Drawer>
    </Spin>
  );
};
export default Dashboard;
