import {
  IBank,
  ICity,
  ICurrencyType,
  IGrowthType,
  IInsuranceCompany,
  ILimitRateType,
  IPaymentType,
  ITermOfSaleType,
} from '../../Api/Search/types';

export const globalsActionTypes = {
  FETCH_CITIES: 'FETCH_CITIES',
  FETCH_CITIES_SUCCESS: 'FETCH_CITIES_SUCCESS',
  FETCH_CITIES_FAILURE: 'FETCH_CITIES_FAILURE',

  FETCH_INSURANCE_COMPANIES: 'FETCH_INSURANCE_COMPANIES',
  FETCH_INSURANCE_COMPANIES_SUCCESS: 'FETCH_INSURANCE_COMPANIES_SUCCESS',
  FETCH_INSURANCE_COMPANIES_FAILURE: 'FETCH_INSURANCE_COMPANIES_FAILURE',

  FETCH_BANKS: 'FETCH_BANKS',
  FETCH_BANKS_SUCCESS: 'FETCH_BANKS_SUCCESS',
  FETCH_BANKS_FAILURE: 'FETCH_BANKS_FAILURE',

  FETCH_GROWTH_TYPES: 'FETCH_GROWTH_TYPES',
  FETCH_GROWTH_TYPES_SUCCESS: 'FETCH_GROWTH_TYPES_SUCCESS',
  FETCH_GROWTH_TYPES_FAILURE: 'FETCH_GROWTH_TYPES_FAILURE',

  FETCH_CURRENCY_TYPES: 'FETCH_CURRENCY_TYPES',
  FETCH_CURRENCY_TYPES_SUCCESS: 'FETCH_CURRENCY_TYPES_SUCCESS',
  FETCH_CURRENCY_TYPES_FAILURE: 'FETCH_CURRENCY_TYPES_FAILURE',

  FETCH_PAYMENT_TYPES: 'FETCH_PAYMENT_TYPES',
  FETCH_PAYMENT_TYPES_SUCCESS: 'FETCH_PAYMENT_TYPES_SUCCESS',
  FETCH_PAYMENT_TYPES_FAILURE: 'FETCH_PAYMENT_TYPES_FAILURE',

  FETCH_TERM_OF_SALE_TYPES: 'FETCH_TERM_OF_SALE_TYPES',
  FETCH_TERM_OF_SALE_TYPES_SUCCESS: 'FETCH_TERM_OF_SALE_TYPES_SUCCESS',
  FETCH_TERM_OF_SALE_TYPES_FAILURE: 'FETCH_TERM_OF_SALE_TYPES_FAILURE',

  FETCH_LIMIT_RATE_TYPES: 'FETCH_LIMIT_RATE_TYPES',
  FETCH_LIMIT_RATE_TYPES_SUCCESS: 'FETCH_LIMIT_RATE_TYPES_SUCCESS',
  FETCH_LIMIT_RATE_TYPES_FAILURE: 'FETCH_LIMIT_RATE_TYPES_FAILURE',
};
export type IGlobalsActionType = keyof typeof globalsActionTypes;

export interface IPayloadlessGlobalsAction {
  type: IGlobalsActionType;
}
export interface IFetchCitiesSuccessAction {
  type: string;
  payload: ICity[];
}

export interface IFetchBanksSuccess {
  type: string;
  payload: IBank[];
}

export interface IFetchInsuranceCompaniesSuccessAction {
  type: string;
  payload: IInsuranceCompany[];
}

export interface IFetchGrowthTypesSuccess {
  type: string;
  payload: IGrowthType[];
}

export interface IFetchCurrencyTypesSuccess {
  type: string;
  payload: ICurrencyType[];
}

export interface IFetchPaymentTypesSuccess {
  type: string;
  payload: IPaymentType[];
}

export interface IFetchTermOfSaleTypesSuccess {
  type: string;
  payload: ITermOfSaleType[];
}

export interface IFetchLimitRateTypesSuccess {
  type: string;
  payload: ILimitRateType[];
}
