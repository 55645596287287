import React, {useEffect} from 'react';
import {Redirect, Route, Switch, useLocation, RouteProps} from 'react-router-dom';
import {useAppSelector} from '../hooks/useRedux';
import Login from '../pages/Login';
import PasswordRecovery from '../pages/Login/PasswordRecovery';
import ResetPassword from '../pages/Login/ResetPassword';
import Dashboard from '../pages/Dashboard';
import CustomerList from '../pages/Customer/CustomerList';
import SalesList from '../pages/Sales/SalesList';
import CustomerDetail from '../pages/Customer/CustomerDetail';
import CustomerCreate from '../pages/Customer/CustomerCreate';
import CustomerCreateBulk from '../pages/Customer/CustomerCreateBulk';
import CustomerInfo from '../pages/Customer/CustomerInfo';
import User from '../pages/User';
import paths from './paths';
import UploadBulkList from '../pages/Customer/UploadBulkList';
import CreateSales from '../pages/Sales/SalesCreate';
import SaleDetail from '../pages/Sales/SalesDetail';
import CreateUser from '../pages/User/CreateUser';
import UserList from '../pages/User/UserList';

export const ProtectedRoute: React.FC<RouteProps> = ({...props}) => {
  const {isLoggedIn} = useAppSelector(({user}) => user);

  return isLoggedIn ? <Route {...props} /> : <Redirect to={paths.login} />;
};
export const GuestRoute: React.FC<RouteProps> = ({...props}) => {
  const {isLoggedIn} = useAppSelector(({user}) => user);

  return !isLoggedIn ? <Route {...props} /> : <Redirect to="/" />;
};

const Routes: React.VFC = () => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => window.scroll({top: 0, behavior: 'smooth'}));
  }, [location]);

  return (
    <Switch>
      <Route path={paths.resetPassword} exact component={ResetPassword} />
      <Route path={paths.setPassword} exact component={ResetPassword} />
      <GuestRoute path={paths.login} exact component={Login} />
      <GuestRoute path={paths.passwordRecovery} exact component={PasswordRecovery} />
      <ProtectedRoute path={paths.user} exact component={User} />
      <ProtectedRoute path={paths.users} exact component={UserList} />
      <ProtectedRoute path={paths.createUser} exact component={CreateUser} />
      <ProtectedRoute path={paths.default} exact component={Dashboard} />
      <ProtectedRoute path={paths.customer} exact component={CustomerList} />
      <ProtectedRoute path={paths.sales} exact component={SalesList} />
      <ProtectedRoute path={paths.saleDetail} exact component={SaleDetail} />
      <ProtectedRoute path={paths.customerDetail} exact component={CustomerDetail} />
      <ProtectedRoute path={paths.customerCreate} exact component={CustomerCreate} />
      <ProtectedRoute path={paths.customerCreateBulkList} exact component={UploadBulkList} />
      <ProtectedRoute path={paths.customerCreateBulk} exact component={CustomerCreateBulk} />
      <ProtectedRoute path={paths.customerInformation} exact component={CustomerInfo} />
      <ProtectedRoute path={paths.salesCreate} exact component={CreateSales} />

      <Redirect to={paths.default} />
    </Switch>
  );
};

export default Routes;
