import React, {useState} from 'react';
import {Button} from 'antd';
import Text from '../../../../components/Text';
import Icon from '../../../../components/Icon';
import {BulkUploadHistoryItems} from '../data';

const UploadHistory = ({setVisible, setPopupData}: any) => {
  const [showDetail, setShowDetail] = useState(-1);

  const handleShowDetail = (index: any) => {
    setShowDetail(index);
    setVisible(true);
    setPopupData({...BulkUploadHistoryItems[index]});
  };

  return (
    <div>
      {BulkUploadHistoryItems.map((item: any, index: any) => (
        <div key={item.key} className="collateral-item">
          <div>
            <Text
              type="h4"
              style={{
                fontSize: '16px',
                fontWeight: '500',
                marginBottom: showDetail === index ? '2px' : '0.5em',
              }}
            >
              {item.text}
            </Text>
            {showDetail === index && (
              <div>
                <Text type="h5" color="primary" style={{fontSize: '14px'}}>
                  {item.description}
                </Text>
                <div className="desc-btns">
                  <Button onClick={() => handleShowDetail(index)} type="default" className="mr">
                    Üzerine Ekle
                  </Button>
                  <Button onClick={() => handleShowDetail(index)} type="primary">
                    Başka Dosya Yükle
                  </Button>
                </div>
              </div>
            )}
          </div>
          {showDetail === index ? (
            <div
              role="button"
              aria-hidden="true"
              tabIndex={0}
              onClick={() => {
                setShowDetail(-1);
                console.log('buttn click');
              }}
            >
              <Icon className="mr" size="2x" color="primary" icon="angle-right" />
            </div>
          ) : (
            <Button
              type="primary"
              className="upload-list-btn"
              onClick={() => handleShowDetail(index)}
            >
              <Icon className="mr" size="1x" color="primary" icon="plus" /> Yükle
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};

export default UploadHistory;
