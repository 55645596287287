import React, {FC} from 'react';
import Button from '../../Button';
import {CustomIcon} from '../../Icon';
import Text from '../../Text';
import SiderStyles, {TitleContainer} from './styles';

type SiderItem = {key: string; icon: string; text: string; disabled?: boolean};
interface Props {
  title?: string;
  subTitle?: string;
  activeKey?: string;
  items: SiderItem[];
  onChange: (string: string) => void;
}

const Sider: FC<Props> = ({title, subTitle, activeKey, items, onChange}) => {
  const displayTitle = !!(title || subTitle);

  return (
    <SiderStyles>
      {displayTitle && (
        <TitleContainer>
          <Text className="m-0" type="h4" color="smoke">
            {title}
          </Text>
          <Text className="" type="h3" bold>
            {subTitle}
          </Text>
        </TitleContainer>
      )}

      <div className="p">
        {items.map(({text, icon, key, disabled}) => (
          <Button
            className="text-l pl hover-scale"
            key={key}
            type={activeKey === key ? 'primary-faded' : 'link'}
            size="large"
            disabled={disabled}
            onClick={() => onChange(key)}
            block
          >
            <CustomIcon icon={icon} className="mr" />
            <Text
              className="d-inline"
              color={disabled ? 'smoke' : 'primary'}
              type="h4"
              weight={activeKey === key ? 600 : 'initial'}
            >
              {text}
            </Text>
          </Button>
        ))}
      </div>
    </SiderStyles>
  );
};

export default Sider;
