import React, {FC} from 'react';
import {Row, Col} from 'antd';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import {ISalesCustomerQuery} from '../../../../Api/Customer/types';

interface Props extends ISalesCustomerQuery {
  onSubmit: () => void;
  onCancel: () => void;
}

const InfoForm: FC<Props> = ({
  isLoading,
  title,
  taxId,
  taxAdministration,
  address,
  startDate,
  operationDetail,
  onSubmit,
  onCancel,
}) => {
  return (
    <div>
      <Text className="m-0" type="h3" bold>
        Ünvanı:
      </Text>
      <Text>{title}</Text>
      <br />
      <br />
      <Text className="m-0" type="h3" bold>
        Vergi Kimlik No:
      </Text>
      <Text>{taxId}</Text>
      <br />
      <br />
      <Text className="m-0" type="h3" bold>
        Vergi Dairesi:
      </Text>
      <Text>{taxAdministration}</Text>
      <br />
      <br />
      <Text className="m-0" type="h3" bold>
        Adres:
      </Text>
      <Text>{address}</Text>
      <br />
      <br />
      <Text className="m-0" type="h3" bold>
        Faaliyete Başlama Tarihi:
      </Text>
      <Text>{startDate}</Text>
      <br />
      <br />
      <Text className="m-0" type="h3" bold>
        Ana Faaliyet Konusu:
      </Text>
      <Text>{operationDetail}</Text>

      <Row gutter={20} className="mt">
        <Col span={11}>
          <Button
            style={{paddingLeft: `${isLoading ? '10px' : '25px'}`}}
            type="primary"
            block
            onClick={onSubmit}
          >
            Kaydet ve Devam Et
          </Button>
        </Col>
        <Col span={8}>
          <Button block disabled={isLoading} onClick={onCancel}>
            Kaydet ve Çık
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default InfoForm;
