import styled from 'styled-components';

export const HeaderContainer = styled.header`
  z-index: 1000;
  position: fixed;
  width: 100vw;

  overflow: hidden;
  height: ${({theme}) => theme.header.height}px;
  padding: ${({theme}) => theme.main.padding}px;

  background-color: ${({theme}) => theme.colors.light};
  border-bottom: ${({theme}) => `1px solid ${theme.colors.lightSmoke}`};
`;

export const Logo = styled.img`
  max-height: 50px;
`;
