import React, {FC} from 'react';
import {Form, Input, Row, Col} from 'antd';
import {useAppSelector} from '../../../../hooks/useRedux';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import {ISalesFieldCreateComponent} from '../types';
import {CREATE_SALES_FIELD_INFO} from '../../../../constants';
import ContentContainer from '../../../../components/ContentContainer';

const {TextArea} = Input;

const SalesUnitFeedback: FC<ISalesFieldCreateComponent> = ({isLoading, onSubmit, onCancel}) => {
  const [form] = Form.useForm();
  const customerSale = useAppSelector((state: any) => state.customer.customerSale);
  return (
    <div>
      <Text type="h2" bold>
        {CREATE_SALES_FIELD_INFO.SALES_UNIT_FEEDBACK.title}
      </Text>
      <ContentContainer maxWidth={400}>
        <Form
          form={form}
          style={{marginTop: '2em'}}
          layout="vertical"
          requiredMark={false}
          initialValues={{...customerSale}}
          onFinish={onSubmit}
        >
          <Form.Item name="note" label="Notlar">
            <TextArea rows={4} />
          </Form.Item>

          <Row gutter={10}>
            <Col span={15}>
              <Form.Item className="m-0">
                <Button
                  style={{paddingLeft: `${isLoading ? '5px' : '20px'}`}}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                  loading={isLoading}
                >
                  Kaydet ve Onaya Gönder
                </Button>
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item className="m-0">
                <Button
                  style={{paddingLeft: '15px'}}
                  type="default"
                  size="large"
                  disabled={isLoading}
                  onClick={onCancel}
                  block
                >
                  Kaydet ve Çık
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ContentContainer>
    </div>
  );
};

export default SalesUnitFeedback;
