export const userActionTypes = {
  AUTO_LOGIN: 'AUTO_LOGIN',
  AUTO_LOGIN_SUCCESS: 'AUTO_LOGIN_SUCCESS',
  AUTO_LOGIN_FAILURE: 'AUTO_LOGIN_FAILURE',

  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  FETCH_USER: 'FETCH_USER',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE: 'FETCH_USER_FAILURE',
};
// type UserActionType = keyof typeof userActionTypes

export interface DefaultUserAction {
  type: string;
  payload?: any;
}

export interface UserCredentials {
  email: string;
  password: string;
}
export interface LoginAction {
  type: typeof userActionTypes.LOGIN;
  payload: UserCredentials;
}
