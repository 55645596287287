import React, {useEffect} from 'react';
import tr_TR from 'antd/es/locale/tr_TR';
import {ConfigProvider} from 'antd';
import Layout from './components/Layout';
import Content from './components/Layout/Content';
import Header from './components/Layout/Header';
import GlobalStyles from './GlobalStyles';
import ThemeProvider from './theme/ThemeProvider';
import 'antd/dist/antd.less';
import Routes from './routes';
import {useAppDispatch, useAppSelector} from './hooks/useRedux';
import {fetchUser} from './store/user/userActions';

const App: React.VFC = () => {
  const isLoggedIn = useAppSelector(({user}) => user.isLoggedIn);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchUser());
    }
  }, [dispatch]);

  return (
    <ThemeProvider>
      <ConfigProvider locale={tr_TR}>
        <GlobalStyles />
        <Layout>
          <Header />
          <Content>
            <Routes />
          </Content>
        </Layout>
      </ConfigProvider>
    </ThemeProvider>
  );
};

export default App;
