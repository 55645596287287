/* eslint-disable import/no-cycle */
import {tciApi} from '..';
import {
  IBank,
  ICity,
  ICurrencyType,
  IGrowthType,
  IInsuranceCompany,
  ILimitRateType,
  IPaymentType,
  ITaxOffice,
  ITermOfSaleType,
} from './types';

export default {
  async fetchCities(): Promise<ICity[]> {
    const cities = await tciApi.post('/parameter/bygroup', {ParameterName: 'City'});
    return cities.map(({label, value, value1}: {label: string; value: number; value1: number}) => ({
      id: value,
      value: value1,
      label,
    }));
  },
  fetchTaxOffices(cityCode?: number): Promise<ITaxOffice[]> {
    return tciApi.get('/taxoffice', {params: {cityCode}});
  },
  fetchInsuranceCompanies(): Promise<IInsuranceCompany[]> {
    return tciApi.post('/parameter/bygroup', {ParameterName: 'InsuranceCompany'});
  },
  fetchBanks(): Promise<IBank[]> {
    return tciApi.post('/parameter/bygroup', {ParameterName: 'Banks'});
  },
  fetchGrowthTypes(): Promise<IGrowthType[]> {
    return tciApi.post('/parameter/bygroup', {ParameterName: 'TradeType'});
  },
  fetchCurrencyTypes(): Promise<ICurrencyType[]> {
    return tciApi.post('/parameter/bygroup', {ParameterName: 'Currency'});
  },
  fetchPaymentTypes(): Promise<IPaymentType[]> {
    return tciApi.post('/parameter/bygroup', {ParameterName: 'PaymentMethod'});
  },
  fetchTermOfSaleTypes(): Promise<ITermOfSaleType[]> {
    return tciApi.post('/parameter/bygroup', {ParameterName: 'TermOfSale'});
  },
  fetchLimitRateTypes(): Promise<ILimitRateType[]> {
    return tciApi.post('/parameter/bygroup', {ParameterName: 'LimitRate'});
  },
  fetchDocument(documentId = 0): Promise<File> {
    return tciApi.get(`/Document/${documentId}`);
  },
  deleteDocument(documentId = 0): Promise<File> {
    return tciApi.delete(`/Document/${documentId}`);
  },
};
