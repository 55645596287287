/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import {pdfjs} from 'react-pdf';
import {Upload as UploadAntd} from 'antd';
import Button from '../Button';
import Icon from '../Icon';
import Text from '../Text';
import {PreviewContainerStyles} from './styles';
import {baseURL} from '../../Api/index';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const UploadButton = ({label, desc}) => {
  return (
    <Button type="primary-faded" style={{height: 'initial', minHeight: 100, minWidth: 110}}>
      <Icon className="d-block m-auto" icon="plus-circle" color="primary" size="lg" />
      <Text color="primary" bold style={{marginTop: 3}}>
        {label === 'Toplu Müşteri Kaydet' ? (
          <>
            <Icon className="mr" icon="users" color="inherit" /> {label}
          </>
        ) : (
          label
        )}
      </Text>
      <Text type="h5" color="smoke">
        {desc}
      </Text>
    </Button>
  );
};

const UploadPreviewContainer = ({file, onRemove, documentId}) => {
  const fileName = file?.name || 'dosya';

  const previewFile = () => {
    window.open(`${baseURL}/Document/${documentId}`);
  };

  const handleFile = () => {
    const objectUrl = URL.createObjectURL(file);
    window.open(objectUrl);
  };

  return (
    <PreviewContainerStyles>
      <Icon
        className="d-block m-auto cursor-pointer"
        icon={['far', 'times-circle']}
        color="red"
        size="2x"
        onClick={onRemove}
      />
      <Text className="mt" align="center" style={{marginTop: 3}} bold block>
        {fileName}
      </Text>

      <Button type="link" size="small" onClick={documentId ? previewFile : handleFile} block>
        <Text type="h5" color="primary" underlined>
          İncele
        </Text>
      </Button>
    </PreviewContainerStyles>
  );
};

const Upload = ({label = '', desc = '', fileList = [], ...props}) => {
  let file = fileList?.[0];
  file = file?.originFileObj || file;

  const handleImageUpload = () => {
    return false; // to prevent automatic request of ANTD Upload component
  };

  return (
    <div style={{height: 100}}>
      <UploadAntd
        fileList={fileList}
        accept={props.accept}
        itemRender={() => (
          <UploadPreviewContainer
            documentId={props.documentId}
            file={file}
            onRemove={props.onRemove}
          />
        )}
        beforeUpload={handleImageUpload}
        {...props}
      >
        {fileList?.length ? null : <UploadButton label={label} desc={desc} />}
      </UploadAntd>
    </div>
  );
};

export default Upload;
