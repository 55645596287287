import React from 'react';
import StyledContent from './styles';

type Props = React.ComponentProps<typeof StyledContent>;

const Content: React.FC<Props> = ({children, ...props}) => {
  return <StyledContent {...props}>{children}</StyledContent>;
};

export default Content;
